// import { API_URLS }  from '../../apiUrls';
import { ThunkAction } from '@reduxjs/toolkit';
import { ActionType } from '../actionTypes';
import { 
  ThunkResult, ThunkDispatchType, FetchReturn,
  FetchReturnSuccess204, FetchReturnSuccess, AutocompleteRecord
} from '../types';
// import {makeHeaders, fetchData} from '../../utils/apiUtils';
import {
  GlobalMessage, RootState, MeetingUpdate, SchedulingPreferenceUpdate,
  ZoomSettingsCreate, ZoomSettingsUpdate, PollSelectionCreate, PollSelectionDelete,
  Leader, NoSelectionCreate, UICalendarEvent, LeaderCalendarUpdate,
} from '..';
import {
  APICalendarEvent, ExternalMeetingInfo,
  Meeting, MeetingHoldEventErrorResolution, MeetingHoldEventErrorResponse, MeetingHoldEventErrorResponseObject,
  MeetingSlot, ScheduleAction, MeetingHoldEventError, UpdateMeetingResponse,
  MeetingQuestionAnswer, MeetingQuestion, Calendar, ZoomSettingsResponse,
  PublicExternalParticipant, MeetingQuestionAnswerSubmission, MeetingRoom,
  CreatePollSelectionsResponse, PollSelectionUpdate, Meetings, MeetingAuditLog,
  PresetLocation, CalendarAssociation, MeetingFilter, MeetingStatus, NormalizedExternalParticipant,
  PrivateExternalParticipant, PrivateExternalParticipantCreate, PrivateExternalParticipantUpdate, NewMeetingUpdate,
  MeetingLeaderUpdate,
  MeetingLeader,
  MeetingLeaderInfo,
  MeetingCreate
} from './types';
import { fetchData, makeHeaders, makeParams, parseFilesToB64Strings } from '../../utils/apiUtils';
import { API_URLS } from '../../apiUrls';
import { EventCache } from '../../utils/scheduleUtils';
import { sendMessage } from '../globalMessages/actions';
import { DateTime } from 'luxon';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import { EVENT_TYPE } from '../../constants';
import api from '../../api';
import { batch } from 'react-redux';
import { setBookingInboundLeadData } from '../../utils/localStorage';
import { sendPermissionDeniedMessage } from '../../utils/permissionUtils';
import { cabCaptureException } from '../../utils/logging';
import { createHash } from 'crypto';
import { generateUniqueUnsavedCabinetId } from '../../utils/dataUtils';
import { debounce, isEqual, keyBy, uniqBy } from 'lodash-es';
import { colors } from '../../colors';


const pollMeetingsIntervalMs = Number(import.meta.env.VITE_POLL_MEETINGS_INTERVAL_SECONDS) * 1000;
const pollEventsIntervalMs = Number(import.meta.env.VITE_POLL_EVENTS_INTERVAL_SECONDS) * 1000;
const pollAuditLogsIntervalMs = Number(import.meta.env.VITE_POLL_AUDIT_LOGS_INTERVAL_SECONDS) * 1000;


const convertCalendarAssociationToCalendar = (calendarAssociation: CalendarAssociation): Calendar => ({
  id: calendarAssociation.id,
  calendar_id: calendarAssociation.calendar_id,
  foregroundColor: calendarAssociation.override_foreground_color ? 
    calendarAssociation.override_foreground_color : calendarAssociation.auto_background_color,
  backgroundColor: calendarAssociation.override_background_color ?
    calendarAssociation.override_background_color : calendarAssociation.auto_background_color,
  summary: calendarAssociation.calendar_name || "Calendar Name Undefined",
  summaryOverride: calendarAssociation.additional_calendar_email?.name,
  provider: calendarAssociation.provider,
  canEdit: calendarAssociation.can_edit,
  conferenceSolutions: calendarAssociation.conference_solutions,
  readable: calendarAssociation.readable,
  owner_email: calendarAssociation.owner_email,
  is_owner_primary: calendarAssociation.is_owner_primary,
  leaders: calendarAssociation.leaders,
  freeBusy: calendarAssociation.free_busy,
  delegatorUser: calendarAssociation.delegator_user,
  additionalCalendarEmail: calendarAssociation.additional_calendar_email,
  autoForegroundColor: calendarAssociation.auto_foreground_color,
  autoBackgroundColor: calendarAssociation.auto_background_color,
  overrideForegroundColor: calendarAssociation.override_foreground_color,
  overrideBackgroundColor: calendarAssociation.override_background_color,
  calendar_access_id: calendarAssociation.calendar_access_id,
  isResource: false,
});


const calendarToCalendarAssociation = (calendar: Partial<Calendar> & {id: number}): 
Partial<CalendarAssociation> & {id: number} => {
  return  {
    id: calendar.id,
    calendar_id: calendar?.calendar_id,
    calendar_name: calendar?.summary,
    provider: calendar?.provider,
    leaders: calendar?.leaders,
    free_busy: calendar?.freeBusy,
    readable: calendar?.readable,
    can_edit: calendar?.canEdit,
    additional_calendar_email: calendar?.additionalCalendarEmail,
    delegator_user: calendar?.delegatorUser,
    conference_solutions: calendar?.conferenceSolutions,
    owner_email: calendar?.owner_email,
    auto_background_color: calendar?.autoBackgroundColor,
    override_background_color: calendar?.overrideBackgroundColor,
    auto_foreground_color: calendar?.autoForegroundColor,
    override_foreground_color: calendar?.autoForegroundColor,
  };
};

type ScheduleThunkAction<R = void> = ThunkAction<R, RootState, unknown, ScheduleAction>;


export const fetchCalendars = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    const headers = await makeHeaders(true);

    return fetchData<CalendarAssociation[]>(
      API_URLS.CALENDAR_ASSOCIATIONS, { headers, }
    ).then((res) => {
      if (res.status === 200) {
        dispatch({ 
          type: ActionType.FETCHED_CALENDAR_ASSOCIATIONS,
          calendars: res.data.map((cal: CalendarAssociation) => convertCalendarAssociationToCalendar(cal))
        });
        return res.data;
      } else {
        return {};
      }
    });
  };

export const updateCalendar = (calUpdate: Partial<Calendar> & {id: number}):
ThunkResult<Promise<CalendarAssociation| Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): 
  Promise<CalendarAssociation | Record<string, unknown>> => {
    const headers = await makeHeaders(true);

    return fetchData<CalendarAssociation>(
      API_URLS.CALENDAR_ASSOCIATIONS + `${calUpdate.id}/`,
      { 
        headers,
        body: JSON.stringify(calendarToCalendarAssociation(calUpdate)),
        method: "PATCH"
      }
    ).then((res) => {
      if (res.status === 200) {
        const state = getState();
        const updateIndex = state.schedule.calendars.findIndex(cal => cal.id === res.data.id);
        if (updateIndex) {
          const newCalendars = [...state.schedule.calendars];
          newCalendars[updateIndex] = convertCalendarAssociationToCalendar(res.data);
          dispatch({ 
            type: ActionType.FETCHED_CALENDAR_ASSOCIATIONS,
            calendars: newCalendars
          });
        }
        return res.data;
      } else {
        return {};
      }
    });
  };

export const updateLeaderCalendars = (
  associations: LeaderCalendarUpdate[]
): ThunkResult<Promise<FetchReturn<Calendar[]> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<Calendar[]> | undefined> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(associations.map(a => {
      const { calId: id, ...assoc } = a;
      return { id, ...assoc };
    }));
    return fetchData<CalendarAssociation[]>(API_URLS.CALENDAR_ASSOCIATION_BATCH, { headers, method: 'POST', body })
      .then((res) => {
        if (res.status === 200) {
          batch(() => {
            dispatch({ 
              type: ActionType.FETCHED_CALENDAR_ASSOCIATIONS,
              calendars: res.data.map((cal) => convertCalendarAssociationToCalendar(cal))
            });
            dispatch({ type: ActionType.UPDATED_LEADER_CALENDARS, associations });
          });
          return res;
        }
      }).catch(err => {
        return err;
      });
  };

export const fetchZoomSettings = ():
ThunkResult<Promise<FetchReturn<ZoomSettingsResponse> | undefined | unknown>> => async (
  dispatch: ThunkDispatchType, getState: () => RootState
) => {
  const headers = await makeHeaders(true);
  // NOTE:  improve clarity for why users might not see Zoom settings for shared execs
  const features = getState().auth.user?.features;
  try {
    if (features?.ZOOM) {
      const res = await fetchData<ZoomSettingsResponse>(API_URLS.ZOOM_SETTINGS, { headers, method: 'GET' });
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_ZOOM_SETTINGS, conferenceSettings: res.data });
        return res;
      }
    }
  } catch (err) {
    return err;
  }
};

export type ZoomSettingsError = { [k in keyof ZoomSettingsResponse]: string[] };

export const updateZoomSettings = (
  conferenceSettings: ZoomSettingsUpdate
): ThunkResult<
  Promise<FetchReturn<ZoomSettingsResponse, { error: ZoomSettingsError }> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);

    // remove explicit undefines
    const { id, ...settings } = Object.fromEntries(
      Object.entries(conferenceSettings).filter(([k, v]) => v !== undefined)
    );
    const body = JSON.stringify(settings);

    try {
      const res = await fetchData<ZoomSettingsResponse>(
        API_URLS.ZOOM_SETTINGS + id + '/', { headers, body, method: 'PATCH' }
      );
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_ZOOM_SETTINGS, conferenceSettings: res.data });
        return res;
      }
    } catch (err) {
      cabCaptureException(err);
    }
  };

export const createZoomSettings = (
  conferenceSettings: ZoomSettingsCreate
): ThunkResult<
  Promise<FetchReturn<ZoomSettingsResponse, { error: ZoomSettingsError }> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(conferenceSettings);

    try {
      const res = await fetchData<ZoomSettingsResponse>(API_URLS.ZOOM_SETTINGS, { headers, body, method: 'POST' });
      if (res.status === 201) {
        dispatch({ type: ActionType.UPDATED_ZOOM_SETTINGS, conferenceSettings: res.data });
        return res;
      }
    } catch (err: unknown) {
      cabCaptureException(err);
    }
  };

export const updateTimeSlot = (timeSlot: MeetingSlot): ScheduleThunkAction<Promise<Record<string, unknown>>> =>
  async (dispatch, getState)  => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(timeSlot);
    const originalSlot = getState().schedule.meetingSlots.find(slot => slot.id === timeSlot.id);

    const url = API_URLS.MEETING_SLOTS + timeSlot.id + '/';

    dispatch({ type: ActionType.UPDATED_MEETING_SLOT, meetingSlot: timeSlot });
    try {
      const res = await fetchData(url, { headers, body, method: 'PATCH' });
      if (res.status !== 200) {
        throw new Error(`Error ${res.status}`);
      }
      dispatch({ type: ActionType.UPDATED_MEETING_SLOT, meetingSlot: res.data });
      return res.data;
    } catch (err) {
      if (originalSlot) {
        dispatch({ type: ActionType.UPDATED_MEETING_SLOT, meetingSlot: originalSlot });
      }
      return err;
    }
  };

export const deleteTimeSlot = (slot: MeetingSlot): ScheduleThunkAction<Promise<{deleted: boolean}>> => {
  return async (dispatch, getState)  => {
    const headers = await makeHeaders(true);
    const url = API_URLS.MEETING_SLOTS + slot.id + '/';
    dispatch({ type: ActionType.DELETED_MEETING_SLOT, slotId: slot.id, meetingId: slot.meeting });
    try {
      const res = await fetchData(url, { headers, method: 'DELETE' });
      if (res.status !== 204) {
        throw new Error(`Error ${res.status}`);
      }
      return {deleted: true};
    } catch (err) {
      dispatch({ type: ActionType.SAVED_MEETING_SLOTS, meetingSlots: [slot] }); //In case deletion fails
    }
    return {deleted: false};
  };
};

export const batchCreateTimeSlots = (timeSlots: MeetingSlot[]): ScheduleThunkAction<Promise<MeetingSlot[]>> =>
  async (dispatch, getState) => {
    if (!timeSlots.length) {
      return [];
    }
    const headers = await makeHeaders(true);
    const body = JSON.stringify(timeSlots);

    dispatch({ type: ActionType.SAVED_MEETING_SLOTS, meetingSlots: timeSlots });
    try {
      const res = await fetchData<{ created: MeetingSlot[] }>(
        API_URLS.MEETING_SLOTS + 'bulk-create/',
        { headers, body, method: 'POST' },
      );
      if (res.status !== 201) {
        throw new Error(`Error ${res.status}`);
      }
      dispatch({ type: ActionType.DELETED_MEETING_SLOTS, slotIds: timeSlots.map(slot => slot.id) });
      dispatch({ type: ActionType.SAVED_MEETING_SLOTS, meetingSlots: res.data.created });
      return res.data.created;
    } catch (err) {
      console.error(err);
      dispatch({ type: ActionType.DELETED_MEETING_SLOTS, slotIds: timeSlots.map(slot => slot.id) });
      return [];
    }
  };

export const batchDeleteTimeSlots = (slotIds: MeetingSlot['id'][]): ScheduleThunkAction<Promise<{deleted: boolean}>> => 
  async (dispatch, getState)  => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(slotIds);
    const url = API_URLS.MEETING_SLOTS + 'bulk-delete/';

    const originalSlots = getState().schedule.meetingSlots.filter(slot => slotIds.includes(slot.id));
    dispatch({ type: ActionType.DELETED_MEETING_SLOTS, slotIds });
    try {
      const res = await fetchData(url, { headers, body, method: 'DELETE' });
      if (res.status !== 200) {
        throw new Error(`Error ${res.status}`);
      }
      return {deleted: true};
    } catch (err) {
      console.error(err);
      dispatch({ type: ActionType.SAVED_MEETING_SLOTS, meetingSlots: originalSlots }); //In case deletion fails
    }
    return {deleted: false};
  };


export const createQuestion = (
  questions: Omit<MeetingQuestion, 'id'>[], meeting: number, isSavedMeeting = true,
): ScheduleThunkAction<Promise<MeetingQuestion[]>> => async (dispatch, getState) => {
  if (!questions.length) {
    return [];
  }

  try {
    let data: { questions: MeetingQuestion[] } | null = null;

    if (isSavedMeeting) {
      const headers = await makeHeaders(true);
      const body = JSON.stringify(questions);
      const res = await fetchData<{ questions: { [id: number]: MeetingQuestion } }>(
        API_URLS.MEETING_QUESTIONS_SUBMIT,
        { headers, body, method: 'POST' },
      );
      if (res.status !== 201) {
        throw new Error(`Error ${res.status}`);
      }
      data = { ...res.data, questions: Object.values(res.data.questions) };
    } else {
      const qs = questions.map(q => ({ ...q, id: generateUniqueUnsavedCabinetId() }));
      data = { questions: qs };
    }

    dispatch({ 
      type: ActionType.SAVED_MEETING_QUESTIONS,
      meetingId: meeting, meetingQuestions: data.questions,
      isSavedMeeting,
    });

    return data.questions;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const updateQuestion = (
  question: MeetingQuestion, isSavedMeeting = true,
): ScheduleThunkAction<Promise<MeetingQuestion | null>> => async (dispatch, getState)  => {
  dispatch({ type: ActionType.UPDATED_MEETING_QUESTION, meetingQuestion: question, isSavedMeeting });

  try {
    let data: MeetingQuestion | null = null;
    if (isSavedMeeting) {
      const headers = await makeHeaders(true);
      const body = JSON.stringify([question]);
      const url = API_URLS.MEETING_QUESTIONS_SUBMIT;
      const res = await fetchData<MeetingQuestion>(url, { headers, body, method: 'POST' });
      if (res.status !== 200) {
        throw new Error(`Error ${res.status}`);
      }
      data = res.data;
    } else {
      data = question;
    }

    dispatch({ type: ActionType.UPDATED_MEETING_QUESTION, meetingQuestion: data, isSavedMeeting });
    return data;
  } catch (err) {
    return null;
  }
};

export const deleteQuestion = (
  question: MeetingQuestion, meeting: number, isSavedMeeting = true,
): ScheduleThunkAction<Promise<Record<string, unknown>>> => {
  return async (dispatch, getState)  => {
    dispatch({
      type: ActionType.DELETED_MEETING_QUESTIONS,
      meetingId: meeting, questionIds:[question.id], isSavedMeeting,
    });

    try {
      if (isSavedMeeting) {
        const headers = await makeHeaders(true);
        const url = API_URLS.MEETING_QUESTIONS + question.id + '/';
        const res = await fetchData(url, { headers, method: 'DELETE' });
        if (res.status !== 204) {
          throw new Error(`Error ${res.status}`);
        }
      }

      return { deleted: true };
    } catch (err) {
      dispatch({ 
        type: ActionType.SAVED_MEETING_QUESTIONS,
        meetingId: meeting, meetingQuestions: [question], isSavedMeeting
      }); //In case deletion fails
    }

    return { deleted: false };
  };
};

export const createNewMeeting = (
  leaders: Leader['id'][], participants: PrivateExternalParticipant[],
  poll?: boolean, reusable?: boolean
): ThunkResult<NewMeetingUpdate> => (
  dispatch: ThunkDispatchType, getState: () => RootState
) => {
  const { auth: { user }, schedule, leaders: { leaders: allLeaders } } = getState();

  const newMtg = {
    id: -1,
    title: '',
    description: '',
    is_poll: poll,
    is_reusable: reusable,
    auto_merge_slots: true,
    duration_minutes: schedule.schedulingPrefs.user_prefs?.default_duration_minutes || 30, prevent_conflict: poll ? 
      false : !!schedule.schedulingPrefs.user_prefs?.booking_check_conflicts,
    create_user: {
      id: user?.id || -1,
      first_name: user?.first_name || '',
      last_name: user?.last_name || '', email: user?.email || '',
    },
    leaders: [...leaders],
    leader_info: leaders
      .map(lId => allLeaders.find(l => l.id === lId))
      .filter((l): l is Leader => !!l)
      .map((l, idx) => ({ ...l, meeting_leader_id: (idx + 1) * -1 })),
    participants: participants.map(p => ({ [p.id]: p })).reduce((a, b) => ({ ...a, ...b }), {}),
  };

  dispatch({ type: ActionType.UPDATED_NEW_MEETING, meeting: newMtg });

  return newMtg;
};

export const updateNewMeeting = (meeting: NewMeetingUpdate | null): ThunkResult<Promise<void>> => async (
  dispatch: ThunkDispatchType, getState: () => RootState
) => {
  const mtg = { ...meeting };

  // make sure that .leader_info gets updated if .leaders was
  if (!isEqual(new Set(meeting?.leaders), new Set(meeting?.leader_info?.map(l => l.id)))) {
    const { leaders } = getState();
    mtg.leader_info = (mtg.leaders || [])
      .map(lId => leaders.leaders.find(l => l.id === lId))
      .filter((l): l is Leader => !!l)
      .map((l, idx) => ({ ...l, meeting_leader_id: (idx + 1) * -1}));
  }

  dispatch({ type: ActionType.UPDATED_NEW_MEETING, meeting: mtg });
};

export const deleteNewMeeting = (): ScheduleAction => {
  return { type: ActionType.DELETED_NEW_MEETING };
};

export const createMeeting = (
  meeting: MeetingCreate, timeSlots: MeetingSlot[], questions: MeetingQuestion[], files?: File[], 
  participants?: PrivateExternalParticipant[], meetingLeaders?: MeetingLeader[]
):
ThunkResult<Promise<UpdateMeetingResponse | void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<UpdateMeetingResponse | void> => {
    const headers = await makeHeaders(true);
    const fileStrings = await parseFilesToB64Strings(files || []);
    const data = {...meeting, id: -1, files: fileStrings};
    const body: string = JSON.stringify(data);

    const res = await fetchData(API_URLS.MEETINGS, { headers, body, method: 'POST' })
      .then(async (meetingRes: FetchReturn): Promise<UpdateMeetingResponse | void> => {
        if (meetingRes.status === 201) {
          trackEvent(EVENT_TYPE.SCHEDULING_CREATE_MEETING);
          const newMeeting = meetingRes.data;
          dispatch({ type: ActionType.SAVED_MEETING, meeting: newMeeting });
          const newTimeSlots = timeSlots.map(slot => ({ ...slot, meeting: newMeeting.id }));
          const newQuestions = questions.map(question => ({ ...question, meeting: newMeeting.id }));
          const savedTimeSlotsPromise = dispatch(batchCreateTimeSlots(newTimeSlots));
          const savedQuestionsPromise = dispatch(createQuestion(newQuestions, newMeeting.id));

          uniqBy(participants, "email")?.forEach(p => {
            dispatch(createMeetingParticipant({
              email: p.email,
              name: p.name || '',
              meeting: newMeeting.id, 
              preregistered: true,
              required: p.required,
              calendar_access: p.calendar_access,
              should_invite: p.should_invite,
            }));
          });

          if (meetingLeaders) {
            dispatch(bulkUpsertMeetingLeaders(meetingLeaders.map(meetingLeaderItr => ({
              ...meetingLeaderItr,
              meeting: newMeeting.id
            }))));
          } 
          const [savedTimeSlots, savedQuestions] = await Promise.all(
            [savedTimeSlotsPromise, savedQuestionsPromise]);

          return { meeting: newMeeting, savedTimeSlots, savedQuestions };
        } else {
          cabCaptureException("Failed to create_meeting", {extra: JSON.parse(JSON.stringify(meeting))});
          throw Error("Failed to create_meeting");
        }
      });
    return res;
  };

export const updateMeeting = (meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[]):
ThunkResult<Promise<UpdateMeetingResponse>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<UpdateMeetingResponse> => {
    const headers = await makeHeaders(true);

    const partialMeeting: Partial<Meeting> = {
      id: meeting.id,
      title: meeting.title,
      internal_label: meeting.internal_label,
      description: meeting.description,
      date_scheduled: meeting.date_scheduled,
      calendar_tz: meeting.calendar_tz,
      secondary_tz: meeting.secondary_tz,
      copy_tz: meeting.copy_tz,
      secondary_copy_tz: meeting.secondary_copy_tz,
      status: meeting.status,
      locations: meeting.locations,
      conference_provider: meeting.conference_provider,
      conference_leader: meeting.conference_leader,
      duration_minutes: meeting.duration_minutes,
      booking_calendar: meeting.booking_calendar,
      use_link: meeting.use_link,
      prevent_conflict: meeting.prevent_conflict,
      is_poll: meeting.is_poll,
      num_expected_participants: meeting.num_expected_participants,
      executive_hold: meeting.executive_hold,
      hold_calendars: meeting.hold_calendars,
      show_voter_names: meeting.show_voter_names,
      leaders: meeting.leaders,
      allow_add_participants: meeting.allow_add_participants,
      rooms: meeting.rooms,
      location_presets: meeting.location_presets,
      auto_merge_slots: meeting.auto_merge_slots,
      is_reusable: meeting.is_reusable,
      enable_public_attendee_list: meeting.enable_public_attendee_list,
      allow_reschedule_cancel: meeting.allow_reschedule_cancel,
      recurring_times: meeting.recurring_times,
      priority: meeting.priority,
      notes: meeting.notes,
      start_delay_minutes: meeting.start_delay_minutes,
      use_template_parent: meeting.use_template_parent,
      slot_time_interval: meeting.slot_time_interval,
      selected_scheduled_time: meeting.selected_scheduled_time,
      buffer_start_minutes: meeting.buffer_start_minutes,
      buffer_end_minutes: meeting.buffer_end_minutes,
      send_participant_reminders: meeting.send_participant_reminders,
      participant_reminder_minutes: meeting.participant_reminder_minutes,
      // currently no ui to change filenames
      //files: meeting.files
    };

    const fileStrings = await parseFilesToB64Strings(files || []);
    const data = {...partialMeeting, files: fileStrings};

    const body: string = JSON.stringify(data);

    const url = API_URLS.MEETINGS + meeting.id + '/';
    return fetchData(url, { headers, body, method: 'PATCH' })
      .then(async (res: FetchReturn): Promise<UpdateMeetingResponse | undefined> => {
        if (res.status === 200) {
          dispatch({ type: ActionType.SAVED_MEETING, meeting: res.data });

          const newTimeSlots = timeSlots.map(slot => ({ ...slot, meeting: meeting.id }));

          let savedTimeSlots;
          if (newTimeSlots.length) {
            savedTimeSlots = await batchCreateTimeSlots(newTimeSlots)(dispatch, getState, null);
          }

          dispatch(showChangeConfirmed('Meeting details have been updated'));

          return { meeting: res.data, savedTimeSlots };
        }
        
      }).catch(err => {
        return err;
      });
  };


export const showChangeConfirmed = (msg: string): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const message: GlobalMessage = {
      timeout: 2000,
      message: msg,
      autoDismiss: true,
      header: '',
      position: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      active: true,
      severity: "info",
    };
    sendMessage(message)(dispatch, getState, null);
  };

export const fetchSchedulingPreferences = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    try {
      const res = await fetchData(API_URLS.SCHEDULING_PREFS, { headers, method: 'GET' });
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_SCHEDULING_PREFS, preferences: res.data });
      }
    } catch (err) {
      console.error(err);
      // dispatch({ type: ActionType.SHOW_ERROR, error: err });
    }
  };

export const updateSchedulingPrefs = (prefs: SchedulingPreferenceUpdate): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const { id, ...prefUpdates } = prefs;
    const body = JSON.stringify(prefUpdates);

    try {
      const res = await fetchData(API_URLS.SCHEDULING_PREFS + id + '/', { headers, body, method: 'PATCH' });
      if (res.status ===  200) {
        dispatch({ type: ActionType.UPDATED_SCHEDULING_PREFS, preferences: res.data });
        return res.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

const privateFetchRemoteCalendars = debounce((dispatch: ThunkDispatchType, headers?: { [key: string]: string }) => {
  return fetchData(API_URLS.AVAILABLE_CALENDARS, { headers, method: 'GET' })
    .then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({
          type: ActionType.FETCHED_AVAILABLE_CALENDARS,
          remoteCalendars: res.data.calendars, errors: res.data.errors
        });
      }
    }).catch(err => {
      return err;
    });
}, 2000);

export const fetchRemoteCalendars = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    dispatch({ type: ActionType.FETCHING_AVAILABLE_CALENDARS });
    const headers = await makeHeaders(true);
    return privateFetchRemoteCalendars(dispatch, headers);
  };

export const addDelegateCalendarsForEmails = (
  emails: string[]
): ThunkResult<Promise<{ [email: string]: Calendar[] }>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(emails);
    const state = getState();
   
    try {
      const res = await fetchData<{email_calendars: { [email: string]: Calendar[] }}>(
        API_URLS.MS_AVAILABLE_CALENDARS_BY_EMAIL, { headers, body: body, method: 'POST' }
      );
      if (res.status === 200) {
        const calendarList = uniqBy(Object.values(res.data.email_calendars).reduce((previousValue, currentValues) => {
          return previousValue.concat(currentValues);
        }, [] as Calendar[]), "id");
        const newCalendars = state.schedule.calendars.concat(calendarList);
        dispatch({
          type: ActionType.FETCHED_AVAILABLE_CALENDARS,
          remoteCalendars: newCalendars, errors: state.schedule.calendarErrors
        });
        return res.data.email_calendars;
      }
      return {};
    } catch (err) {
      console.error(err);
      return {};
    }
  };

const lastMeetingsFetchTime: {
  [key: string]: DateTime
} = {

};

export type FetchMeetingProps = {
  query: Partial<MeetingFilter>
  checkMeetingIds?: number[], page?: number,
  skipFetchTimeUpdate?: boolean,
};

export const fetchMeetings = ({
  query, checkMeetingIds, page, skipFetchTimeUpdate = false,
}: FetchMeetingProps): ThunkResult<ReturnType<typeof api.fetchMeetings> | Promise<undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState)  => {
    const state = getState();
    const unscheduled = query.status === MeetingStatus.PENDING;
    try {
      if (!skipFetchTimeUpdate) {
        lastMeetingsFetchTime[JSON.stringify(query)] = DateTime.now();
      }
      const fetchStartTime = DateTime.now().toISO();
      const res = await api.fetchMeetings(
        query, checkMeetingIds, page
      );

      if (res?.status === 200
        && (Object.keys(res.data).length === 0 || !isEqual(state.schedule.meetings, res.data))
      ) {
        const meetings = res.data.data.meetings;
        const removedMeetings: { [id: number]: Meeting | null } = 'data' in res.data ? res.data.data.removed : {};
        const deletedMeetingIds = Object.entries(removedMeetings)
          .filter(([k, v]) => v == null)
          .map(([k]) => Number(k));
        const alteredMeetings = Object.values(removedMeetings)
          .filter((v) => v != null)
          .reduce((all, v) => ({ ...all, ...(v ? { [v.id]: v } : {} ) }), {} as Meetings);

        dispatch({
          type: ActionType.FETCHED_MEETINGS,
          meetings: { ...meetings, ...alteredMeetings },
          scheduledMeetingsLoaded: !unscheduled,
          fetchStartTime
        });
        if (deletedMeetingIds.length > 0) {
          dispatch({ type: ActionType.DELETED_MEETINGS, meetingIds: deletedMeetingIds });
        }

        return res;
      }
    } catch (err) {
      console.error(err);
    }
  };

let meetingPollingActiveTimeout: NodeJS.Timeout | undefined;

export const startPollingMeetings = (
  query: Partial<MeetingFilter>
): ThunkResult<Promise<(() => void)>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<(() => void)> => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    const stopPolling = () => {
      if (timeout) clearTimeout(timeout);
      meetingPollingActiveTimeout = undefined;
    };

    const isPoll = !!query.is_poll;
    const isReusable = !!query.is_reusable;
    const unscheduled = query.status === MeetingStatus.PENDING;

    const pollFunc = async () => {
      let timeoutMs = pollMeetingsIntervalMs;
      const lastFetchTime = lastMeetingsFetchTime[JSON.stringify(query)];
      const lastNoFilterFetchTime = lastMeetingsFetchTime.noFilter;
      let shouldFetch = true;

      if (lastFetchTime) {
        shouldFetch = false;
        let diff = DateTime.now().toMillis() - lastFetchTime.toMillis();
        // in the case that we are polling unscheduled, we also want to make sure there is not a more
        // recent unfiltered fetch that can be used (because unscheduled is just a subset of all meetings)
        if (lastNoFilterFetchTime) {
          const noFilterDiff = DateTime.now().toMillis() - lastNoFilterFetchTime.toMillis();
          if (noFilterDiff < diff) diff = noFilterDiff;
        }
        if (diff < pollMeetingsIntervalMs) {
          timeoutMs = (pollMeetingsIntervalMs - diff) + 10;
        } else {
          shouldFetch = true;
        }
      }

      if (shouldFetch) {
        const { schedule: { meetings } } = getState();
        const checkMeetingIds = Object.values(meetings)
          .filter(mtg => (
            mtg.status === query.status && !!mtg.is_poll === isPoll && !!mtg.is_reusable === isReusable
          ))
          .map(mtg => mtg.id);
        await dispatch(fetchMeetings({
          query,
          checkMeetingIds,
        }));
        await dispatch(fetchMeetingSlotCalendarEventError({unscheduled: !!unscheduled}));
      }

      if (timeout === meetingPollingActiveTimeout && pollMeetingsIntervalMs) {
        timeout = setTimeout(pollFunc, timeoutMs);
        meetingPollingActiveTimeout = timeout;
      }
    };

    if (meetingPollingActiveTimeout != null) {
      clearTimeout(meetingPollingActiveTimeout);
      meetingPollingActiveTimeout = undefined;
    }
    await pollFunc();
    // let timeout = setTimeout(pollFunc, interval);
    // meetingPollingActiveTimeout = timeout;

    return stopPolling;
  };

export const fetchMeeting = (meetingId: string): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    const headers = await makeHeaders(true);

    return fetchData(`${API_URLS.MEETINGS}${meetingId}/`, { headers, method: 'GET' })
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.FETCHED_MEETING, meeting: res.data });
        }
        sendPermissionDeniedMessage(dispatch, res);
      }).catch(err => {
        return err;
      });
  };

export const deleteMeeting = (meeting: Meeting): ScheduleThunkAction<Promise<FetchReturnSuccess204 | undefined>> => {
  return async (dispatch, getState): Promise<FetchReturnSuccess204 | undefined>  => {
    const headers = await makeHeaders(true);
    const url = API_URLS.MEETINGS + meeting.id + '/';
    dispatch({ type: ActionType.DELETED_MEETING, meeting: meeting });
    try {
      const res = await fetchData(url, { headers, method: 'DELETE' });
      if (res.status !== 204) {
        dispatch({ type: ActionType.SAVED_MEETING, meeting: meeting }); //In case deletion fails
        throw new Error(`Error ${res.status}`);
      }
      return res;
    } catch (err) {
      dispatch({ type: ActionType.SAVED_MEETING, meeting: meeting }); //In case deletion fails
    }
  };
};


export const fetchMeetingSlots = (unscheduled: boolean): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    const headers = await makeHeaders(true);

    const params = makeParams({ unscheduled });

    return fetchData(API_URLS.MEETING_SLOTS + params, { headers, method: 'GET' })
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.FETCHED_MEETING_SLOTS, meetingSlots: res.data, unscheduled });
        }  
      }).catch(err => {
        return err;
      });
  };

export const fetchSlotsForMeeting = (meetingId: string): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {

    const headers = await makeHeaders(true);

    return fetchData(API_URLS.MEETINGS + `${meetingId}/slots/`, { headers, method: 'GET' })
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.FETCHED_SLOTS_FOR_MEETING, meetingSlots: res.data });
        }
      }).catch(err => {
        return err;
      });
  };

export const fetchMeetingExternal = (
  externalId: string, meetingToken?: string, slotsStartDate?: DateTime, slotsEndDate?: DateTime
): ThunkResult<Promise<ExternalMeetingInfo | Record<never, never>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(false);
    const paramsObj: { external_id: string; meetingToken?: string; start?: string; end?: string } = { 
      external_id: externalId,
      meetingToken,
    };
    const start = slotsStartDate?.toISO();
    if (start) {
      paramsObj.start = start;
    }
    const end = slotsEndDate?.toISO();
    if (end) {
      paramsObj.end = end;
    }
    const params = makeParams(paramsObj);

    try {
      const res = await fetchData<ExternalMeetingInfo>(
        API_URLS.MEETING_EXTERNAL + params,
        { headers, method: 'GET' },
      );
      const participantsRes = await api.listMeetingParticipants({
        meeting_external_id: externalId, 
        ...((res.status === 200 && !res.data.is_poll) ? {preregistered: true} : {})
      });

      if (res.status === 200) {
        const participants = participantsRes.status === 200 ? participantsRes.data : [];
        dispatch({ type: ActionType.FETCHED_MEETING_EXTERNAL, meeting: {...res.data, participants} });
        
        return {...res.data, participants};
      } else {
        console.error(res);
        return {};
      }
    } catch (err) {
      console.error(err);
      cabCaptureException(err);
      return {};
    }
  };

export const fetchEvents = ({
  googleCalendarIds = [], microsoftCalendarIds = [], startDate, endDate, clearCache = false, frequencyMs, forTimezone
}: {
  googleCalendarIds?: string[], microsoftCalendarIds?: string[],
  startDate: string, endDate: string, clearCache?: boolean, frequencyMs?: number, forTimezone: string
}): ThunkResult<Promise<{ nextAllowedFetchMs?: number }>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    let cachedEvents: APICalendarEvent[] = [];

    const microsoftCalendarIdsDeduped = Array.from(new Set(microsoftCalendarIds));
    const googleCalendarIdsDeduped = Array.from(new Set(googleCalendarIds));
    let lastFetchTime: DateTime | undefined;

    const checkCache = (calendarId: string) => {
      const cacheHit = EventCache.getInstance().get(calendarId, startDate, endDate);
      if (cacheHit) {
        if (!lastFetchTime || lastFetchTime < cacheHit.cacheTimestamp) {
          lastFetchTime = cacheHit.cacheTimestamp;
        }
        if (!clearCache) {
          cachedEvents = [...cachedEvents, ...cacheHit.events];
          return false;
        }
      }
      return true;
    };
    
    const microsoftCalIdsToFetch = microsoftCalendarIdsDeduped.filter(checkCache);
    const googleCalIdsToFetch = googleCalendarIdsDeduped.filter(checkCache);

    // if frequencyMs is given, we check to see if fetch has recently happened, and if so, abort and return
    // the ms until the next call can be made
    if (frequencyMs && lastFetchTime) {
      const diff = DateTime.now().toMillis() - lastFetchTime.toMillis();
      if (diff < frequencyMs) {
        return { nextAllowedFetchMs: frequencyMs - diff };
      }
    }

    if (clearCache) {
      EventCache.getInstance().clear();
    }

    const shouldFetchNewEvents = googleCalIdsToFetch.length > 0 || microsoftCalIdsToFetch.length > 0;

    let res: FetchReturn<{ events: APICalendarEvent[] }> | undefined;
    try {
      if (shouldFetchNewEvents) {
        const headers = await makeHeaders(true);
        const params = makeParams(
          { googleCalId: googleCalIdsToFetch, msCalId: microsoftCalIdsToFetch, startDate, endDate, 
            forTimezone: forTimezone || "UTC" }
        );
        res = await fetchData<{ events: APICalendarEvent[] }>(
          API_URLS.CALENDAR_EVENTS + params,
          { headers, method: 'GET' },
        );
      }
    } catch (err) {
      console.error(err);
      console.error('Problem fetching events. Returning cached events.');
      return {};
    }

    let allEvents = [...cachedEvents];

    if (res?.status === 200) {
      const { events } = res.data;
      const eventsByCalendarId: { [calendarId: string]: APICalendarEvent[] } = {};

      [...googleCalendarIds, ...microsoftCalendarIds].forEach(calendarId => {
        eventsByCalendarId[calendarId] = [];
      });

      events.forEach((event) => {
        eventsByCalendarId[event.calendarId].push(event);
      });

      Object.keys(eventsByCalendarId).forEach(calendarId => {
        EventCache.getInstance().add(calendarId, startDate, endDate, eventsByCalendarId[calendarId]);
      });

      // dedupe and save both the newly fetched events and the cached events
      allEvents = [...events];
      cachedEvents.forEach(ce => {
        if (!allEvents.find(e => e.id === ce.id && e.calendarId === ce.calendarId)) {
          allEvents.push(ce);
        }
      });
    }

    const state = getState();

    const newEvents: Record<UICalendarEvent['unique_cal_event_id'], UICalendarEvent> = keyBy(allEvents.map((event) => {
      const location = Object.values(event.locations_and_conferences || {}).map(l => {
        if (l.name) {
          try {
            const url = new URL(l.name);
            const domain = url.hostname.toLowerCase();
            if (domain.includes('zoom.')) {
              return 'Zoom Meeting';
            } else if (domain.includes('meet.google.')) {
              return 'Google Meet';
            } else if (domain.includes('teams.microsoft.')) {
              return 'Microsoft Teams';
            }
          } catch (err) {
            // Invalid URL, just return the location name as-is
            return l.name;
          }
        }

        return l.name;
      }).join(', ');

      return {
        unique_cal_event_id: `${event.calendarId}-${event.unique_id}`,
        unique_id: event.unique_id,
        id: event.id,
        title: event.title,
        location,
        start: event.start.dateTime,
        end: event.end.dateTime,
        editable: false,
        calendarId: event.calendarId,
        allDay: event.allDay,
        //borderColor: event.backgroundColor,
        backgroundColor: event.backgroundColor,
        iCalUId: event.iCalUId,
        textColor: colors.white900,
        selfResponse: event.selfResponse,
        busy: event.busy,
        attendees: event.attendees,
        bufferStartMinutes: event.buffer_start_minutes,
        bufferEndMinutes: event.buffer_end_minutes,
        isBackground: false,
        recurringRootEventIdr: event.recurring_root_event_idr,
      };
    }), e => e.unique_cal_event_id);

    if (!isEqual(newEvents, state.schedule.events)) {
      dispatch({ type: ActionType.FETCHED_REMOTE_CALENDAR_EVENTS, events: newEvents });
    }

    return {};
  };

let activeTimeout: NodeJS.Timeout | undefined;

export const startPollEvents = (googleCalendarIds: string[] = [], microsoftCalendarIds: string[] = [],
  startDate: string, endDate: string, forTimezone: string): ThunkResult<Promise<(() => void)>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<(() => void)> => {
    const pollFunc = async () => {
      let timeoutMs = pollEventsIntervalMs;
      const { nextAllowedFetchMs } = await dispatch(fetchEvents({
        googleCalendarIds, microsoftCalendarIds, startDate, endDate, clearCache: true,
        frequencyMs: pollEventsIntervalMs, forTimezone
      }));

      if (timeout === activeTimeout) {
        if (nextAllowedFetchMs) timeoutMs = nextAllowedFetchMs + 10;
        timeout = setTimeout(pollFunc, timeoutMs);
        activeTimeout = timeout;
      }
    };

    if (activeTimeout != null) {
      clearTimeout(activeTimeout);
    }

    let timeout: NodeJS.Timeout | undefined;
    if (pollEventsIntervalMs) {
      timeout = setTimeout(pollFunc, pollEventsIntervalMs);
    }
    activeTimeout = timeout;

    return () => {
      clearTimeout(timeout);
      activeTimeout = undefined;
    };
  };

export const bookExternal = (
  externalId: string, externalParticipants: Omit<PublicExternalParticipant, "id">[],
  startTime: string, organizationId?: number, createUserId?: number, organizationName?: string | null, 
  answers?: MeetingQuestionAnswerSubmission[],
) =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(false);
    const body = JSON.stringify({ externalId, externalParticipants, startTime, answers });
    const res = await fetchData<ExternalMeetingInfo>(API_URLS.BOOK_EXTERNAL, { headers, body, method: 'POST' });
    if (res.status === 200) {
      if (organizationId && organizationName && createUserId) {
        setBookingInboundLeadData(organizationId, organizationName || "Not Available", createUserId, res.data.id);
      }
    }
    return res;
  };

export const bookExternalReschedule = (
  externalId: string,
  meetingToken: string,
  externalParticipants: Omit<PublicExternalParticipant, "id">[],
  startTime: string,
  answers?: MeetingQuestionAnswerSubmission[],
) =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(false);
    const body = JSON.stringify({ externalId, externalParticipants, startTime, answers, meetingToken });
    const res = await fetchData<ExternalMeetingInfo>(
      API_URLS.BOOK_EXTERNAL, { headers, body, method: 'POST' }
    );
    return res;
  };



export const createPollSelections = (
  externalId: string, selections: Array<PollSelectionCreate | NoSelectionCreate>,
): ThunkResult<Promise<FetchReturn<CreatePollSelectionsResponse> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    try {
      const res = await api.createMeetingPollSelections(externalId, selections);
      if (res.status === 201) {
        
        dispatch({
          type: ActionType.SET_POLL_SELECTIONS, externalId,
          pollSelections: res.data.poll_selections, externalParticipants: res.data.participants
        });
        return res;
      }
    } catch (err) {
      console.error(err);
    }
  };

export const updatePollSelections = (
  externalId: string, selections: Array<PollSelectionUpdate>,
): ThunkResult<Promise<FetchReturn<CreatePollSelectionsResponse> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    try {
      const res = await api.updateMeetingPollSelections(externalId, selections);
      if (res.status === 200) {
        dispatch({
          type: ActionType.SET_POLL_SELECTIONS, externalId,
          pollSelections: res.data.poll_selections, externalParticipants: res.data.participants
        });
        return res;
      }
    } catch (err) {
      console.error(err);
    }
  };

export const deletePollSelections = (
  externalId: string, selections: PollSelectionDelete[],
): ThunkResult<Promise<FetchReturnSuccess<unknown> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    
    try {
      const res = await api.deleteMeetingPollSelections(selections);
      if (res.status === 200) {
        dispatch({ type: ActionType.DELETED_POLL_SELECTIONS, externalId, deletedSelectionIds: res.data.deleted_ids });
        return res.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

export const bookGroupMeeting = (
  meetingId: number, startTime: string, createUserId?: number, shouldCreateNewEvent?: boolean
): ThunkResult<Promise<FetchReturn<Meeting> | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<Meeting> | undefined> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ startTime, shouldCreateNewEvent });
    const organization = getState().organization;
    try {
      const res = await fetchData<Meeting>(
        API_URLS.MEETINGS + `${meetingId}/book-group-meeting/`,
        { headers, body, method: 'POST' }
      );
      if (res.status === 200) {
        dispatch({ type: ActionType.UPDATED_MEETING, meeting: res.data });
        if (organization.id && createUserId && organization.name) {
          setBookingInboundLeadData(
            organization.id,
            organization.name,
            createUserId,
            res.data.id
          );
        }
        
      } 
      return res;
      
    } catch (err) {
      console.error(err);
    }
  };

export const handleMeetingSlotCalendarEventResolution = (
  { meetingId, meetingSlotCalendarEventId }: MeetingHoldEventErrorResolution
): ThunkResult<Promise<boolean>> =>
  async (): Promise<boolean> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ id: meetingSlotCalendarEventId, event_errors: null });
    try {
      await fetchData(
        API_URLS.MEETING_SLOT_CALENDAR_EVENTS + `${meetingSlotCalendarEventId}/`,
        { headers, body, method: 'PATCH' }
      );
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  };

const handleErrorFormatting = (event: MeetingHoldEventErrorResponseObject): MeetingHoldEventError => {
  const meetingSlot = event.meeting_slot;
  return {
    action: event.event_errors.action,
    calendarId: event.calendar_association.calendar_id,
    meetingSlotCalendarEventId: event.id,
    leaderName: "",
    error_code: event.event_errors.error_code,
    meetingName: event.meeting_title,
    resolved: false,
    error_timestamp: event.event_errors.error_timestamp,
    meetingSlotId: meetingSlot ? meetingSlot.id : null,
    meetingSlotStart: meetingSlot ? meetingSlot.start_date : null,
    meetingSlotEnd: meetingSlot ? meetingSlot.end_date : null,
  };
};


export const fetchMeetingSlotCalendarEventError = (
  { unscheduled, meetingId }: { unscheduled?: boolean, meetingId?: number }
): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const url = API_URLS.MEETING_SLOT_CALENDAR_EVENTS_ERRORS + makeParams({
      unscheduled: unscheduled,
      meetingId: meetingId?.toString()
    });
    const state = getState();
    try {
      const res = await fetchData<MeetingHoldEventErrorResponse>(
        url,
        { headers, method: 'GET' }
      );
      const schedule_errors: { [key: string]: MeetingHoldEventError[] } = {};
      if (res.status === 200 && res.data.errors) {
        Object.entries(res.data.errors).forEach(([evtMeetingId, meetingSlotCalendarEvent], idx) => {
          schedule_errors[evtMeetingId] = meetingSlotCalendarEvent.map((event: MeetingHoldEventErrorResponseObject) => {
            return handleErrorFormatting(event);
          }
          );
        });
        if (meetingId) {
          dispatch({ type: ActionType.SET_MEETING_SCHEDULE_ERRORS, errors: schedule_errors[meetingId], meetingId });
        } else {
          if (!isEqual(state.schedule.scheduleErrors, schedule_errors)) {
            dispatch({ type: ActionType.SET_SCHEDULE_ERRORS, errors: schedule_errors });
          } 
        }
      }
      
    } catch (err) {
      console.error(err);
    }
  };

export const setMeetingSlotCalendarEventError = (
  { meetingId, errors }: { meetingId: number, errors: MeetingHoldEventError[] }
): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    dispatch({ type: ActionType.SET_MEETING_SCHEDULE_ERRORS, errors: errors, meetingId });
  };

export const cancelExternalMeeting = (
  meetingId: number, meetingToken: string, message: string
): ThunkResult<Promise<FetchReturn>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn> => {
    const res = await api.cancelExternalMeeting(meetingId, meetingToken, message);
    return res;
  };

export const duplicateMeeting = (
  meetingId: number, data: Partial<Meeting>
): ThunkResult<Promise<Meeting | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Meeting | undefined> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(data);
    const res = await fetchData<Meeting>(
      API_URLS.DUPLICATE_MEETING(meetingId), { headers, body, method: 'POST' }
    );
    
    if (res.status === 201) {
      dispatch({ type: ActionType.SAVED_MEETING, meeting: res.data });
      dispatch(fetchSlotsForMeeting(res.data.id.toString()));
      return res.data;
    }
  };

export const fetchExternalMeetingAnswers = (external_id: string, email?: string): ThunkResult<Promise<FetchReturn<{
  answers: {[id: number]: MeetingQuestionAnswer}
}> | undefined>> => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  const headers = await makeHeaders();
  const urlEncodedEmail = email ? encodeURIComponent(email) : undefined;
  const response = await fetchData<{ answers: {[id: number]: MeetingQuestionAnswer}}>(
    API_URLS.MEETING_QUESTION_ANSWER(external_id, urlEncodedEmail), {headers, method:"GET"}
  );
  if (response.status === 200) {
    dispatch({type: ActionType.SET_MEETING_QUESTION_ANSWERS, questionAnswers: response.data["answers"]});
    return response;
  }
};

export const fetchMultipleExternalMeetingAnswers = (ids: number[]): ThunkResult<Promise<FetchReturn<{
  answers: {[id: number]: MeetingQuestionAnswer}
}>| undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{
    answers: {[id: number]: MeetingQuestionAnswer};
  }> | undefined> => {
    const headers = await makeHeaders(true);
    const response = await fetchData(API_URLS.MULTIPLE_MEETING_QUESTION_ANSWER(ids), {headers, method:"GET"});
    if (response.status === 200) {
      dispatch({type: ActionType.SET_MEETING_QUESTION_ANSWERS, questionAnswers: response.data["answers"]});
      return response;
    }
  };

export const submitMeetingQuestionAnswer = (data: MeetingQuestionAnswerSubmission[]): ThunkResult<Promise<FetchReturn<{
  answers: MeetingQuestionAnswerSubmission[];
}>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{
    answers:MeetingQuestionAnswerSubmission[];
  }>> => {
    const headers = await makeHeaders();
    const body = JSON.stringify({"data": data});
    const response = await fetchData<{ answers: MeetingQuestionAnswerSubmission[] }>(
      API_URLS.MEETING_QUESTION_ANSWER_SUBMIT,
      { headers, body, method: 'POST' }
    );
    return response;
  };

export const fetchOrCreateExternalParticipant = (
  data: Omit<PublicExternalParticipant, "id" | "email_hash">
): ThunkResult<Promise<FetchReturn<PublicExternalParticipant>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<PublicExternalParticipant>> => {
    const res = await api.fetchOrCreateMeetingExternalParticipant(data);
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: ActionType.SET_EXTERNAL_PARTICIPANT, externalParticipant: res.data });
      // NOTE: currently we use this for private and public of setting participants. We don't want to
      // dispatch the private redux update if this is from a booking/poll link
      if (res.data.meeting) {
        const state = getState();
        if (state.schedule.meetings[res.data.meeting]) {
          dispatch({ type: ActionType.SET_MEETING_PARTICIPANT, externalParticipant: res.data });
        }
      }
    }
    return res;
  };

export const createMeetingParticipant = (
  data: PrivateExternalParticipantCreate, isSavedMeeting = true,
): ThunkResult<Promise<void>> => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  let updatedData: PrivateExternalParticipant = {
    ...data,
    id: generateUniqueUnsavedCabinetId(),
    email_hash: createHash('sha1').update((data.email || "").toLowerCase()).digest('hex'),
    name: data.name || '',
    first_response_date: '',
    no_times_comment: null,
    calendar_access: null,
    prevent_conflicts: false,
    view_calendar: false,
    is_fetchable: false,
  };

  if (updatedData.calendar_access === null) {
    const calendar = getState().schedule.calendars.find(
      cal => cal.owner_email === data.email && cal.is_owner_primary
    );
    if (calendar) {
      updatedData.calendar_access = calendar.calendar_access_id;
      updatedData.prevent_conflicts = true;
      updatedData.view_calendar = true;
      updatedData.is_fetchable = true;
    }
  }

  if (isSavedMeeting) {
    // optimistic update
    dispatch({ type: ActionType.SET_MEETING_PARTICIPANT, externalParticipant: updatedData, isSavedMeeting });

    const res = await api.createMeetingExternalParticipant({ ...data, preregistered: true });

    // remove optimistic data once request finishes
    dispatch({
      type: ActionType.DELETED_MEETING_PARTICIPANT,
      participantId: updatedData.id, meetingId: updatedData.meeting, isSavedMeeting: true
    });
    if (res.status === 201) {
      updatedData = res.data;
    } else {
      return;
    }
  }

  dispatch({ type: ActionType.SET_MEETING_PARTICIPANT, externalParticipant: updatedData, isSavedMeeting });
};

export const updateMeetingParticipant = (
  update: PrivateExternalParticipantUpdate, meetingId: number, isSavedMeeting = true,
): ThunkResult<Promise<void>> => async (dispatch, getStore) => {
  const data = { ...update, no_times_comment: update.no_times_comment || null };

  if (isSavedMeeting) {
    // TODO: we should be using the updated values returned from this in the dispatch
    const res = await api.updateMeetingParticipant(data);
    if (res.status !== 200) return;
  }

  const { id, ...participant } = data;

  dispatch({
    type: ActionType.UPDATED_MEETING_PARTICIPANT,
    participantId: id,
    meetingId,
    externalParticipant: participant,
    isSavedMeeting,
  });
};

export const deleteMeetingParticipant = (
  participantId: number, meetingId: number, isSavedMeeting = true,
): ThunkResult<Promise<void>> =>
  async (dispatch, getStore) => {
    if (isSavedMeeting) {
      const res = await api.deleteMeetingParticipant(participantId);
      if (res.status !== 204) return;
    }

    dispatch({ type: ActionType.DELETED_MEETING_PARTICIPANT, participantId, meetingId, isSavedMeeting });
  };

export const fetchParticipantAutocompleteOptions = (q?: string): ThunkResult<Promise<void>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    if (!q) {
      dispatch({type: ActionType.FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS, options: []});
    } else {
      const headers = await makeHeaders(true);
      const params = makeParams({q});
      const res = await fetchData<AutocompleteRecord[]>(
        API_URLS.PARTICIPANT_AUTOCOMPLETE + params,
        { headers, method: 'GET' },
      );
      if (res.status === 200) {
        dispatch({type: ActionType.FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS, options: res.data});
      }
    }
  };

export const updateMeetingLeader = (
  data: MeetingLeaderUpdate, meetingId: number, leaderId: number, isSavedMeeting = true,
): ThunkResult<Promise<void>> => async (dispatch, getStore) => {
  let { id, ...updatedData } = data;

  if (isSavedMeeting) {
    const res = await api.updateMeetingLeader(data);
    if (res.status !== 200) return;

    ({ id, ...updatedData } = res.data);
  }

  dispatch({
    type: ActionType.UPDATED_MEETING_LEADER,
    meetingLeader: updatedData,
    meetingId,
    leaderId,
    isSavedMeeting,
  });
};

export const removeMeetingLeader = (
  meetingId: Meeting['id'], meetingLeaderId: MeetingLeader['id'],
): ThunkResult<Promise<void>> => async (dispatch, getStore) => {
  const meeting = meetingId > 0
    ? getStore().schedule.meetings[meetingId]
    : getStore().schedule.newMeeting;

  if (!meeting) return;

  const newLeaderIds = meeting.leader_info
    ?.filter(li => li.meeting_leader_id !== meetingLeaderId)
    .map(li => li.id);

  const meetingUpdate = {
    id: meetingId,
    leaders: newLeaderIds,
  };

  if (meetingUpdate.id < 0) {
    await dispatch(updateNewMeeting(meetingUpdate));
  } else {
    await dispatch(updateMeeting(meetingUpdate, []));
  }
};


export const fetchMeetingRooms = (): ThunkResult<Promise<FetchReturn<{[id: string]: MeetingRoom}>>> =>
  async (dispatch, getStore): Promise<FetchReturn<{[id: string]: MeetingRoom}>> => {
    const res = await api.fetchMeetingRooms();
    if (res.status === 200) {
      dispatch({type: ActionType.SET_MEETING_ROOMS, rooms: res.data});
    }
    return res;
  };

const lastAuditLogFetchTime: {
  time: DateTime | null;
} = {
  time: null
};

export const fetchMeetingAuditLogs = (limit: number): 
ThunkResult<Promise<FetchReturn<{[id: string]: MeetingAuditLog}>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const params = makeParams({ fetch_meeting_changes: limit });
    const state = getState();
    lastAuditLogFetchTime['time'] = DateTime.now();
    const res = await fetchData(API_URLS.MEETING_AUDIT_LOGS + params, { headers, method: 'GET' });
    if (res.status === 200 && 
      (Object.keys(res.data).length === 0 || !isEqual(state.schedule.meetingAuditLogs, res.data))) {
      dispatch({type: ActionType.SET_MEETING_AUDIT_LOGS, auditLogs: res.data});
    }
    return res;
  };

let auditLogsPollingActiveTimeout: NodeJS.Timeout | undefined;

export const startPollingAuditLogs = (limit: number): ThunkResult<Promise<(() => void)>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<(() => void)> => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    const stopPolling = () => {
      if (timeout) clearTimeout(timeout);
      auditLogsPollingActiveTimeout = undefined;
    };

    const pollFunc = async () => {
      let timeoutMs = pollAuditLogsIntervalMs;
      const lastFetchTime = lastAuditLogFetchTime['time'];
      
      if (lastFetchTime) {
        const diff = DateTime.now().toMillis() - lastFetchTime.toMillis();

        if (diff < pollAuditLogsIntervalMs) {
          timeoutMs = (pollAuditLogsIntervalMs - diff) + 10;
        } else {
          await dispatch(fetchMeetingAuditLogs(limit));
        }
      } else {
        await dispatch(fetchMeetingAuditLogs(limit));
      }

      if (timeout === auditLogsPollingActiveTimeout && pollAuditLogsIntervalMs) {
        timeout = setTimeout(pollFunc, timeoutMs);
        auditLogsPollingActiveTimeout = timeout;
      }
    };

    if (auditLogsPollingActiveTimeout != null) {
      clearTimeout(auditLogsPollingActiveTimeout);
      auditLogsPollingActiveTimeout = undefined;
    }
    await pollFunc();

    return stopPolling;
  };

export const fetchPresetLocations = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const res = await fetchData(API_URLS.PRESET_LOCATIONS, { headers, method: 'GET' });
    if (res.status === 200) {
      dispatch({ type: ActionType.FETCHED_PRESET_LOCATIONS, locations: res.data });
    }
  };

export const createPresetLocation = (name: string, location: string): ThunkResult<Promise<void>> =>
  async (dispatch, getStore) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ name, location });
    const res = await fetchData<PresetLocation>(API_URLS.PRESET_LOCATIONS, { headers, body, method: 'POST' });
    if (res.status === 201) {
      dispatch({ type: ActionType.UPDATED_PRESET_LOCATION, id: res.data.id, location: res.data });
    }
  };

export const updatePresetLocation = (id: number, name: string, location: string): ThunkResult<Promise<void>> =>
  async (dispatch, getStore) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ name, location });
    const res = await fetchData<PresetLocation>(`${API_URLS.PRESET_LOCATIONS}${id}/`, { headers, body, method: 'PUT' });
    if (res.status === 200) {
      dispatch({ type: ActionType.UPDATED_PRESET_LOCATION, id: res.data.id, location: res.data });
    }
  };

export const deletePresetLocation = (id: number): ThunkResult<Promise<void>> =>
  async (dispatch, getStore) => {
    const headers = await makeHeaders(true);
    const res = await fetchData(`${API_URLS.PRESET_LOCATIONS}${id}/`, { headers, method: 'DELETE' });
    if (res.status === 204) {
      dispatch({ type: ActionType.DELETED_PRESET_LOCATION, id });
    }
  };

export const deleteMeetingFile = (id: number, meetingId: number): ThunkResult<Promise<void>> =>
  async (dispatch, getStore) => {
    const headers = await makeHeaders(true);
    const store = getStore();
    const meeting = store.schedule.meetings[meetingId];
    const res = await fetchData(`${API_URLS.MEETING_FILE}${id}/`, { headers, method: 'DELETE' });
    if (res.status === 204) {
      const meetingUpdate = {...meeting, files: meeting.files.filter(file => file.id !== id)};
      dispatch({type: ActionType.UPDATED_MEETING, meeting: meetingUpdate });
    }
  };

type AdditionalCalendarResponse = {
  calendar: CalendarAssociation;
  remote_calendar: Calendar;
};

export const addAdditionalCalendar = (
  email: string, name: string, providerId?: number,
): ThunkResult<Promise<AdditionalCalendarResponse|undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ email, name, provider_id: providerId });
    try {
      const res = await fetchData<AdditionalCalendarResponse>(
        API_URLS.GET_ADDITIONAL_CALENDAR, { headers, body, method: 'POST' }
      );
      if (res.status === 200) {
        dispatch({ 
          type: ActionType.CREATED_CALENDAR,
          calendar: convertCalendarAssociationToCalendar({...res.data.calendar, ...res.data.remote_calendar})
        });
        return res.data;
      } else {
        throw 'data' in res ? res.data : res.status;
      }
    } catch (err) {
      console.error(err);
    }
  };

export const updateAdditionalCalendar = (
  id: Calendar['id'], name: string
): ThunkResult<Promise<CalendarAssociation|undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ id, name });
    try {
      const res = await fetchData<CalendarAssociation>(
        API_URLS.UPDATE_ADDITIONAL_CALENDAR, { headers, body, method: 'PATCH' }
      );
      if (res.status === 200) {
        dispatch({
          type: ActionType.UPDATED_CALENDAR,
          calendar: convertCalendarAssociationToCalendar(res.data)
        });
        return res.data;
      } else {
        throw 'data' in res ? res.data : res.status;
      }
    } catch (err) {
      console.error(err);
    }
  };

export const sendNoTimesWork = (
  data: NormalizedExternalParticipant
): ThunkResult<Promise<boolean|undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    try {
      const res = await api.sendNoTimesWork(data);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      cabCaptureException(err);
      return false;
    }
  };

export const bulkUpsertMeetingLeaders = (meetingLeader: MeetingLeader[]): ThunkResult<Promise<boolean>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const meetingId = meetingLeader[0].meeting;
    let newLeaderInfo = meetingLeader;

    if (meetingId > 0) {
      try {
        const res = await api.bulkUpsertMeetingLeaders(meetingLeader);
        if (res.status === 200) {
          newLeaderInfo = res.data.data;
        }
      } catch (err) {
        cabCaptureException(err);
        return false;
      }
    }

    const { schedule: { meetings, newMeeting }, leaders: { leaders } } = getState();

    const meeting = meetingId > 0 ? meetings[meetingId] : newMeeting;
    if (!meeting) return false;

    const curLeaderInfo = meeting.leader_info || [];
    const newMeetingLeaderInfo = newLeaderInfo
      .map(ml => {
        const ldr = leaders.find(l => l.id === ml.leader);
        if (!ldr) return null;
        return { ...ldr, ...ml, id: ldr.id, meeting: meetingId, leader: ldr.id, meeting_leader_id: ml.id };
      })
      .filter(li => !!li) as MeetingLeaderInfo[];

    const leaderInfoUpdate = uniqBy([...newMeetingLeaderInfo, ...curLeaderInfo], li => li.id);
    const meetingUpdate: MeetingUpdate = {
      id: meetingId,
      leaders: leaderInfoUpdate.map(li => li.id),
      leader_info: leaderInfoUpdate,
    };

    if (meetingId > 0) {
      dispatch({ type: ActionType.UPDATED_MEETING, meeting: meetingUpdate });
    } else {
      dispatch({ type: ActionType.UPDATED_NEW_MEETING, meeting: meetingUpdate });
    }

    return true;
  };

export const addMeetingLeader = (
  meetingId: Meeting['id'], leaderId: Leader['id'],
): ThunkResult<Promise<void>> => async (dispatch, getStore) => {
  await dispatch(bulkUpsertMeetingLeaders([{
    id: generateUniqueUnsavedCabinetId(),
    meeting: meetingId,
    leader: leaderId,
  }]));
};

export const listMeetingParticipants = (args: Parameters<typeof api.listMeetingParticipants>[0]) => 
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    try {
      const res = await api.listMeetingParticipants(args);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      cabCaptureException(err);
      return false;
    }
  };

