import { ReactElement, ReactNode, useState } from "react";
import {
  MenuItem, MenuItemProps, Select, SelectChangeEvent, SelectProps,
  styled, useTheme, ListSubheader, Divider, Box, Typography
} from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";
import { CabIcon } from "../CabIcon";
import { CabTooltip } from "../CabTooltip";
import { IoChevronDown } from "react-icons/io5";

export type CabDropdownOption<T extends MenuItemProps["value"]> = {
  value: T;
  label: string | ReactElement;
  disabled?: boolean;
  tooltipText?: string;
  subtext?: string;
  IconComponent?: ReactElement;
};

export interface CabDropdownProps<T extends MenuItemProps["value"]> extends CabComponentProps<SelectProps> {
  label?: string | ReactElement;
  size?: SelectProps['size'] | 'xsmall';
  disabled?: SelectProps['disabled'];
  value?: T|T[];
  multiple?: SelectProps['multiple'];
  placeholder?: SelectProps['placeholder'];
  placeholderColor?: string;
  defaultValue?: T;
  inputId?: string;
  hideArrow?: boolean;
  sx?: SelectProps['sx'];
  menuItemSx?: SelectProps['sx'];
  options: (CabDropdownOption<T> | {
    groupHeader: string;
  })[];
  onChange?: ((event: SelectChangeEvent<T>) => void) | undefined;
  onBlur?: SelectProps['onBlur'];
  onOpen?: SelectProps['onOpen'];
  onClose?: SelectProps['onClose'];
  onChangeOverrides?: {[value: string | number]: {
    onChange: (e: SelectChangeEvent<T>) => void;
    preventDefault: boolean;
  }};
  hideOptionTooltipsWhenClosed?: boolean;
  inputRef?:  React.Ref<ReactElement | HTMLInputElement>;
  pretty?: boolean;
}

export const CabDropdown = <T extends MenuItemProps["value"]>({
  label, options, value, defaultValue, disabled, size, inputId, hideArrow,
  overrides, sx, onChange, onChangeOverrides, multiple, placeholder, onOpen, onClose, onBlur,
  hideOptionTooltipsWhenClosed, menuItemSx, placeholderColor, inputRef, pretty,
}: CabDropdownProps<T>): ReactElement => {
  const xsmall = size === "xsmall";
  const props: SelectProps = {
    label,
    size: xsmall ? 'small' : size,
    disabled,
    multiple,
    placeholder,
    sx,
    onBlur,
    IconComponent: iconProps => (hideArrow ? null : <CabIcon {...iconProps} Icon={IoChevronDown} />)
  };

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const MenuProps = {
    PaperProps: {
      sx: {
        '& .MuiMenuItem-root': {
          fontSize: size === 'small'
            ? 14
            : 16
        },
      },
    },
  };

  const handleChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
    const event = e as SelectChangeEvent<T>;
    const newValue = event.target.value as keyof typeof onChangeOverrides;
    const foundOption = options.find(option => 'value' in option && option.value === newValue);
    if (foundOption && 'disabled' in foundOption && foundOption?.disabled) {
      return;
    }
    if (onChangeOverrides) {
      if (onChangeOverrides[newValue]) {
        onChangeOverrides[newValue].onChange(event);
        if (onChangeOverrides[newValue].preventDefault) {
          return;
        }
      }
    }
    if (onChange) onChange(event);
  };

  const handleOpen = (e: React.SyntheticEvent<Element, Event>) => {
    setIsOpen(true);
    if (onOpen) onOpen(e);
  };

  const handleClose = (e: React.SyntheticEvent<Element, Event>) => {
    setIsOpen(false);
    if (onClose) onClose(e);
  };

  props.MenuProps = MenuProps;

  return (
    <SelectStyled
      {...props}
      pretty={pretty}
      inputRef={inputRef}
      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}
      xsmall={xsmall}
      displayEmpty
      renderValue={(v: unknown) => <RenderValue<T> 
        value={v} 
        multiple={multiple} 
        placeholder={placeholder} 
        placeholderColor={placeholderColor}
        options={options} 
      />}
      {...overrides}
      value={value}
      defaultValue={defaultValue}
      inputProps={{
        id: inputId,
      }}
    >
      {options.map((option, idx) => {
        if ('groupHeader' in option) {
          return (
            <ListSubheader
              sx={{ lineHeight: '28px', color: colors.black500, paddingLeft: '6px' }}
              key={option.groupHeader}
            >
              {idx !== 0 && <Divider sx={{ bgcolor: colors.black900, height: 1 }} />}
              {option.groupHeader}
            </ListSubheader>
          );
        }

        return (
          <MenuItem 
            value={option.value}
            key={(Array.isArray(option.value) ? option.value.join("").toString() : option.value) as string}
            sx={{
              color: theme.palette.mode === 'dark' ? colors.white900 : colors.black900,
              ...(option.disabled ? {
                backgroundColor: theme.palette.mode === 'dark' ? colors.black800 : colors.white800,
                cursor: "unset",
                color: theme.palette.mode === 'dark' ?   
                  colors.white400
                  :
                  colors.black400,
                "&:hover" : {
                  backgroundColor: colors.white800
                }

              } : null),
              ...menuItemSx
            }}
          >
            <CabTooltip title={(hideOptionTooltipsWhenClosed && !isOpen) ? '' : option.tooltipText}>
              <Box component={'span'} width="100%" display="flex" alignItems="center">
                {option.IconComponent && (
                  <Box sx={{ marginRight: 1 }}>
                    {option.IconComponent}
                  </Box>
                )}
                {/* We may want to use this in the future for multi selects
                (multiple && Array.isArray(value)) &&
                  <CabCheckbox
                    sx={{marginRight: 2}}
                    checked={value.includes(option.value)}
                  />
                */}
                <Box component={'span'}>
                  {option.label}
                  <Box sx={{color: colors.black600}}>
                    {option.subtext}
                  </Box>
                </Box>
              </Box>
            </CabTooltip>
          </MenuItem>
        );
      })}
    </SelectStyled>
  );
};

type RenderValueProps<T extends MenuItemProps["value"]> = {
  value: unknown, 
  multiple?: boolean, 
  placeholder?: string,
  placeholderColor?: string, 
  options: (CabDropdownOption<T> | {
    groupHeader: string;
  })[]
};

function RenderValue<T extends MenuItemProps["value"]>(
  {value, multiple, placeholder, options, placeholderColor}: RenderValueProps<T>
): ReactElement {
  const castValue = value as T;
  const selectedOptions = options.filter(opt => {
    const castOpt = opt as CabDropdownOption<T>;
    if (Array.isArray(castValue)) {
      return castValue.includes(castOpt.value);
    }
    return castOpt.value === castValue;
  }) as unknown as CabDropdownOption<T>[];

  return !selectedOptions.length  || (multiple && Array.isArray(value) && value.length === 0)
    ? (placeholder ? <Typography sx={{
      color: placeholderColor ?? colors.black700, fontStyle: "italic"}}
    >
      {placeholder}
    </Typography>
      :
      <></>
    )
    : <Box 
      display="flex" 
      flexWrap="nowrap" 
      overflow="hidden"
      sx={{
        textOverflow: "ellipsis"
      }}
    >
      {selectedOptions.map((opt, idx) => (
        <Box
          key={opt.value as string | number}
          sx={{
            display: "flex",
            marginRight: 0.5,
            textOverflow: "ellipsis",
            alignItems: "center",
          }}
        >
          {opt?.IconComponent && <Box sx={{ marginRight: 1 }}>{opt?.IconComponent}</Box>}
          {opt?.label}
          {idx !== selectedOptions.length - 1 && <>,</> }
        </Box>
      ))}
    </Box>;
}

const SelectStyled = styled(
  Select, { shouldForwardProp: (prop) => prop !== 'xsmall' && prop !== 'pretty', label: "SelectStyled"}
)<CabComponentProps<SelectProps> & {xsmall?: boolean, pretty?: boolean}>(({ theme, size, xsmall, pretty }) => ({
  ...(theme.palette.mode === 'dark' ? {
    color: colors.white900,
    '& .MuiSelect-icon': {
      color: colors.white800
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.white800,
      borderWidth: pretty ? 0 : 2
    },
    '&:hover': {
      backgroundColor: colors.white50,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.white800,
        borderWidth: pretty ? 0 : 1,
      },
    },
    '& fieldset': {
      borderColor: colors.white300,
    },
    '& .MuiOutlinedInput-input.Mui-disabled':{
      WebkitTextFillColor: colors.white500,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.white50,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.white300,
      },
      '& .MuiSelect-icon': {
        color: colors.white800
      },
    },
  } : {
    color: colors.black900,
    '& .MuiSelect-icon': {
      color: colors.black900
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.black800,
      borderWidth: pretty ? 0 : 2
    },
    '&:hover': {
      backgroundColor: colors.black50,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.black800,
        borderWidth: pretty ? 0 : 1,
      },
    },
    '& fieldset': {
      borderColor: colors.black200,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      WebkitTextFillColor: colors.black500,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.black50,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.black200,
      },
    },
  }),
  height: size === 'small'
    ? xsmall ? 26 : 32
    : 40,
  fontSize: size === 'small'
    ? 14
    : 16,
  '&.MuiMenuItem-root': {
    fontSize: 5
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: pretty ? 0 : 1
  }
}));