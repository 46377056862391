
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";


export const selectOrderedLeaders = createSelector([
  (state: RootState) => state.leaders.leaders,
], (leaders) => {
  return leaders.toSorted((a, b) => a.order - b.order);
});

export const selectOrderedSchedulingLeaders = createSelector([
  selectOrderedLeaders,
], (leaders) => leaders.filter(leader => 
  !leader.is_shared
  || (leader.is_shared && leader.permissions.scheduling
    && Object.values(leader.permissions.scheduling).some((perm: boolean) => perm)
  )
));
