import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createSelector, lruMemoize } from 'reselect';
import store, {
  actions, RootState, User, MeetingSlot, RecurringTimes, Meeting, Calendar,
  UICalendarEventConsolidated, ParticipantWithSelections, ParticipantsWithSelections,
  MeetingLeader,
} from '..';
import colors from '../../colors';
import { PROVIDER_BY_ID, PROVIDER_CONFERENCE_TYPES, ZOOM_CONNECT_STRATEGY } from '../../constants';
import { checkForProviderGrant } from '../../utils/authUtils';
import { createMeetingSlotsFromRecurringTimes, getPrimaryCalendar } from '../../utils/scheduleUtils';
import { isEqual } from 'lodash-es';

const actionCreators = bindActionCreators({
  fetchPromoMeetings: actions.staticData.fetchPromoMeetings,
}, store.dispatch);

export const getAvailableConferenceProviders = (
  state: RootState, leaderIdFilter?: number[],
): { id: number; label: string }[] => {
  const conferenceProviders: { id: number; label: string }[] = [];
  const providerPresent: number[] = [];
  const grants = state.auth.user?.oauth_grant_details;
  if (grants) {
    Object.keys(PROVIDER_CONFERENCE_TYPES)
      .filter(providerKey => (
        // filter out providers the user does not have grants for
        checkForProviderGrant(grants, PROVIDER_BY_ID[Number(providerKey)].name)
      ))
      .flatMap((providerKey) => PROVIDER_CONFERENCE_TYPES[providerKey]).forEach((record) => {
        if (!providerPresent.includes(record.id)) {
          conferenceProviders.push(record);
          providerPresent.push(record.id);
        }
      });
  }
  return conferenceProviders;
};

export const getConferenceProvidersForLeaders = (state: RootState, leaderIdFilter: number[]): {
  conferenceProviderId: number; leaderId: number|null; label: string
}[] => {
  return getConferenceProvidersForLeadersParameterized(
    state.auth.user, state.schedule.zoomSettings, state.leaders, leaderIdFilter
  );
};


export const getConferenceProvidersForLeadersParameterized = (
  user: User | undefined | null,
  zoomSettings: RootState["schedule"]["zoomSettings"],
  leaders: RootState["leaders"],
  leaderIdFilter: number[],
  defaultZoomAccountLabel = 'Default Account',
  addUsernameToLabel = true,
): {
  conferenceProviderId: number; leaderId: number|null; label: string
}[] => {
  const options: { conferenceProviderId: number; leaderId: number|null; label: string }[] = [];
  
  Object.keys(PROVIDER_CONFERENCE_TYPES)
    .forEach((providerKey) => {
      const hasProviderGrant = user && checkForProviderGrant(
        user.oauth_grant_details, PROVIDER_BY_ID[Number(providerKey)].name
      );
      const grantUsername = hasProviderGrant
        ? Object.keys(user.oauth_grant_details[PROVIDER_BY_ID[Number(providerKey)].name])[0]
        : null;
      PROVIDER_CONFERENCE_TYPES[providerKey].forEach(conference => {
        if (conference.id === 3) {
          Object.values(zoomSettings)
            .filter(stg => !stg.leader || leaderIdFilter.includes(stg.leader))
            .forEach(stg => {
              const leader = leaders.leaders.find(l => l.id === stg.leader);
              let leaderFullName = leader ? leader.first_name : '';
              if (leader?.last_name) leaderFullName += ` ${leader.last_name[0]}`;
              let label = conference.label;
              if (leaderFullName) label += ` - ${leaderFullName}`;
              if (!leader) label += ` - ${defaultZoomAccountLabel}`;
              let providerIsValid = true;
              if (stg.method === ZOOM_CONNECT_STRATEGY.OBO
                && stg.schedule_obo && (grantUsername || leader?.is_shared)) {
                // Assigned OBO user for the leader and Zoom authed or the leader is shared
                if (addUsernameToLabel) {
                  label += ` (${stg.schedule_obo.username})`;
                }
              } else if (grantUsername) {
                // Zoom authed, but no method specified for exec, so using base account
                if (addUsernameToLabel) {
                  label += ` (${grantUsername})`;
                }
              } else {
                // No Zoom auth and PMI not selected for this leader, so not a valid Zoom option
                providerIsValid = false;
              }
              if (providerIsValid) {
                options.push({
                  conferenceProviderId: conference.id,
                  leaderId: stg.leader,
                  label,
                });
              }
            });
        } else if (grantUsername) {
          options.push({
            conferenceProviderId: conference.id,
            leaderId: null,
            label: conference.label,
          });
        }
      });
    });

  return options;
};


export const selectRealOrPromoMeetings = createSelector([
  (state: RootState) => state.auth.user?.features,
  (state: RootState) => state.schedule.meetings,
  (state: RootState) => state.staticData.promoMeetings,
], (features, meetings, promoMeetings) => {
  if (features?.MEETING_POLLS) {
    return meetings;
  } else {
    if (Object.keys(promoMeetings).length === 0) {
      actionCreators.fetchPromoMeetings();
    }
    return promoMeetings;
  }
});

export const selectLeaderLookup = createSelector([
  (state: RootState) => state.leaders.leaders,
], (leaders) => {
  return leaders.map(l => ({ [l.id]: l }))
    .reduce((a, b) => ({ ...a, ...b }), {});
});

export const selectLeadersWithCalendars = createSelector([
  (state: RootState) => state.leaders.leaders,
], (leaders) => {
  return leaders.filter(l => Object.values(l.leader_calendars).length > 0);
});

export const selectMeetingLeaders = createSelector([
  (state: RootState) => state.schedule.calendars,
  (state: RootState, meetingId: Meeting['id']) => selectMeeting(state, meetingId),
], (calendars, meeting): MeetingLeader[] => {
  if (!meeting) return [];

  return meeting.leader_info?.map(l => ({
    ...l,
    id: l.meeting_leader_id,
    meeting: meeting.id, leader: l.id,
    required: l.required != null ? l.required : true,
    prevent_conflicts: l.prevent_conflicts != null ? l.prevent_conflicts : true,
    should_invite: l.should_invite != null ? l.should_invite : true,
    view_calendar: l.view_calendar != null ? l.view_calendar : true,
    is_fetchable: Object.values(calendars).filter(c => c.leaders.includes(l.id)).some(c => c.calendar_access_id),
  })) || [];
});

export const selectCalendarLookupByCalendarId = createSelector([
  (state: RootState) => state.schedule.calendars,
], (calendars) => {
  return calendars.map(c => ({ [c.calendar_id]: c }))
    .reduce((a, b) => ({ ...a, ...b }), {});
});

export const selectMeeting = createSelector([
  (state: RootState) => state.schedule.newMeeting,
  (state: RootState, meetingId: Meeting['id']) => state.schedule.meetings[meetingId],
  (state: RootState, meetingId: Meeting['id']) => meetingId,
], (newMeeting, savedMeeting, meetingId) => {
  return meetingId < 0 ? newMeeting : savedMeeting;
});

export const selectUserRecurringMeetingSlots = createSelector([
  (state: RootState) => state.schedule.meetings,
  (state: RootState) => state.schedule.newMeeting,
  (state: RootState, selectedSlots: MeetingSlot[]) => selectedSlots,
  (state: RootState, selectedSlots: MeetingSlot[], start?: DateTime) => start,
  (state: RootState, selectedSlots: MeetingSlot[], start?: DateTime, end?: DateTime) => end,
  (state: RootState, selectedSlots: MeetingSlot[], start?: DateTime, end?: DateTime, meetingId?: number) => meetingId,
], (meetings, newMeeting, selectedSlots, start, end, meetingId) => {
  const meeting = meetingId
    ? meetingId > -1 ? meetings[meetingId] : newMeeting
    : null;

  const meetingsToCheck = (meetingId
    ? meeting?.recurring_times ? [meeting] : []
    : Object.values(meetings).filter(mtg => mtg.recurring_times)) as (
    Partial<Meeting> & { recurring_times: RecurringTimes }
  )[];

  if (!start || !end) return [];

  return meetingsToCheck.flatMap(mtg => {
    const excludedSlots = selectedSlots.filter(slot => slot.meeting === mtg.id && slot.is_exclude);
    const startDate = DateTime.now();
    return createMeetingSlotsFromRecurringTimes(
      mtg.recurring_times, start, end, startDate, excludedSlots, mtg.id || -1
    );
  });
});


// Is there a way to take advantage of default cache so we don't have to specify cache size?
// const createSelectArray = <T>(arr: T[]) => {
//   const selectArray = createSelector(
//     arr.map((el, i) => (...e: T[]) => e[i]),
//     (...args) => args,
//   );
//   return selectArray;
// };
// const a = createSelectArray(['thing', 'thing2'])
// a.apply(null, ['thing', 'thing2'])

const memoizeCalendarIds = lruMemoize(
  (calendarIds: Set<string>) => [...calendarIds].sort(),
  { equalityCheck: isEqual, maxSize: 20 },
);

export const selectEvents = createSelector([
  (state: RootState) => state.schedule.events,
], (events) => {
  return Object.values(events);
});

export const selectUserCalendarEvents = createSelector([
  (state: RootState) => state.schedule.calendars,
  (state: RootState) => state.schedule.events,
  selectLeaderLookup,
  selectCalendarLookupByCalendarId,
  (state: RootState, calendarIds: Set<Calendar['calendar_id']>) => memoizeCalendarIds(calendarIds),
  (state: RootState, calendarIds: Set<Calendar['calendar_id']>, start?: string) => start,
  (state: RootState, calendarIds: Set<Calendar['calendar_id']>, start?: string, end?: string) => end,
  (state: RootState, calendarIds: Set<Calendar['calendar_id']>, start?: string, end?: string,
    canceledDeclinedMeetings?: boolean) => canceledDeclinedMeetings,
], (
  calendars, events, leaderLookup, calendarLookupByCalendarId, calendarIds,
  start, end, canceledDeclinedMeetings = false,
) => {
  const startDateMillis = start != null ? DateTime.fromISO(start).toMillis() : undefined;
  const endDateMillis = end != null ? DateTime.fromISO(end).toMillis() : undefined;
  let filteredEvents = Object.values(events)
    .filter(event => calendarIds.includes(event.calendarId))
    .filter(event => !canceledDeclinedMeetings ? event.selfResponse !== 'declined' : true);

  if (startDateMillis && endDateMillis) {
    filteredEvents = filteredEvents.filter(event => {
      const eventStartMillis = DateTime.fromISO(event.start).toMillis();
      const eventEndMillis = DateTime.fromISO(event.end).toMillis();

      return (eventStartMillis > startDateMillis && eventStartMillis < endDateMillis)
        || (eventEndMillis > startDateMillis && eventEndMillis < endDateMillis);
    });
  }

  const consolidatedEvent: {[key: string]: UICalendarEventConsolidated} = {};
  const leaderInfo: {[key: string]: Set<number>} = {};

  filteredEvents.forEach((event) => {
    const borderColor = event.backgroundColor ? event.backgroundColor : calendars.find(
      (cal: Calendar) => cal.calendar_id === event.calendarId
    )?.backgroundColor || colors.black900;

    const calendarInfo = calendarLookupByCalendarId[event.calendarId];
    const eventLeaders = calendarInfo?.leaders || [];
    if (leaderInfo[event.unique_id]) {
      eventLeaders.forEach(l => leaderInfo[event.unique_id].add(l));
    } else {
      leaderInfo[event.unique_id] = new Set(eventLeaders);
    }

    const backgroundColor = chroma(borderColor || colors.black900).luminance(0.85).hex();
    const textColor = colors.white100;
    if (consolidatedEvent[event.unique_id]) {
      consolidatedEvent[event.unique_id].borderColor = colors.black900;
      consolidatedEvent[event.unique_id].backgroundColor = chroma(colors.black900).luminance(0.85).hex();
      
      if (calendarInfo) {
        consolidatedEvent[event.unique_id].calendarInfo?.push(calendarInfo);
      }

      // we want to prioritize information from calendars we have the best access to
      if (calendarInfo?.canEdit) {
        consolidatedEvent[event.unique_id].title = event.title;
        consolidatedEvent[event.unique_id].backgroundColor = backgroundColor;
        consolidatedEvent[event.unique_id].borderColor = borderColor;
        consolidatedEvent[event.unique_id].textColor = textColor;
        consolidatedEvent[event.unique_id].attendees = event.attendees;
        consolidatedEvent[event.unique_id].busy = event.busy;
        consolidatedEvent[event.unique_id].leaderBackgroundColors.unshift(backgroundColor);
        consolidatedEvent[event.unique_id].leaderBorders.unshift({color: borderColor, status: event.selfResponse});
      } else {
        consolidatedEvent[event.unique_id].leaderBackgroundColors.push(backgroundColor);
        consolidatedEvent[event.unique_id].leaderBorders.push({color: borderColor, status: event.selfResponse});
      }

      // If at least one calendar has not declined, show as not declined
      // TODO: We should handle "tentative" similarly, maybe even "needsAction", but with different UI for each
      const curSelfResponse = consolidatedEvent[event.unique_id].selfResponse;
      const newSelfResponse = curSelfResponse === "declined" ? event.selfResponse : curSelfResponse;
      consolidatedEvent[event.unique_id].selfResponse = newSelfResponse;
    } else {
      consolidatedEvent[event.unique_id] = {
        ...event,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        leaderBackgroundColors: [backgroundColor],
        leaderBorders: [{color: borderColor, status: event.selfResponse}],
        attendees: event.attendees,
        calendarInfo: calendarInfo ? [calendarInfo] : [],
        textColor: textColor,
        busy: event.busy
      };
    }
  });

  Object.keys(consolidatedEvent).forEach(eId => {
    const leaders = leaderInfo[eId] ? Array.from(leaderInfo[eId]) : [];
    consolidatedEvent[eId].leaderInfo = leaders.map(l => leaderLookup[l]).filter(l => l !== undefined);
  });

  return Object.values(consolidatedEvent);
});

export const selectMeetingSlots = createSelector([
  (state: RootState) => state.schedule.meetingSlots,
  (state: RootState, meetingId?: Meeting['id']) => meetingId,
], (meetingSlots, meetingId) => {
  if (!meetingId) return [];

  return meetingSlots.filter(s => s.meeting === meetingId);
});


export const selectMeetingBookingSlots = createSelector([
  (state: RootState) => state.schedule.meetings,
  (state: RootState) => state.schedule.publicMeetings,
  (state: RootState, meetingId?: Meeting['id']) => meetingId,
], (meetings, publicMeetings, meetingId) => {
  if (!meetingId) return { booking_slots: [], conflict_slots: [] };

  const meeting = meetings[meetingId];
  if (!meeting?.external_id) return { booking_slots: [], conflict_slots: [] };

  const externalMeeting = publicMeetings[meeting.external_id];
  if (!externalMeeting) return { booking_slots: [], conflict_slots: [] };

  return {
    booking_slots: externalMeeting.booking_slots,
    conflict_slots: externalMeeting.conflict_slots,
  };
});

export const selectLocations = createSelector([
  (state: RootState) => state.schedule.meetings,
  (state: RootState) => state.schedule.presetLocations,
  (state: RootState) => state.schedule.meetingRooms,
  (state: RootState, meetingId?: Meeting['id']) => meetingId,
], (meetings, presetLocations, meetingRooms, meetingId): string[] => {
  if (!meetingId) {
    return [];
  }

  // Meeting may be undefined so we need to check if meeting exists before each of these fields are computed
  const meeting = meetings[meetingId];

  const conferenceProvider = !meeting ? undefined : (
    Object.values(PROVIDER_CONFERENCE_TYPES)
      .flatMap(c => c)
      .find(c => c.id === meeting.conference_provider)
      ?.label
  );

  const meetingPresetLocations: string[] = !meeting ? [] : (
    meeting.location_presets.map(location => {
      return Object.values(presetLocations).find(p => p.id === location)?.name;
    })
  );

  const meetingLocationRooms: string[] = !meeting ? [] : (
    meeting.rooms.map(room => {
      return Object.values(meetingRooms).find(r => r.id === room)?.display_name || '';
    })
  );

  return [
    ...(conferenceProvider ? [conferenceProvider] : []),
    ...meetingLocationRooms,
    ...meetingPresetLocations,
    ...(meeting?.locations || []),
  ];

});

export const selectParticipantsWithSelections = createSelector([
  (state: RootState) => state.schedule.meetings,
  (state: RootState, meetingId?: Meeting['id']) => meetingId,
], (meetings, meetingId): ParticipantsWithSelections => {
  if (!meetingId) {
    return [];
  }

  const meeting = meetings[meetingId];

  const participantsByEmailHash: {
    [id: string]: ParticipantWithSelections
  } = {};

  // Get all participants, even if they have no selections
  Object.values(meeting?.participants || {}).forEach(participant => {
    participantsByEmailHash[participant.email_hash] = {
      id: participant.id,
      emailHash: participant.email_hash,
      name: participant.name,
      email: participant.email,
      selectedSlots: [],
      first_response_date: participant.first_response_date,
      required: participant.required
    };
  });

  Object.values(meeting?.poll_selections || {}).forEach(selection => {
    const participants = meeting.participants || {};
    const participant = participants ? participants[selection.participant] : null;

    if (participant) {
      participantsByEmailHash[participant.email_hash].selectedSlots.push({
        id: selection.id,
        start: DateTime.fromISO(selection.start_date),
        end: DateTime.fromISO(selection.end_date),
        priority: selection.priority,
      });
    }
  });

  return Object.values(participantsByEmailHash);

});

export const selectLeaderCalendarMap = createSelector([
  (state: RootState) => state.schedule.calendars,
  (state: RootState) => state.leaders.leaders,
], (calendars, leaders) => {
  return new Map(leaders.map(leader => [leader, calendars.filter(calendar => calendar.leaders.includes(leader.id))]));
});

export const selectLeaderIdCalendarMap = createSelector([
  selectLeaderCalendarMap,
], (calendarMap): Record<number, Calendar[]> => {
  return Object.fromEntries(Array.from(calendarMap.entries()).map(([leader, calendars]) => [
    leader.id, calendars
  ]));
});

export const selectLeaderIdPrimaryCalendarMap = createSelector([
  selectLeaderCalendarMap,
], (calendarMap): Record<number, Calendar | undefined> => {
  return Object.fromEntries(Array.from(calendarMap.entries()).map(([leader, calendars]) => [
    leader.id, getPrimaryCalendar(leader, calendars)
  ]));
});
