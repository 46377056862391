import { ReactElement, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlotInfo } from "react-big-calendar";
import { SxProps } from "@mui/material";
import {
  actions, GlobalModalComponentName, Leader, MeetingSlot,
  RootState, ThunkDispatchType,
} from "../../../store";
import CalendarScheduler from "./CalendarScheduler";
import { PAGE_URL } from "../../../constants";
import { selectUserCalendarEvents } from "../../../store/schedule/selectors";
import { TimeZone } from "../../../utils/scheduleUtils";
import { router } from "../../../router";
import {
  calendarViewUpdated,
  condensePendingSlotsUpdated,
  selectActiveCalendars,
  selectAllHiddenCalendarIds,
  selectCalendarMap, selectSelectedAdditionalCalendars, selectSelectedLeaderCalendars,
  showAllDayEventsUpdated,
  showAllRecurringSlotsUpdated,
  showCanceledDeclinedMeetingsUpdated,
  showMultiLeaderColumnsUpdated,
  showPendingSlotsUpdated
} from "../../../store/scheduleUI";
import { DateTime } from "luxon";
import { useHandler } from "../../../store/hooks";


type Props =  {
  selectedLeaders: Leader['id'][];
  userHasGrant: boolean;
  openAdditionalCalendarsModal?: () => void;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  loadingCalendars: boolean;
  handleOpenMeeting?: (meetingId: number) => void;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleSlotsCreated?: (info: SlotInfo, isExcluded?: boolean) => void;
  handleEditSlot?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDateChange: (start: DateTime, end: DateTime) => void;
  calendarTimezoneSelected: TimeZone | undefined;
  handleCalendarTimezoneSelected?: (timezone: TimeZone) => void;
  secondaryTimezonesSelected: (TimeZone | undefined)[];
  handleSecondaryTimezoneSelected?: (idx: number, timezone: TimeZone) => void;
  handleDeleteSlots?: (slots: MeetingSlot[]) => void;
  currentMeetingId: number | undefined;
  handleDuplicateMeeting?: (meetingId: number) => void
  handleDeleteMeeting?: (meetingId: number) => void
  handleShareMeeting?: (meetingId: number) => void
  handleCopyLink?: (meetingId: number) => void
  onUpdateAdditionalCalendar?: (additionalCalendars: number[]) => void
  hideCalendarHeader?: boolean;
  sx?: SxProps;
};

const CalendarSchedulerContainer = ({
  selectedLeaders, userHasGrant,
  openAdditionalCalendarsModal, currentDateRangeInfo, loadingCalendars, handleOpenMeeting, selectedSlots,
  recurringSlots, handleSlotsCreated, handleEditSlot, onDateChange, calendarTimezoneSelected,
  handleCalendarTimezoneSelected, secondaryTimezonesSelected, handleSecondaryTimezoneSelected, handleDeleteSlots,
  currentMeetingId, handleDuplicateMeeting, handleDeleteMeeting, handleShareMeeting, handleCopyLink,
  onUpdateAdditionalCalendar, hideCalendarHeader, sx,
}: Props): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const handle = useHandler();
  const navigate = router.navigate;

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const newMeeting = useSelector((state: RootState) => state.schedule.newMeeting);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const calendarMap = useSelector(selectCalendarMap);
  const additionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const selectedLeaderCalendars = useSelector(selectSelectedLeaderCalendars);
  const activeCalendars = useSelector(selectActiveCalendars);
  const hiddenCalendarIds = useSelector(selectAllHiddenCalendarIds);
  const showAllRecurringSlots = useSelector((state: RootState) => state.scheduleUI.showAllRecurringSlots);
  const showCanceledDeclinedMeetings = useSelector((state: RootState) => state.scheduleUI.showCanceledDeclinedMeetings);
  const showPendingSlots = useSelector((state: RootState) => state.scheduleUI.showPendingSlots);
  const condensePendingSlots = useSelector((state: RootState) => state.scheduleUI.condensePendingSlots);
  const showAllDayEvents = useSelector((state: RootState) => state.scheduleUI.showAllDayEvents);
  const showMultiLeaderColumns = useSelector((state: RootState) => state.scheduleUI.showMultiLeaderColumns);
  const calendarView = useSelector((state: RootState) => state.scheduleUI.calendarView);

  const leaderHasAssociations = selectedLeaderCalendars.length > 0;

  const visibleDisplayCalendars = useMemo(() => (
    activeCalendars.filter(c => !hiddenCalendarIds.includes(c.id)).map(c => c.calendar_id)
  ), [activeCalendars, hiddenCalendarIds]);

  const consolidatedEvents = useSelector((state: RootState) => selectUserCalendarEvents(
    state,
    new Set(visibleDisplayCalendars),
    currentDateRangeInfo.start.toISO() || undefined,
    currentDateRangeInfo.end.toISO() || undefined,
    showCanceledDeclinedMeetings,
  ));

  const currentMeeting = currentMeetingId ? meetings[currentMeetingId] : null;
  const currentOrNewMeeting = currentMeeting || newMeeting;
  const showSecondaryTimezone = user?.features.MULTI_TIMEZONE && schedulingPrefs.user_prefs?.multi_timezone;
  const currentMeetingDurationMinutes = currentOrNewMeeting ? currentOrNewMeeting.duration_minutes : undefined;
  const meetingPreventDoubleBooking = currentOrNewMeeting?.prevent_conflict;

  const openUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const openAssociationModal = () => navigate(PAGE_URL.MANAGE_CALENDARS);
  const openAccountManagementModal = () => navigate(PAGE_URL.INTEGRATION_SETTINGS);
  const handleOpenPollResults = (handleOpenPollResultsId: number) => (
    navigate(`${PAGE_URL.POLL_RESULTS}/${handleOpenPollResultsId}/`)
  );

  const handleToggleMultiTimezone = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        multi_timezone: !schedulingPrefs.user_prefs?.multi_timezone,
      }));
    }
  };

  const handleToggleGranularTimeSelection = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        granular_time_selection: !schedulingPrefs.user_prefs?.granular_time_selection,
      }));
    }
  };

  const handleToggleShowAllRecurringSlots = () => {
    dispatch(showAllRecurringSlotsUpdated(!showAllRecurringSlots));
  };

  const handleToggleCanceledDeclinedMeetings = () => {
    dispatch(showCanceledDeclinedMeetingsUpdated(!showCanceledDeclinedMeetings));
  };

  const handleToggleShowPendingSlots = () => {
    dispatch(showPendingSlotsUpdated(!showPendingSlots));
  };

  const handleToggleCondensePendingSlots = () => {
    dispatch(condensePendingSlotsUpdated(!condensePendingSlots));
  };

  const handleToggleAllDayEvents = () => {
    dispatch(showAllDayEventsUpdated(!showAllDayEvents));
  };

  const handleToggleMultiLeaderColumns = () => {
    dispatch(showMultiLeaderColumnsUpdated(!showMultiLeaderColumns));
  };

  return (
    <CalendarScheduler
      selectedLeaders={selectedLeaders.map(lId => leaders.leaders.find(l => l.id === lId))
        .filter((leader): leader is Leader => !!leader)}
      calendars={calendars}
      meetings={meetings}
      calendarMap={calendarMap}
      additionalCalendars={additionalCalendars}
      newMeeting={newMeeting}
      coloredEvents={consolidatedEvents}
      showSecondaryTimezone={showSecondaryTimezone}
      currentMeetingDurationMinutes={currentMeetingDurationMinutes}
      meetingPreventDoubleBooking={meetingPreventDoubleBooking}
      hasMultiTimezone={user?.features.MULTI_TIMEZONE}
      hasMultiLeaderCalendarView={user?.features.MULTI_LEADER_CALENDAR_VIEW}
      onToggleMultiTimezone={handleToggleMultiTimezone}
      granularTimeSelection={schedulingPrefs.user_prefs?.granular_time_selection || false}
      onToggleGranularTimeSelection={handleToggleGranularTimeSelection}
      leaderHasAssociations={leaderHasAssociations}
      userHasGrant={userHasGrant}
      openAssociationModal={openAssociationModal}
      openAdditionalCalendarsModal={openAdditionalCalendarsModal}
      openAccountManagementModal={openAccountManagementModal}
      currentDateRangeInfo={currentDateRangeInfo}
      loadingCalendars={loadingCalendars}
      handleOpenMeeting={handleOpenMeeting}
      selectedSlots={selectedSlots}
      recurringSlots={recurringSlots}
      handleSlotsCreated={handleSlotsCreated}
      handleEditSlot={handleEditSlot}
      onDateChange={onDateChange}
      calendarTimezoneSelected={calendarTimezoneSelected}
      handleCalendarTimezoneSelected={handleCalendarTimezoneSelected}
      secondaryTimezonesSelected={secondaryTimezonesSelected}
      handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
      handleDeleteSlots={handleDeleteSlots}
      currentMeetingId={currentMeetingId}
      isPoll={currentOrNewMeeting?.is_poll}
      handleDuplicateMeeting={handleDuplicateMeeting}
      handleDeleteMeeting={handleDeleteMeeting}
      handleShareMeeting={handleShareMeeting}
      handleCopyLink={handleCopyLink}
      onOpenPollResults={handleOpenPollResults}
      showAllRecurringSlots={showAllRecurringSlots}
      onToggleShowAllRecurringSlots={handleToggleShowAllRecurringSlots}
      showCanceledDeclinedMeetings={showCanceledDeclinedMeetings}
      onToggleCanceledDeclinedMeetings={handleToggleCanceledDeclinedMeetings}
      showPendingSlots={showPendingSlots}
      onToggleShowPendingSlots={handleToggleShowPendingSlots}
      condensePendingSlots={condensePendingSlots}
      onToggleCondensePendingSlots={handleToggleCondensePendingSlots}
      showAllDayEvents={showAllDayEvents}
      onToggleAllDayEvents={handleToggleAllDayEvents}
      showMultiLeaderColumns={showMultiLeaderColumns}
      onToggleMultiLeaderColumns={handleToggleMultiLeaderColumns}
      openUpgradeModal={openUpgradeModal}
      onUpdateAdditionalCalendar={onUpdateAdditionalCalendar}
      hideHeader={true}
      hideCalendarHeader={hideCalendarHeader}
      noCalendarBorder
      sx={sx}
      calendarView={calendarView}
      onCalendarViewChange={handle(calendarViewUpdated)}
    />
  );
};

export default memo(CalendarSchedulerContainer);
