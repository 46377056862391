import { CustomFields } from '../store';
import { NOTE_FIELD_LABELS, ERROR_TYPE, ERROR_MESSAGE, emailRegex } from '../constants';

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
export type SetObjectStateField<T> = (value: T) => void;

export const generateUniqueKey = (): string => '_' + Math.random().toString(36).substr(2, 9);

export interface MutableCustomField {
  label: string; 
  value: string; 
  isUnique: boolean;
  uniqueKey: string;
}

export type MutableCustom = (MutableCustomField)[];

//mutable custom field lets users create their own card fields
//-label is the user entered title for the field
//-value is the user entered value
//-isUnique will be true if label is unique and there are no errors
//-uniqueKey is used as a key for react when mapping
export const getNewMutableCustomField = (): MutableCustomField => (
  {
    label: '', 
    value: '', 
    isUnique: true, 
    uniqueKey: generateUniqueKey(),
  });

export const customToMutableCustom = (custom: CustomFields): MutableCustom => {
  const mutableCustom: MutableCustom = [];
  Object.keys(custom).forEach((field): void => {
    if (custom[field]) {
      mutableCustom[custom[field].order] = {
        label: field, 
        value: custom[field].value, 
        isUnique: true,
        uniqueKey: generateUniqueKey()};
    }
  }); 
  return mutableCustom.filter((field) => field);
};

export const isUniqueCustomLabel = (mutableCustom: MutableCustom, newLabel: string): boolean => {
  return !mutableCustom.some((field): boolean => field.label === newLabel);
};

export const mutableCustomToCustom = (mutableCustom: MutableCustom): [CustomFields, string[]] => {
  const custom: CustomFields = {};
  const errors: string[] = [];

  mutableCustom.forEach((field, index): void => {
    //if key already exists its a duplicate - so dont save changes
    if (field.label in custom) {
      if (!errors.includes(field.label)) {
        errors.push(field.label);
      }
    }
    //we only want to send the field, value, and order to the backend if label is not empty
    if (field.label) {
      custom[field.label] = {value: field.value, order: index};
    }
  });
  return [custom, errors];
};

export interface FieldErrors {
  [fieldKey: string]: {[K in keyof typeof ERROR_TYPE]: {message: string; valid: boolean; fieldLabel: string}};
}

export const addFieldError = (
  fieldErrors: FieldErrors, fieldKey: keyof typeof NOTE_FIELD_LABELS, errorType: keyof typeof ERROR_TYPE
): FieldErrors => {
  const error = fieldErrors[fieldKey] || {[errorType]: {message: '', valid: false}};
  error[errorType].message = '- ' + NOTE_FIELD_LABELS[fieldKey] + ' is ' + ERROR_MESSAGE[errorType] + '\n';
  error[errorType].valid = false;
  return {...fieldErrors, [fieldKey]: error};
};

export const removeFieldError = (
  fieldErrors: FieldErrors, fieldKey: string, errorType: keyof typeof ERROR_TYPE
): FieldErrors => {
  const newErrors = {...fieldErrors};
  if (fieldErrors[fieldKey]) {
    newErrors[fieldKey][errorType].valid = true;
  }
  return newErrors;
};

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export type ParsedNameAndEmail = {
  name: string | null;
  email: string;
};

export const parseEmailAddresses = (input: string): ParsedNameAndEmail[] => {
  const entries: ParsedNameAndEmail[] = [];

  // Normalize input: remove extra spaces, unify line breaks, and trim whitespace
  const normalizedInput = input
    .replace(/\r\n|\r/g, '\n') // Replace carriage returns with line breaks
    .replace(/\s+\n/g, '\n') // Remove extra spaces before line breaks
    .trim();

  // Split input into lines or entries separated by commas or semicolons
  const lines = normalizedInput.split(/[\n,;]+/);

  // Regular expressions for parsing
  const emailWithNameRegex = /^([^<]+?)\s*<([\w.%+-]+@[\w.-]+\.[A-Za-z]{2,})>$/;
  const emailOnlyRegex = /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/;
  const csvLineRegex = /^([^<]+?)\s*<([\w.%+-]+@[\w.-]+\.[A-Za-z]{2,})>,?/;

  for (const line of lines) {
    const trimmedLine = line.trim();

    // Check for email with name (e.g., "Evan Kesten <evan@joincabinet.com>")
    let match = trimmedLine.match(emailWithNameRegex);
    if (match) {
      const name = match[1].trim();
      const email = match[2].toLowerCase();
      entries.push({ name, email });
      continue;
    }

    // Check for standalone email address
    if (emailOnlyRegex.test(trimmedLine)) {
      const email = trimmedLine.toLowerCase();
      entries.push({ name: null, email });
      continue;
    }

    // Handle CSV-like rows
    match = trimmedLine.match(csvLineRegex);
    if (match) {
      const name = match[1].trim();
      const email = match[2].toLowerCase();
      entries.push({ name, email });
      continue;
    }

    // Handle tab-separated Outlook tracking info or similar
    const parts = trimmedLine.split(/\t+/);
    if (parts.length > 0) {
      const emailMatch = parts[0].match(emailWithNameRegex);
      if (emailMatch) {
        const name = emailMatch[1].trim();
        const email = emailMatch[2].toLowerCase();
        entries.push({ name, email });
        continue;
      }
    }
  }

  return entries;
};