import { TemplateVars } from "@CabComponents/CabTextTokenInput";
import { Box, Divider, FormControl, Grid } from "@mui/material";
import { uniqBy } from "lodash-es";
import { ChangeEvent, ReactElement, ReactNode, useMemo } from "react";
import { CabAutocomplete, CabDropdown, CabIcon, CabTextInput, CabTextTokenInput } from "..";
import colors from "../../../colors";
import { PROVIDER, PROVIDER_CONFERENCE_TYPES } from "../../../constants";
import { Calendar, LeaderList, Meeting, MeetingRoom, PresetLocations, User, ZoomSettings } from "../../../store";
import { getConferenceProvidersForLeadersParameterized } from "../../../store/schedule/selectors";
import { cabCaptureMessage } from "../../../utils/logging";
import { isMobile } from "../../../utils/screenSizeUtils";
import { IoPersonOutline } from "react-icons/io5";
import { GenericAutocompleteProps } from "@CabComponents/CabAutocomplete";


const StaticLocationOptions = {
  TEXT_LOCATION_OPTION: { value: '-1', label: 'Address/Phone/Other' },
  ADD_ZOOM_OPTION: { value: '-2', label: '-- Add Zoom --' },
  NO_LOCATION_OPTION: { value: '-3', label: 'No Location' },
  CHOOSE_NEW_LOCATION_OPTION: { value: '-4', label: 'Select a new location' },
  CONFERENCE_ROOM_OPTION: { value: '-5', label: 'Conference Room' },
};

const LocationOptionWrapper = ({ children }: { children: ReactNode | ReactNode[] | string }) => (
  <Box display="flex" flexWrap="nowrap" alignItems="center" width="100%">
    {children}
  </Box>
);

interface ConferenceRoomOptionMeta { capacity?: number }

export interface CabMeetingLocationDropdownProps {
  value: {
    conference?: { provider: number | null; leader: number | null },
    locationText?: string;
    room?: number;
    presetLocation?: number;
    none?: boolean;
  };
  onChange: (val: {
    conference?: { provider: number; leader: number | null },
    locationText?: string;
    room?: number;
    presetLocation?: number;
    none?: boolean;
  }) => void;
  onBlurOtherField: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  disabled?: boolean;
  disableConferenceOptionsTooltip?: string;
  showTokenInputs?: boolean;
  meeting?: Partial<Meeting> & { id: number };
  meetingBookingCalendar?: Calendar;
  user: User;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  leaders: LeaderList;
  isOwner: boolean;
  presetLocations: PresetLocations;
  meetingRooms: { [id: string]: MeetingRoom };
  onAddZoom: () => void;
  onAddQuestion?: () => void;
  templateVars: TemplateVars;
  disabledLocationPresetIds: number[];
  disabledMeetingRoomIds: number[];
  hideOtherOption?: boolean;
  showCalendarDefaultOption?: boolean;
  secondaryInputOnNewLine?: boolean;
  defaultZoomAccountLabel?: string;
  addUsernameToZoomLabel?: boolean;
  hasMicrosoftGrant?: boolean;
  hasGoogleGrant?: boolean;
}

const CabMeetingLocationDropdown = ({
  value, onChange, disabled, showTokenInputs, meeting, meetingBookingCalendar, user, zoomSettings, leaders, isOwner,
  presetLocations, meetingRooms, onAddZoom, onAddQuestion, templateVars, onBlurOtherField,
  disableConferenceOptionsTooltip, disabledLocationPresetIds, disabledMeetingRoomIds, hideOtherOption,
  showCalendarDefaultOption, secondaryInputOnNewLine, defaultZoomAccountLabel, addUsernameToZoomLabel,
  hasMicrosoftGrant, hasGoogleGrant
}: CabMeetingLocationDropdownProps) => {

  const [conferenceOptions, hasZoom] = useMemo(() => {
    let availableConferenceProviders: { conferenceProviderId: number; leaderId: number | null; label: string }[] = [];
    if (meetingBookingCalendar && PROVIDER_CONFERENCE_TYPES[meetingBookingCalendar.provider]) {
      // availableConferenceProviders = PROVIDER_CONFERENCE_TYPES[calendar.provider].map(c => ({
      //   conferenceProviderId: c.id, leaderId: null, label: c.label,
      // }));
      const allProviderSolutions = PROVIDER_CONFERENCE_TYPES[meetingBookingCalendar.provider];
      const allowedProviderSolutions = meetingBookingCalendar.conferenceSolutions;
      allowedProviderSolutions.forEach(allowedSolutionKey => {
        const foundSolution = allProviderSolutions.find(
          providerSolution => providerSolution.key === allowedSolutionKey
        );
        if (foundSolution) {
          availableConferenceProviders.push({
            conferenceProviderId: foundSolution.id,
            leaderId: null,
            label: foundSolution.label
          });
        } else {
          // This shouldn't happen, but can if we have a conference solution we haven't seen before.
          // We will log it and try to show something reasonable to the user
          cabCaptureMessage(
            `Found unknown conference solution "${allowedSolutionKey}" with provider ID
            ${meetingBookingCalendar.provider}`
          );
          const unknownSolutionLabel = allowedSolutionKey ? allowedSolutionKey
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase()) : 'Unknown';

          availableConferenceProviders.push({
            conferenceProviderId: meetingBookingCalendar.provider,
            leaderId: null,
            label: unknownSolutionLabel
          });
        }
      });

    }

    // add anything else that is not Google or MS
    const others = getConferenceProvidersForLeadersParameterized(
      user, zoomSettings, leaders, meeting?.leaders || [], defaultZoomAccountLabel, addUsernameToZoomLabel
    ).filter(
      provider => ![PROVIDER.GOOGLE.id, PROVIDER.MICROSOFT.id].includes(provider.conferenceProviderId)
    );

    availableConferenceProviders = [...availableConferenceProviders, ...others];
    const hasZoomOptions = availableConferenceProviders.some(p => p.conferenceProviderId === PROVIDER.ZOOM.id);
    const availableOptions = availableConferenceProviders.map((provider) => {
      return {
        value: {
          provider: provider.conferenceProviderId,
          leader: provider.leaderId,
        },
        label: provider.label,
      };
    });

    // add meeting's current conference as an option ONLY if you don't own it. Otherwise duplicates may appear.
    if (!isOwner && meeting?.conference_provider) {
      let providerInfo: { id: number; label: string } | undefined;
      Object.values(PROVIDER_CONFERENCE_TYPES).forEach(providerOptions => (
        providerOptions.forEach(conferenceProvider => {
          if (conferenceProvider.id === meeting.conference_provider) {
            providerInfo = conferenceProvider;
          }
        })
      ));
      if (providerInfo) {
        const confLeaderId = meeting.conference_leader || null;
        let labelAdditional = '';
        if (confLeaderId && providerInfo.id === PROVIDER.ZOOM.id) {
          const confLeader = meeting.leader_info?.find(l => l.id === confLeaderId);
          if (confLeader) {
            let leaderFullName = confLeader ? confLeader.first_name : '';
            if (confLeader?.last_name) leaderFullName += ` ${confLeader.last_name[0]}`;
            labelAdditional = ` - ${leaderFullName}`;
          }
          const confLeaderZoomSettings = Object.values(zoomSettings).find(stg => stg.leader === confLeaderId);
          const zoomLabel = confLeaderZoomSettings?.schedule_obo?.username
            ? ` (${confLeaderZoomSettings.schedule_obo.username})`
            : confLeaderZoomSettings?.oauth_token?.username
              ? ` (${confLeaderZoomSettings?.oauth_token?.username})`
              : '';
          labelAdditional += zoomLabel;
        }

        availableOptions.push({
          value: {
            provider: providerInfo.id,
            leader: meeting.conference_leader || null,
          },
          label: `${providerInfo.label}${labelAdditional}`,
        });
      }
    }

    return [uniqBy(availableOptions, option => JSON.stringify(option.value)), hasZoomOptions];
  }, [leaders, meetingBookingCalendar, meeting?.conference_provider, meeting?.conference_leader, meeting?.leader_info,
    meeting?.leaders, user, zoomSettings, isOwner, addUsernameToZoomLabel, defaultZoomAccountLabel]);

  const isMeetingConferenceValid = !meeting ? true : (!!conferenceOptions.find(
    ({ value: v }) => v.provider === meeting.conference_provider
  ) || meeting.conference_provider === null);

  let locationOptions: ({
    value: string; label: string | ReactElement; disabled?: boolean; tooltipText?: string; isConference?: boolean
  } | { groupHeader: string })[] = useMemo(() => {

    let conf = conferenceOptions.map(conference => ({
      value: JSON.stringify({ conference: conference.value }),
      label: (
        <LocationOptionWrapper>
          {conference.label}
        </LocationOptionWrapper>
      ),
      // only allow one virtual conference location
      disabled: !!disableConferenceOptionsTooltip,
      isConference: true,
      tooltipText: disableConferenceOptionsTooltip,
    })).concat(hasZoom ? [] : [{
      value: StaticLocationOptions.ADD_ZOOM_OPTION.value,
      label: (
        <LocationOptionWrapper>
          {StaticLocationOptions.ADD_ZOOM_OPTION.label}
        </LocationOptionWrapper>
      ),
      disabled: isMobile(),
      isConference: false,
      tooltipText: '',
    }]);

    if (!isMeetingConferenceValid) {
      conf = [{
        value: StaticLocationOptions.CHOOSE_NEW_LOCATION_OPTION.value,
        label: (
          <LocationOptionWrapper>
            {StaticLocationOptions.CHOOSE_NEW_LOCATION_OPTION.label}
          </LocationOptionWrapper>
        ),
        disabled: false,
        isConference: false,
        tooltipText: '',
      }, ...conf];
    }

    if (showCalendarDefaultOption) {
      conf = [{
        value: JSON.stringify({ conference: { provider: null, leader: null } }),
        label: (
          <LocationOptionWrapper>
            {
              hasMicrosoftGrant && hasGoogleGrant ? 'Calendar Default (Google Meet/MS Teams)' 
                : hasMicrosoftGrant ? 'Microsoft Teams'
                  : hasGoogleGrant ? 'Google Meet'
                    : 'Calendar Default'
            }
          </LocationOptionWrapper>
        ),
        disabled: false,
        isConference: true,
        tooltipText: disableConferenceOptionsTooltip,
      }, ...conf];
    }

    const presets = Object.values(presetLocations).map(presetLoc => ({
      value: JSON.stringify({ presetLocation: presetLoc.id }),
      label: (
        <LocationOptionWrapper>
          {presetLoc.name}
        </LocationOptionWrapper>
      ),
      disabled: disabledLocationPresetIds.includes(presetLoc.id),
    }));

    const hasConferenceRooms = Object.values(meetingRooms).some(
      room => room.provider === meetingBookingCalendar?.provider
    );

    return [
      ...(conf.length ? [{ groupHeader: 'Conference Integrations' }] : []),
      ...conf,
      ...(presets.length ? [{ groupHeader: 'Custom Presets' }] : []),
      ...presets,
      { groupHeader: 'Other' },
      ...(hasConferenceRooms ? [{
        value: StaticLocationOptions.CONFERENCE_ROOM_OPTION.value,
        label: <LocationOptionWrapper>{StaticLocationOptions.CONFERENCE_ROOM_OPTION.label}</LocationOptionWrapper>,
        disabled: false,
      }] : []),
      ...(hideOtherOption ? [] : [{
        value: StaticLocationOptions.TEXT_LOCATION_OPTION.value,
        label: <LocationOptionWrapper>{StaticLocationOptions.TEXT_LOCATION_OPTION.label}</LocationOptionWrapper>,
        disabled: false,
      }]),
      {
        value: StaticLocationOptions.NO_LOCATION_OPTION.value,
        label: <LocationOptionWrapper>{StaticLocationOptions.NO_LOCATION_OPTION.label}</LocationOptionWrapper>,
        disabled: false,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(disabledLocationPresetIds), disableConferenceOptionsTooltip,
    conferenceOptions, meetingRooms, presetLocations,
    meetingBookingCalendar?.provider, hasZoom, isMeetingConferenceValid
  ]);

  const roomOptions: GenericAutocompleteProps<number, ConferenceRoomOptionMeta>['options'] = useMemo(() => (
    Object.values(meetingRooms)
      .filter(
        room => room.provider === meetingBookingCalendar?.provider
      ).sort((a, b) => a.display_name.localeCompare(b.display_name)).map((room) => ({
        value: room.id,
        label: room.display_name,
        icon: (
          <Box display="flex" flexDirection="row" gap={0.5}>
            <CabIcon sx={{ marginLeft: 2, fontSize: "14px", marginTop: '3px' }} Icon={IoPersonOutline} />
            {room.capacity}
          </Box>
        ),
        disabled: disabledMeetingRoomIds.includes(room.id),
        meta: {capacity: room.capacity}
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [meetingBookingCalendar?.provider, JSON.stringify(disabledMeetingRoomIds), meetingRooms]);

  locationOptions = value?.conference
    ? (locationOptions.map(lo => 'isConference' in lo
      ? { ...lo, disabled: false, tooltipText: '' }
      : lo))
    : locationOptions;

  return (
    <Grid container spacing={2}>
      <Grid item xs={(value?.locationText != null || value?.room != null) && !secondaryInputOnNewLine ? 6 : 12}>
        <FormControl sx={{ width: "100%" }}>
          <CabDropdown<string | number>
            size="small"
            value={
              (() => {
                if (value?.locationText != null) {
                  return StaticLocationOptions.TEXT_LOCATION_OPTION.value;
                } else if (value?.room != null) {
                  return StaticLocationOptions.CONFERENCE_ROOM_OPTION.value;
                } else if (value?.none != null) {
                  return StaticLocationOptions.NO_LOCATION_OPTION.value;
                } else if (value) {
                  if (value.conference && !isMeetingConferenceValid) {
                    return StaticLocationOptions.CHOOSE_NEW_LOCATION_OPTION.value;
                  }
                  return JSON.stringify(value);
                }

                return StaticLocationOptions.NO_LOCATION_OPTION.value;
              })()
            }
            onChange={e => {
              const val = e.target.value && JSON.parse(e.target.value as string);
              if (val === Number(StaticLocationOptions.TEXT_LOCATION_OPTION.value)) {
                onChange({ locationText: '' });
              } else if (val === Number(StaticLocationOptions.CONFERENCE_ROOM_OPTION.value)) {
                onChange({ room: -1 });
              } else if (val === Number(StaticLocationOptions.NO_LOCATION_OPTION.value)) {
                onChange({ none: true });
              } else if (val === Number(StaticLocationOptions.CHOOSE_NEW_LOCATION_OPTION.value)) {
                onChange({ none: true });
              } else {
                onChange(val);
              }
            }}
            options={locationOptions}
            hideOptionTooltipsWhenClosed
            disabled={disabled}
            onChangeOverrides={{
              [StaticLocationOptions.ADD_ZOOM_OPTION.value]: {
                onChange: onAddZoom,
                preventDefault: true
              }
            }}
            // placeholder="Select an Option"
            sx={{ width: '100%' }}
          />
        </FormControl>
      </Grid>

      {/* address/phone/other text field */}
      {value?.locationText != null && (
        <>
          <Grid item xs={secondaryInputOnNewLine ? 12 : 6}>
            {showTokenInputs ? (
              <CabTextTokenInput
                value={value.locationText || ''}
                callOnBlur
                onChange={(e: ChangeEvent<HTMLInputElement>) => (
                  onChange({ locationText: e.target.value })
                )}
                onAddQuestion={onAddQuestion}
                onBlur={onBlurOtherField}
                templateVars={templateVars}
                placeholder="e.g. 123 Main St, Anytown, NY"
                formControlSx={{ width: "100%", marginTop: 0 }}
                tokenColor={colors.black400}
                disabled={disabled}
              />
            ) : (
              <CabTextInput
                value={value.locationText || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => (
                  onChange({ locationText: e.target.value })
                )}
                onBlur={onBlurOtherField}
                placeholder={'e.g. 123 Main St, Anytown, NY'}
                formControlSx={{ width: "100%", marginTop: 0 }}
                disabled={disabled}
              />
            )}
            {secondaryInputOnNewLine && <Divider sx={{ marginTop: 2 }} />}
          </Grid>
        </>
      )}

      {/* conference room field */}
      {value?.room != null && (
        <Grid item xs={secondaryInputOnNewLine ? 12 : 6}>
          <CabAutocomplete<number, ConferenceRoomOptionMeta>
            // Setting value only when there are options prevents a bad state in the autocomplete
            value={value.room}
            onChange={(val) => {
              onChange({ room: val as number });
            }}
            placeholder="Select a room"
            noOptionsText="No matching rooms found"
            sx={{ width: '100%' }}
            options={roomOptions}
            disabled={disabled}
            optionIconPlacement="end"
            // TODO: This code is meant to show the capacity of the room after it's selected, but it looks
            //    like all of our custom attriubutes get wiped after 1 render cycle at this point.
            // getOptionLabel={o => {
            //   const option = o as GenericAutocompleteOption<number, ConferenceRoomOptionMeta>;
            //   const capacity = option.meta?.capacity ? ` (${option.meta.capacity})` : "";
            //   return `${option.label}${capacity}`;
            // }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CabMeetingLocationDropdown;
