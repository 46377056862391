import { setDayEnd } from './datesUtils';
import { getTimeZones, TimeZone as TzdbTimeZone } from '@vvo/tzdb';
import { Calendar, DateRange, DisplayCalendarsDict, 
  Meeting, Meetings, MeetingSlot, IEventCache, User, Leader, APICalendarEvent, 
  RBCEvent, RecurringTimes, RecurringTimeFormData, AbbreviatedDays, 
  RecurringTimesOptions, ExcludedSingleDateFormData, InvalidParticipantVotes, MeetingQuestion, BookingSlot, 
  CombinedMeetingSlot,
  RemoteCalendar,
  SchedulingPreferences,
  EventHoldTitle,
  PrivateNormalizedExternalParticipants,
  MeetingLeader,
  MeetingLeaderInfo,
  MeetingQuestionAnswerSubmission,
} from '../store';
import ct from 'countries-and-timezones'; 
import { DateTime, Settings } from 'luxon';
import { PAGE_URL, PROVIDER, PROVIDER_BY_ID } from '../constants';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { getMeetingSlotTemplate } from '../store/templates';
import colors from '../colors';
import { SlotInfo } from 'react-big-calendar';
import { datetime, RRule } from 'rrule';
import { checkForProviderGrant } from './authUtils';
import chroma from 'chroma-js';
import { QuestionType, RecurringTimeOptionLimit } from "../store/schedule/types";


// Luxon's `.toFormat()` function defaults to en-US locale. This makes it default to the
//       user's actual locale.
Settings.defaultLocale = DateTime.now().locale;

export type ExcludedSlotInfo = Pick<SlotInfo, 'start' | 'end'>;

export const currentMeetingFilter = (slot: MeetingSlot, currentMeetingId?: number): boolean => 
  slot.meeting === currentMeetingId || slot.meeting < 0; 

export const isWithinRangeFilter = (slot: MeetingSlot, startDate: Date, endDate: Date): boolean => {
  return DateTime.fromISO(slot.end_date).toMillis() >= DateTime.fromJSDate(startDate).toMillis() 
    || DateTime.fromISO(slot.start_date).toMillis() <= DateTime.fromJSDate(endDate).toMillis();
};

export const mergeBookingSlots = (bookingSlots: BookingSlot[], meetingDuration: number): BookingSlot[] => {
  const tolerance = meetingDuration > 30 ? 10 : 5;
  const newBookingSlots: BookingSlot[] = [];
  if (bookingSlots.length > 0) {
    newBookingSlots.push({...bookingSlots[0]});
    for (let i = 1; i < bookingSlots.length; i++) {
      const top = newBookingSlots[newBookingSlots.length - 1];
      if (DateTime.fromISO(top.end).plus({minute: tolerance}) < DateTime.fromISO(bookingSlots[i].start)) {
        newBookingSlots.push({...bookingSlots[i]});
      } else if (DateTime.fromISO(top.end).plus({minute: tolerance}) >= DateTime.fromISO(bookingSlots[i].start)) {
        top.end = bookingSlots[i].end;
        newBookingSlots.pop();
        newBookingSlots.push(top);
      }
    }
  }
  return newBookingSlots;
};

export const getFormattedSlots = (selectedSlots: BookingSlot[], 
  currentTimezone?: TimeZone, additionalTimezones?: string[] | null): string => {
  const timezoneName = currentTimezone?.name || '';
  let message = '';
  const slotsByDay: {[date: string]: BookingSlot[]}  = {};

  selectedSlots
    .sort((slotA, slotB) => slotA.start.localeCompare(slotB.start))
    .forEach(slot => {
      const date = DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('ccc, MMM dd');
      
      if (slotsByDay[date]) {
        slotsByDay[date].push(slot);
      } else {
        slotsByDay[date] = [slot];
      }
    });

  message = Object.keys(slotsByDay).map((date) => {
    const slotsString = slotsByDay[date].map( (slot, index) => {
      let daySlotString = `• ${DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('h:mm a')
        .toLowerCase()}`;
      if (!additionalTimezones || additionalTimezones.length < 2) {
        daySlotString += 
          ` - ${DateTime.fromISO(slot.end).setZone(timezoneName).toFormat('h:mm a').toLowerCase()}`;
      }
      daySlotString += ` ${currentTimezone?.abbreviation || ''}`;

      if (additionalTimezones) {
        daySlotString += getAdditionalTimezoneText(additionalTimezones, slot, timezoneName);
      }
      return daySlotString;
    }, '').join('\n');
    return message + date + ': \n' + slotsString + '\n';
  }).join('\n');

  return message;
};

const getRecurringOfferedTimes = (recurringTimes: RecurringTimes, timezoneAbbr: string): string => {
  const recurringTimeData = convertRecurringTimesToRecurringTimesFormData(recurringTimes);
  const timesByDay: {[times: string]: (keyof RecurringTimeFormData)[]}  = {};
  let message = '';

  (Object.keys(recurringTimeData) as Array<keyof (RecurringTimeFormData)>).forEach((day) => {
    if (recurringTimeData[day].enabled) {
      if (timesByDay[JSON.stringify(recurringTimeData[day].times)]) {
        timesByDay[JSON.stringify(recurringTimeData[day].times)].push(day);
      } else {
        timesByDay[JSON.stringify(recurringTimeData[day].times)] = [day];
      }
    }
  });

  if (recurringTimes.options.limit.selected === RecurringTimeOptionLimit.DATE_RANGE) {
    message += `From ${DateTime.fromISO(recurringTimes.options.limit.date_range.start).toFormat('MM/dd/yyyy')}`
    + ` to ${DateTime.fromISO(recurringTimes.options.limit.date_range.end).toFormat('MM/dd/yyyy')}: \n`;
  } else if (recurringTimes.options.limit.selected === RecurringTimeOptionLimit.NUM_DAYS) {
    message += `For the next ${recurringTimes.options.limit.num_days.toString()} days: \n`;
  } else {
    message += 'Every: \n';
  }

  Object.values(timesByDay).forEach((days, i) => {
    if (i > 0) {
      message += '\n';
    }
    const capitalizedDays = days.map(day => {
      return day.toString().charAt(0).toUpperCase() + day.toString().slice(1);
    });
    message += capitalizedDays.join(', ');
    recurringTimeData[days[0]].times.forEach(slot => {
      message += `\n• ${DateTime.fromISO(slot.startTime).toFormat('h:mma').toLowerCase()} - `
      + `${DateTime.fromISO(slot.endTime).toFormat('h:mma').toLowerCase()} ${timezoneAbbr}`;
    });
  });


  return message;
};

export const getTimesOfferedText = (selectedSlots: MeetingSlot[], excludedSlots: MeetingSlot[], 
  recurringTimes: RecurringTimes | null, currentTimezone?: TimeZone): string => {
  const timezoneAbr = currentTimezone?.abbreviation || '';
  let message = '';
  const includedSlotsString = getFormattedMeetingTimes(selectedSlots, currentTimezone);
  const excludedSlotsString = getFormattedMeetingTimes(excludedSlots, currentTimezone);

  message = includedSlotsString;

  if (recurringTimes) {
    if (includedSlotsString !== '') {
      message += '\n';
    }
    message += getRecurringOfferedTimes(recurringTimes, timezoneAbr);
    if (excludedSlotsString !== '') {
      message += '\n';
    }
  }

  if (excludedSlots.length > 0) {
    message += '\n Exclusions: \n' + excludedSlotsString;
  }

  return message;
};

const getFormattedMeetingTimes = (selectedSlots: MeetingSlot[], currentTimezone?: TimeZone): string => {
  const timezoneName = currentTimezone?.name || '';
  let message = '';
  const slotsByDay: {[date: string]: MeetingSlot[]}  = {};

  selectedSlots
    .sort((slotA, slotB) => slotA.start_date.localeCompare(slotB.start_date))
    .forEach(slot => {
      const date = DateTime.fromISO(slot.start_date).setZone(timezoneName).toFormat('ccc, MMM dd');
      
      if (slotsByDay[date]) {
        slotsByDay[date].push(slot);
      } else {
        slotsByDay[date] = [slot];
      }
    });

  message = Object.keys(slotsByDay).map((date) => {
    const slotsString = slotsByDay[date].map( slot => {
      let daySlotString = `• ${DateTime.fromISO(slot.start_date).setZone(timezoneName).toFormat('h:mm a')
        .toLowerCase()}`;
      daySlotString += 
        ` - ${DateTime.fromISO(slot.end_date).setZone(timezoneName).toFormat('h:mm a').toLowerCase()}`;
      daySlotString += ` ${currentTimezone?.abbreviation || ''}`;

      return daySlotString;
    }, '').join('\n');
    return message + date + ': \n' + slotsString + '\n';
  }).join('\n');

  return message;
};

export const getFormattedHyperlinkSlots = (selectedSlots: BookingSlot[], baseLink: string, 
  currentTimezone?: TimeZone, currentMeetingId?: number, additionalTimezones?: string[] | null): string => {
  const timezoneName = currentTimezone?.name || '';
  let message = '';
  const slotsByDay: {[date: string]: BookingSlot[]}  = {};

  selectedSlots
    .sort((slotA, slotB) => slotA.start.localeCompare(slotB.start))
    .forEach(slot => {
      const date = DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('ccc, MMM dd');
      
      if (slotsByDay[date]) {
        slotsByDay[date].push(slot);
      } else {
        slotsByDay[date] = [slot];
      }
    });

  message = Object.keys(slotsByDay).map((date) => {
    const slotsString = slotsByDay[date].map( (slot, index) => {
      const start = slot.start;
      const end = slot.end;
      let daySlotString = `<div>• <a href=${baseLink}?start=${start}&end=${end} target='_blank'>
       ${DateTime.fromISO(slot.start).setZone(timezoneName).toFormat('h:mm a').toLowerCase()}`; 
      if (!additionalTimezones || additionalTimezones.length < 2) {
        daySlotString += 
          ` - ${DateTime.fromISO(slot.end).setZone(timezoneName).toFormat('h:mm a').toLowerCase()}`;
      }
      daySlotString += ` ${currentTimezone?.abbreviation || ''}`;

      if (additionalTimezones) {
        daySlotString += getAdditionalTimezoneText(additionalTimezones, slot, timezoneName);
      }
      return daySlotString + '</a></div>';
    }, '').join('\n');
    return '<div style="font: inherit">' + message + date + ': \n' + slotsString + '\n </div>';
  }).join('<br>');

  return message;
};

const getAdditionalTimezoneText = (
  additionalTimezones: string[], slot: BookingSlot, primaryTimezone: string
): string => {
  let additionalTimezonesString = '';
  for (const timezoneString of additionalTimezones) {
    const timezone = allTimeZones.find((zone: TimeZone) => zone.name === timezoneString);
    const tz1DayEnd = DateTime.fromISO(slot.end).setZone(primaryTimezone).toFormat('MM-dd-yyyy');
    const tz2DayEnd = DateTime.fromISO(slot.end).setZone(timezoneString).toFormat('MM-dd-yyyy');
    let dayDiffEnd = '';
    if (tz1DayEnd > tz2DayEnd) {
      dayDiffEnd = ' (-1 day)';
    } else if (tz2DayEnd > tz1DayEnd) {
      dayDiffEnd = ' (+1 day)';
    }
    const tz1DayStart = DateTime.fromISO(slot.start).setZone(primaryTimezone).toFormat('MM-dd-yyyy');
    const tz2DayStart = DateTime.fromISO(slot.start).setZone(timezoneString).toFormat('MM-dd-yyyy');
    let dayDiffStart = '';
    if (tz1DayStart > tz2DayStart) {
      dayDiffStart = ' (-1 day)';
    } else if (tz2DayStart > tz1DayStart) {
      dayDiffStart = ' (+1 day)';
    }
    additionalTimezonesString += ` // ${DateTime.fromISO(slot.start).setZone(timezoneString)
      .toFormat('h:mm a').toLowerCase()}${dayDiffStart}`;
    if (!additionalTimezones || additionalTimezones.length < 2) {
      additionalTimezonesString += 
        ` - ${DateTime.fromISO(slot.end).setZone(timezoneString).toFormat('h:mm a')
          .toLowerCase()}${dayDiffEnd}`;
    }
    additionalTimezonesString += ` ${timezone?.abbreviation || ''}`;
  }
  return additionalTimezonesString;
};

/*
  startDateStr: start date of new slot selected
  endDateStr: end date of the new slot selected
  selectedSlots: current valid slots selected for availability
  currentMeetingId: id of the current meeting object (if the current meeting has been saved)
*/
export const handleCollisions = (
  startDateStr: string, endDateStr: string, currentMeetingSlots: MeetingSlot[],
): { startDate: string; endDate: string; deleteSlots: MeetingSlot[] } => {
  const orderedSlots = currentMeetingSlots.sort((slotA, slotB) => {
    return DateTime.fromISO(slotA.start_date).toMillis() - DateTime.fromISO(slotB.end_date).toMillis();
  });

  const dayClicked = DateTime.fromISO(startDateStr).day; 
  const startMillis =  DateTime.fromISO(startDateStr).toMillis();
  const endMillis = DateTime.fromISO(endDateStr).toMillis();
  let newStartDateStr = startDateStr;
  let newEndDateStr = endDateStr;

  let deleteSlots: MeetingSlot[] = [];
  let newStartSlot: undefined | MeetingSlot;
  let newEndSlot: undefined | MeetingSlot;

  if (orderedSlots.length > 0) {
    orderedSlots.forEach(currentSlot => {
      const currentSlotStart = DateTime.fromISO(currentSlot.start_date);
      if (currentSlotStart.day === dayClicked) {
        const currentSlotEnd = DateTime.fromISO(currentSlot.end_date);
        const currentSlotStartMillis = currentSlotStart.toMillis();
        const currentSlotEndMillis = currentSlotEnd.toMillis();
        const newStartDate = DateTime.fromISO(newStartDateStr).toMillis();
        const newEndDate = DateTime.fromISO(newEndDateStr).toMillis();

        //Check if the slot clicked is within the ranges of any other meeting slot event. 
        if (startMillis <= currentSlotStartMillis && endMillis >= currentSlotEndMillis) {
          deleteSlots.push(currentSlot);
        } else if (startMillis >= currentSlotStartMillis && endMillis <= currentSlotEndMillis) {
          deleteSlots.push(currentSlot);
          newStartDateStr = currentSlot.start_date;
          newEndDateStr = currentSlot.end_date;
        } else {
          //If the end of the slot is before the  date clicked then it's potentially the start of the new slot 
          //to be created after the double click. Will select the latest possible date. 
          if (currentSlotEndMillis < endMillis && currentSlotEndMillis > newStartDate) {
            newStartSlot = currentSlot;
            deleteSlots.push(currentSlot);
            newStartDateStr = getMinDate(currentSlot.start_date || '', newStartDateStr || '');
            newEndDateStr = getMaxDate(currentSlot.end_date || '', newEndDateStr || '');
          } else if (currentSlotEndMillis === startMillis) {
          // If you're not expanding to all available space but there's a meeting slot right before the selected slot
            newStartSlot = currentSlot;
            newStartDateStr = currentSlot.end_date;
          }
          //If the start of the slot is after the  date clicked then it's potentially the end of the new slot 
          //to be created after the double click. Since the array is ordered, should always be the first. 
          if (currentSlotStartMillis > startMillis && currentSlotStartMillis < newEndDate) {
            newEndSlot = currentSlot;
            deleteSlots.push(currentSlot);
            newStartDateStr = getMinDate(currentSlot?.start_date || '', newStartDateStr || '');
            newEndDateStr = getMaxDate(currentSlot?.end_date || '', newEndDateStr || '');
          } else if (currentSlotStartMillis === endMillis) {
            //If you're not expanding to all available space and there's a meeting slot right after the selected slot
            newEndSlot = currentSlot;
            newEndDateStr = currentSlot.start_date;
          }
        }
      }
    });
  }

  //If the meeting before is the same as the meeting for which you are selecting then merge with the one before 
  if (newStartSlot) {
    newStartDateStr = newStartSlot.start_date;
    deleteSlots.push(newStartSlot);
  }

  //If the meeting after is the same as the meeting for which you are selecting then merge with the one after 
  if (newEndSlot) {
    newEndDateStr = newEndSlot.end_date;
    deleteSlots.push(newEndSlot);
  }

  deleteSlots = uniqBy(deleteSlots, (slot) => slot.id);
  
  const result = { startDate: newStartDateStr, endDate: newEndDateStr, deleteSlots };
  return result;
};

type SlotState = {createdSlots: MeetingSlot[], deletedSlots: MeetingSlot[], updatedSlots: MeetingSlot[]};

export const getSlotCreateResult = (
  info: (SlotInfo | ExcludedSlotInfo) & { id?: number }, slotId: number, meeting: Partial<Meeting>,
  selectedSlots: MeetingSlot[], isExcluded: boolean
): SlotState => {
  const mtg = meeting;
  const filteredSlots = selectedSlots.filter((slot) => slot.is_exclude === isExcluded);
  // This is to prevent all-day slots from being selected.
  // Currently, slots going to midnight end at 11:59 PM, but if we change that
  //   to go to 12:00AM, it's possible we might stop "full days" from being selected
  if (info.end.getTime() - info.start.getTime() >= 24 * 36e5
    || !mtg) {
    return { createdSlots: [], deletedSlots: [], updatedSlots: [] };
  }

  let newSlotsToSave: MeetingSlot[] = [];
  let newSlotsToDelete: MeetingSlot[] = [];
  if (info.start !== info.end) {
    const autoMergeSlots = mtg.auto_merge_slots != null && !isExcluded ? mtg.auto_merge_slots : true;
    const durationMinutes = isExcluded ? 0 : mtg.duration_minutes ?? 0;

    const originalSlot = { ...info };
    const remainderSlots: MeetingSlot[] = [];
    const duration = DateTime.fromJSDate(originalSlot.end)
      .diff(DateTime.fromJSDate(originalSlot.start), 'minutes')
      .toObject().minutes;

    if (!autoMergeSlots) {
      // don't allow duplicates
      if (filteredSlots.find(s => s.start_date === DateTime.fromJSDate(originalSlot.start).toString())) {
        return { createdSlots: [], deletedSlots: [], updatedSlots: [] };
      }
      if (duration !== durationMinutes) {
        const originalSlotEnd = originalSlot.end;
        let remainder = DateTime.fromJSDate(originalSlotEnd)
          .diff(DateTime.fromJSDate(originalSlot.start), 'minutes')
          .toObject().minutes || 0;
        if (remainder > durationMinutes) {
          let start = originalSlot.start;
          // Keep adding meeting slots while the remainder is greater than the meeting duration
          while (remainder >= durationMinutes) {
            const end = DateTime.fromJSDate(start)
              .plus({ minutes: durationMinutes }).toJSDate();
            const remainderSlot: MeetingSlot = {
              // to guarantee a unique id when slots get broken out further
              id: -Math.floor(start.getTime() / 1000),
              meeting: mtg.id || -1,
              start_date: DateTime.fromJSDate(start).toString(),
              end_date: DateTime.fromJSDate(end).toString(),
              allDay: false,
              title: mtg.title || '',
              is_exclude: false,
            };
            if (mtg.slot_time_interval) {
              const endDateTime = DateTime.fromJSDate(end);
              let newStart = DateTime.fromJSDate(start);
              while (newStart < endDateTime) {
                newStart = newStart.plus({minutes: mtg.slot_time_interval});
              }
              start = newStart.toJSDate();
            } else {
              start = end;
            }
            
            remainder = (DateTime.fromJSDate(originalSlotEnd))
              .diff(DateTime.fromJSDate(start), 'minutes')
              .toObject().minutes || 0;
            remainderSlots.push(remainderSlot);
          }
        } else {
          originalSlot.end = DateTime.fromJSDate(originalSlot.start).plus({ minutes: durationMinutes }).toJSDate();
          const slotStart = DateTime.fromJSDate(originalSlot.start);
          const slotEnd = DateTime.fromJSDate(originalSlot.end);
          remainderSlots.push(buildSlot(slotId, slotStart, slotEnd, mtg.id, mtg.title || '', false, isExcluded));
        }
      } 
    // if this was a click or very tiny drag it will default to 15
    // In this case we want to make sure we set it to the meeting duration at minimum
    // UNLESS it occurs right at the end of another slot
    } else if (duration != null && duration <= 15 && durationMinutes) {
      let newDuration = Math.ceil(durationMinutes / 15) * 15;

      // find if this occurs right at the end of another slot. If so, we only want to extend to the meeting duration
      // or if it is already at the duration, extend by 15
      const slotToExtend = filteredSlots.find(s => (
        DateTime.fromISO(s.end_date).toMillis() === DateTime.fromJSDate(originalSlot.start).toMillis()
      ));

      if (slotToExtend) {
        const slotToExtendDuration = DateTime.fromISO(slotToExtend.end_date)
          .diff(DateTime.fromISO(slotToExtend.start_date), 'minutes')
          .minutes;
        const durationDiff = durationMinutes - slotToExtendDuration;
        newDuration = durationDiff > 0 ? durationDiff : 15;
      }

      originalSlot.end = DateTime.fromJSDate(originalSlot.start)
        .plus({ minutes: newDuration }).toJSDate();
    }

    if (!remainderSlots.length) {
      const slotStart = DateTime.fromJSDate(originalSlot.start);
      const slotEnd = DateTime.fromJSDate(originalSlot.end);
      remainderSlots.push(buildSlot(slotId, slotStart, slotEnd, mtg.id, mtg.title || '', false, isExcluded));
    }

    newSlotsToSave = remainderSlots.map(slot => {
      if (autoMergeSlots) {
        const { startDate, endDate, deleteSlots } = handleCollisions(
          slot.start_date, slot.end_date, filteredSlots,
        );

        if (deleteSlots.length > 0) {
          newSlotsToDelete = [...newSlotsToDelete, ...deleteSlots];
          return { ...slot, start_date: startDate, end_date: endDate };
        }
      }

      return slot;
    });

    newSlotsToDelete = uniqBy(newSlotsToDelete, (slot) => slot.id);

    // dedupe by start and end
    const checkAgainstSlots = filteredSlots.filter(s => !newSlotsToDelete.find(sd => sd.id === s.id));
    newSlotsToSave = newSlotsToSave.filter(s => (
      !checkAgainstSlots.find(ss =>
        +DateTime.fromISO(ss.start_date) === +DateTime.fromISO(s.start_date)
        && +DateTime.fromISO(ss.end_date) === +DateTime.fromISO(s.end_date)
      )
    ));
  }

  return {
    createdSlots: newSlotsToSave,
    deletedSlots: newSlotsToDelete,
    updatedSlots: []
  };
};

export const getSlotEditResult = (
  eventId: string, start: Date, end: Date, selectedSlots: MeetingSlot[], isExcluded: boolean, 
  currentOrNewMeeting?: Partial<Meeting>,
): SlotState => {

  const filteredSlots = selectedSlots.filter((slot) => slot.is_exclude === isExcluded);
  const autoMergeSlots = isExcluded || !!currentOrNewMeeting?.auto_merge_slots;
  let updatedSlots: MeetingSlot[] = [];
  let deletedSlots: MeetingSlot[] = [];
  let startDate: string | undefined;
  let endDate: string | undefined;

  const startStr = DateTime.fromJSDate(start).toISO() || "";
  const endStr = autoMergeSlots
    ? DateTime.fromJSDate(end).toISO() || ""
    : DateTime.fromJSDate(start).plus({ minutes: currentOrNewMeeting?.duration_minutes || 30 }).toISO() || "";

  const otherSlots = filteredSlots.filter(slot => slot.id.toString() !== eventId);

  if (!autoMergeSlots) {
    if (otherSlots.find(s => s.start_date === startStr)) {
      const slot = filteredSlots.find(s => s.id.toString() === eventId);
      return {
        createdSlots: [],
        deletedSlots: slot ? [slot] : [],
        updatedSlots: [],
      };
    }
  } else {
    ({
      startDate,
      endDate,
      deleteSlots: deletedSlots 
    } = handleCollisions(startStr, endStr, otherSlots));
  }

  //If the event id is less than 0 it means it hasn't been saved to the backend 
  //Therefore edit the event in the filteredSlots state array. 
  if (Number(eventId) < 0) {
    const nextEvents = filteredSlots
      .filter(existingEvent => existingEvent.id.toString() === eventId)
      .map(existingEvent => ({
        ...existingEvent,
        start_date: startDate || startStr,
        end_date: endDate || endStr,
        is_exclude: isExcluded,
      }));

    updatedSlots = updatedSlots.concat(nextEvents);
  } else {
    //If the event has already been saved to the backend then edit the record in the backend.
    const updateMeetingSlot: MeetingSlot = {
      id: Number(eventId),
      start_date: startDate || startStr,
      end_date: endDate || endStr,
      meeting: currentOrNewMeeting?.id || -1,
      is_exclude: isExcluded
    };

    updatedSlots.push(updateMeetingSlot);
  }

  return { deletedSlots, updatedSlots, createdSlots: [] };
};

export const getSlotsDeleteResult = (slots: MeetingSlot[]): SlotState => {
  const deletedSlots: MeetingSlot[] = [];
  const updatedSlots: MeetingSlot[] = [];
  slots.forEach(slot => {
    deletedSlots.push(slot);
  });

  return {deletedSlots, updatedSlots, createdSlots: []};
};

export const removeTimesFromSlot = (
  originalTimeSlot: { start: DateTime, end: DateTime },
  timeSlotsToRemove: { start: DateTime, end: DateTime }[],
  timeSlotsToKeep: { start: DateTime, end: DateTime }[] = [],
): { start: DateTime, end: DateTime }[] => {
  const result: { start: DateTime; end: DateTime }[] = [];

  let currentSlot: { start: DateTime; end: DateTime } = { ...originalTimeSlot };

  // Check each time slot in the second parameter
  for (const timeSlotToRemove of timeSlotsToRemove) {
    // if outside the current slot, skip it
    if (timeSlotToRemove.start >= currentSlot.end || timeSlotToRemove.end <= currentSlot.start) {
      continue;
    }

    // If the time slot to remove starts after the current slot, create a new slot from the end
    // of the current slot to the start of the removing slot
    if (timeSlotToRemove.start > currentSlot.start) {
      result.push({
        start: currentSlot.start,
        end: timeSlotToRemove.start
      });
    }

    // If time slot to remove ends before the current slot, update the current slot to start after the removing slot
    if (timeSlotToRemove.end < currentSlot.end) {
      currentSlot = {
        start: timeSlotToRemove.end,
        end: currentSlot.end
      };
    // If the removing slot encompasses the entire current slot, update the current slot to an empty slot
    } else {
      currentSlot = {
        start: currentSlot.end,
        end: currentSlot.end
      };
    }
  }

  // If the current slot is not empty, add it to the result
  if (currentSlot.start < currentSlot.end) {
    result.push(currentSlot);
  }

  // elongate slots in result with any overlapping slots in slotsToKeep
  const newResult = [...result];
  result.forEach((slot, i) => {
    for (const slotToKeep of timeSlotsToKeep) {
      if (slotToKeep.start < slot.start && slotToKeep.end > slot.start) {
        newResult[i].start = slotToKeep.start;
      }
      if (slotToKeep.start < slot.end && slotToKeep.end > slot.end) {
        newResult[i].end = slotToKeep.end;
      }
    }
  });

  return newResult;
};

export const getMaxDate = (dateString1: string, dateString2: string): string => {
  const date1 = DateTime.fromISO(dateString1).toMillis();
  const date2 = DateTime.fromISO(dateString2).toMillis();
  if (date1 > date2) {
    return dateString1;
  } else {
    return dateString2;
  }
};

export const getMinDate = (dateString1: string, dateString2: string): string => {
  const date1 = DateTime.fromISO(dateString1).toMillis();
  const date2 = DateTime.fromISO(dateString2).toMillis();
  if (date1 < date2) {
    return dateString1;
  } else {
    return dateString2;
  }
};

export const leaderHasCalendars = 
  (calendars: Calendar[], selectedLeaders: number[]) : boolean => {
    const hasAssociations =  calendars.find(calendarAssociation => {
      // start as true, breifly not showing connect message is better than showing it when we should not
      let result = true;
      let foundAssociation = false;
      selectedLeaders.forEach(selectedLeader => {
        if (calendarAssociation.leaders.includes(selectedLeader)) {
          foundAssociation = true;
        }
        result = foundAssociation || !!calendarAssociation.leaders.includes(selectedLeader);
      });
      return result;
    });
    return Boolean(hasAssociations);
  };

export const getCalendarsDisplayed = (calendars: Calendar[], 
  selectedLeaders: number[], selectedAdditionalCalendars: number[],
  currentDisplayCalendars: DisplayCalendarsDict, leaders: Leader[],
) : DisplayCalendarsDict => {

  return calendars.map(cal => {
    
    const isSelectedLeaderCal = cal.leaders.some(leaderId => {
      if (!selectedLeaders.includes(leaderId)) return false;
      const leader = leaders.find(l => l.id === leaderId);
      return !!leader;
    });
    const isSelectedAdditionalCal = (cal && selectedAdditionalCalendars.includes(cal.id));
    const displayInfo = currentDisplayCalendars[cal.calendar_id];
    // if calendar from your executive (you own) then always filter by available
    return {
      isSelectedLeaderCal,
      isSelectedAdditionalCal,
      // If there's no information for this calendar it means it's the first time it's being displayed. 
      // So add true to display and determine the other values from calendar information in available calendars.
      display: displayInfo ? displayInfo.display : true,
      color: cal?.backgroundColor || displayInfo?.color || colors.black400,
      name: cal?.summary || 'Unknown Calendar',
      provider: cal?.provider || cal.provider,
      canEdit: cal?.canEdit || false,
      id: cal?.id,
      calendarAccessId: cal.calendar_access_id || undefined,
      calendarId: cal?.calendar_id,
      leaders: cal.leaders,
      conferenceSolutions: cal?.conferenceSolutions || [],
      readable: cal?.readable || false,
      additionalCalendarEmail: cal?.additionalCalendarEmail,
      freeBusy: cal?.freeBusy
    };
  })
    .filter(assoc => (assoc.isSelectedLeaderCal || assoc.isSelectedAdditionalCal) && assoc.provider)
    .sort(assoc => assoc.isSelectedLeaderCal ? -1 : 1)
    .map(assoc => ({ [assoc.calendarId]: assoc }))
    .reduce((a, b) => ({ ...a, ...b }), {});
};

export const getAvailableCalendars = (
  calendars: Calendar[], user: User | undefined | null, leaders: Leader[]
): Calendar[] => {
  return calendars.filter(association => {
    // if calendar is from a shared leader or user is logged in for this calendar's provider
    return association.leaders
      .map(lId => leaders.find(leader => leader.id === lId))
      .some(leader => leader?.is_shared)
      || !!(user && checkForProviderGrant(user.oauth_grant_details, PROVIDER_BY_ID[association.provider].name));
  });
};

export type TimeZone = TzdbTimeZone & {allAbbreviations: string[]};

// This is expensive so we just do it once. The goal is to correct abbreviations
//    for DST, and to make all abbreviations available for searching
export const allTimeZones: TimeZone[] = getTimeZones().map((zone) => {
  let abbreviation = DateTime.local({ zone: zone.name }).toFormat("ZZZZ");
  // TZDB abbreviations are all valid for searching because they are all the standard-time
  //    versions of the timezones.
  const allAbbreviations = [zone.abbreviation];
  if (abbreviation.includes("GMT")) {
    // Only override the primary abbreviation if it's not just a GMT offset
    abbreviation = zone.abbreviation;
  } else {
    // If the Intl abbreviation is not just a GMT offset, make it searchable.
    allAbbreviations.push(abbreviation);
  }
  return {...zone, abbreviation, allAbbreviations};
});

export const getTimeZone = (timezoneAbbr: string, name?: string): TimeZone | undefined => {
  //If searching by name then build the timezone object 
  //If the timezone object is an alias use the alias of property 
  //Otherwise use the name since the timezone might be the main timezone name 
  //If not searching by name get the first timezone that matches the abbreviation.
  const buildTz = name ? ct.getTimezone(name) : null;
  const findName = name && buildTz ? buildTz.aliasOf || buildTz.name : '';

  let timezone = allTimeZones.find(tz => (findName && tz.name === findName));
  if (!timezone) {
    // If the timezone found doesn't match a name that's available in the timezone database (e.g. America/New_York), 
    //   fall back to a timezone that at least matches the current abbreviation (e.g. EST).
    // NOTE: We are explicitly not checking allAbbreviations because the current abbreviation is the only one
    //   that should be checked to avoid finding the wrong timezone (there is a lot of overlap in abbreviations)
    timezone = allTimeZones.find(tz => (timezoneAbbr && tz.abbreviation === timezoneAbbr));
  }

  // If there is no timezone found, we're probably working with a fixed-offset timezone like Etc/GMT+6
  //   in which case we don't really have any usable data so just pick a timezone with a matching offset
  //   (including accounting for DST) and hope for the best.
  if (!timezone) {
    const offsetMinutes = buildTz?.dstOffset;
    timezone = allTimeZones.find(tz => tz.currentTimeOffsetInMinutes === offsetMinutes);
  }

  return timezone;
};

export const NY_TZ = allTimeZones.find(tz => tz.name === 'America/New_York') as TimeZone;

export const getLocalTimeZone = (): TimeZone | undefined => {
  const timezoneLong = DateTime.local().zoneName;
  const timezoneAbbr = DateTime.local().toFormat('ZZZZ');
  return getTimeZone(timezoneAbbr, timezoneLong);
};

export const getUTCTimeZone = (): TimeZone | undefined => {
  const timezone = getTimeZones({ includeUtc: true }).find(z => z.name === "UTC");
  if (!timezone) {
    return;
  }
  return {...timezone, allAbbreviations: ["UTC"]};
};

export const getLocalTimeZoneName = (): string => {
  const localTimeZone = getLocalTimeZone();
  // Fall back to the default zoneName if we can't find a true timezone to use
  return localTimeZone?.name || DateTime.local().zoneName;
};

export const getSelectedTimeZoneText = (timezone: TimeZone | undefined): string => {
  return timezone
    ? getTimeZone(timezone.abbreviation, timezone.name)?.name.replace('_', ' ') || ''
    : '';
};

export const formatTimezoneOption = (timezone: TimeZone): {short: string, medium: string, long: string} => {
  const offsetHours = timezone.currentTimeOffsetInMinutes / 60;
  const { abbreviation, name  } = timezone;
  const mediumFormat = `${abbreviation} - ${name}`;
  const longFormat = `(GMT${offsetHours >= 0 ? '+' : ''}${offsetHours}) - ${mediumFormat}`;
  return {short: abbreviation, medium: mediumFormat, long: longFormat};
};

export const formatTimezoneText = (timezone: TimeZone): string => {
  const offsetHours = timezone.currentTimeOffsetInMinutes / 60;
  const { abbreviation } = timezone;
  return `${abbreviation} - (GMT${offsetHours >= 0 ? '+' : ''}${offsetHours})`;
};

export const getTimezoneFromMeeting = (meetingTz: string | null) => {
  const timezoneLongCalendar = meetingTz || getLocalTimeZoneName();
  const timezoneCalendarAbbr = DateTime.local({ zone: timezoneLongCalendar }).toFormat('ZZZZ'); 
  const timezoneCalendar = getTimeZone(timezoneCalendarAbbr, meetingTz || undefined);
  return timezoneCalendar;
};

export const filterMeetings = (
  meetingId: string, meetings: Meetings, selectedLeader: number | null, showScheduled: boolean, 
  startDate?: DateRange, scheduledDate?: DateRange, 
): boolean => {
  const meeting = meetings[meetingId];

  // Don't show polls for now
  if (meeting.is_poll) return false;

  const meetingDate = DateTime.fromISO(meeting.create_date).toMillis();

  const isGreaterThanStartDate = startDate?.start ? meetingDate >= DateTime.fromISO(startDate.start).toMillis() : true;
  const isLessThanStartDate = startDate?.end ? meetingDate <= DateTime.fromISO(startDate.end).toMillis() : true;

  const startDateFilter = startDate
    ? isGreaterThanStartDate && isLessThanStartDate 
    : true;

  const dateSheduled = meeting.date_scheduled;

  const isGreaterThanScheduledDate = scheduledDate?.start && dateSheduled 
    ? DateTime.fromISO(dateSheduled).toMillis() >= DateTime.fromISO(scheduledDate.start).toMillis()
    : true;
  const isLessThanScheduledDate = scheduledDate?.end  && dateSheduled 
    ? DateTime.fromISO(dateSheduled).toMillis() <=  DateTime.fromISO(scheduledDate.end).toMillis()
    : true;

  const scheduledDateFilter = scheduledDate
    ? isGreaterThanScheduledDate && isLessThanScheduledDate
    : true;

  const selectedLeaderFilter = selectedLeader
    ? meeting.leaders.includes(selectedLeader) 
    : true;

  const scheduledMeetingFilter = scheduledStatusMeetingFilter(showScheduled)(meeting); 

  return Boolean(startDateFilter && scheduledDateFilter && selectedLeaderFilter && scheduledMeetingFilter);
};

export const sortMeetingLogs = (meetings: Meetings) => (a: string, b: string): number => {
  const meetingA = meetings[a]; 
  const meetingB = meetings[b]; 
  return sortMeetings(meetingA, meetingB);
};

export const sortMeetings = (meetingA: Meeting, meetingB: Meeting): number => {
  if (!meetingA.date_scheduled && !meetingB.date_scheduled) {
    //The meeting with the latest create date at the top
    return  DateTime.fromISO(meetingB.create_date).toMillis() - DateTime.fromISO(meetingA.create_date).toMillis();
  } else if (!meetingA.date_scheduled) {
    return -1;
  } else if (!meetingB.date_scheduled) {
    return 1;
  } else {
    const bDateScheduled = Number(DateTime.fromISO(meetingB.date_scheduled).toMillis());
    const aDateScheduled = Number(DateTime.fromISO(meetingA.date_scheduled).toMillis());
    return bDateScheduled - aDateScheduled;
  }
};


export const scheduledStatusMeetingFilter = (showScheduled: boolean) => (meeting: Meeting): boolean => {
  return showScheduled ? true : meeting.date_scheduled === null;
};

export class EventCache {
  private constructor() { /* ... */}
  private static _instance: EventCache;
  static getInstance(): EventCache {
    return this._instance || (this._instance = new this());
  }
  // static instance: EventCache | null = EventCache.instance === null ? new EventCache() :  EventCache.instance;
  cache: IEventCache = {}; 

  get(calendarId: string, startDate: string, endDate: string): {
    events: APICalendarEvent[]; cacheTimestamp: DateTime } | null {
    if (calendarId in this.cache
        && startDate in this.cache[calendarId]
        && endDate in this.cache[calendarId][startDate]) {
      return this.cache[calendarId][startDate][endDate];
    } else {
      return null;
    }
  }

  add(calendarId: string, startDate: string, endDate: string, 
    newEvents: APICalendarEvent[]): void {
    if (!(calendarId in this.cache)) {
      this.cache[calendarId] = {};
    }
    if (!(startDate in this.cache[calendarId])) {
      this.cache[calendarId][startDate] = {};
    }
    if (!(endDate in this.cache[calendarId][startDate])) {
      this.cache[calendarId][startDate][endDate] = {
        events: [],
        cacheTimestamp: DateTime.now(),
      };
    }
    this.cache[calendarId][startDate][endDate].events.push(...newEvents);
  }

  clear(): void {
    this.cache = {};
  }
}

export const formatToWeek = (date: DateTime, zone?: string): DateTime => {
  const formatted = DateTime.fromISO(date?.toString() || '').toFormat('dd/MM/yyyy');
  let shiftWeek = DateTime.fromFormat(formatted, 'dd/MM/yyyy');
  if ( shiftWeek.weekday === 7 ) {
    //Make sure the day is inside the next week if its a Sunday
    shiftWeek = shiftWeek.plus({days: 2});
  }

  //Set date to midweek to avoid risk of week bing selected resulting in previous week for start and end dates. 
  const setToMidWeek = shiftWeek.set({weekday: 3}); 
  return cloneDeep(setToMidWeek);
};

// Get stard and end of current week based on a single date within that week (and optional timezone)
export const getWeekRange = (date: DateTime, timezone?: string): {start: DateTime, end: DateTime} => {
  const dateClone = formatToWeek(date, timezone);
  const start = dateClone.startOf('week', {useLocaleWeeks: true}).startOf('day');
  const end = dateClone.endOf('week', {useLocaleWeeks: true}).endOf('day');
  return {start, end};
}; 

export const isSameCalendarDate = (date1: DateTime, date2: DateTime): boolean => {
  return date1.hasSame(date2, 'year') && date1.hasSame(date2, 'month') && date1.hasSame(date2, 'day');
};

export const getPickerDay = (dateStr: string): DateTime => {
  return DateTime.fromFormat(dateStr.split('T')[0], 'yyyy-MM-dd'); 
};

export const splitRange = (startDate: Date, endDate: Date, minutes: number): Array<{start: Date, end: Date}> => {
  let start = DateTime.fromJSDate(startDate);
  const endLimit = DateTime.fromJSDate(endDate);
  let end;
  const slots = [];
  while (start <= endLimit.minus({minutes})) {
    end = start.plus({minutes});
    slots.push({start: start.toJSDate(), end: end.toJSDate()}); 
    start = end;
  } 
  return slots;
};

export const colorDisplayMeetingSlots = (meetingSlots: MeetingSlot[], selectedLeaders: number[], 
  meetings: Meetings, currentMeetingId: number | undefined, patternClass: string, 
  prefs: SchedulingPreferences | undefined, isPotentiallyAvailable = false, isFaded = false): MeetingSlot[] => {
  // Deduping is required here because we are combining selectedSlots and meetingSlots when calling this function,
  //   we might be combining selectedSlots and meetingSlots which leads to overlaps
  const coloredSlots = uniqBy(meetingSlots, (slot) => slot.id)
    .filter(slot => {
      if (slot.meeting < 0) return true;
      if (currentMeetingId && slot.meeting === currentMeetingId) return true;

      return meetings[slot.meeting]?.date_scheduled === null
        && selectedLeaders.some(leaderId => meetings[slot.meeting]?.leaders.includes(leaderId));
    })
    .map(meetingSlot => {
      const showInternalLabel = prefs && meetings[meetingSlot.meeting]?.is_reusable && 
        meetings[meetingSlot.meeting]?.executive_hold && prefs.event_title_for_holds === EventHoldTitle.INTERNAL_LABEL;
      const isCurrentMeeting = meetingSlot.meeting === currentMeetingId || meetingSlot.meeting < 0;
      const isEditable = isCurrentMeeting &&
        (
          (!currentMeetingId || currentMeetingId < 0)
          || (!!currentMeetingId && currentMeetingId > 0 && meetingSlot.id > 0) // not an unsaved slot on saved meeting
        );

      return {
        ...meetingSlot, 
        title: isCurrentMeeting
          ? 
          showInternalLabel ? meetings[meetingSlot.meeting]?.internal_label || '' 
            : meetings[meetingSlot.meeting]?.title || ''
          : showInternalLabel ? `Pending: ${meetings[meetingSlot.meeting]?.internal_label || ''}` 
            : `Pending: ${meetings[meetingSlot.meeting]?.title || ''}`,
        editable: isEditable,
        textColor: isCurrentMeeting && !isPotentiallyAvailable
          ? colors.ink
          : colors.calmGrey800,
        backgroundColor: isCurrentMeeting
          ? isPotentiallyAvailable ? colors.white900 : chroma.hex(colors.lavendar500).alpha(0.5).hex()
          : colors.calmGrey300,
        className: isCurrentMeeting ? undefined : patternClass,
        borderColor: isPotentiallyAvailable ? colors.lavendar600 : undefined,
      };
    });
  return coloredSlots;
};

export const clearSelectedSlots = (selectedSlots: MeetingSlot[]): MeetingSlot[] => {
  return selectedSlots.filter(slot => Number(slot.id) > 0);
};

// Make sure to decrement minSlotId each time to account for the id being taken
export const buildSlot = (slotId: number, currentDay: DateTime, endDay: DateTime, 
  currentMeetingId: number | undefined, meetingName: string, allDay: boolean, isExclude: boolean): MeetingSlot => ({
  ...getMeetingSlotTemplate(),
  id: slotId,
  start_date: cloneDeep(currentDay).toISO() || "",
  end_date: allDay ? setDayEnd(cloneDeep(endDay)).toISO() || "" :  endDay.toISO() || "",
  meeting: currentMeetingId || -1,
  editable: currentMeetingId == null,
  title: meetingName,
  backgroundColor: colors.lavendar600,
  textColor: colors.white900,
  is_exclude: isExclude
});

export const getMinSlotId = (selectedSlots: MeetingSlot[]): number => {
  let minSlotId = selectedSlots.length === 0 ? -1 : selectedSlots.reduce((prev, curr) =>
    prev.id < curr.id ? prev : curr
  ).id;

  minSlotId = Math.min(-1, minSlotId);
  return minSlotId;
};

export const getNormalizedLeaders = (leaders: Leader[]): {[leaderId: number]: Leader} => {
  const normalizedLeaders: {[leaderId: number]: Leader} = {};
  leaders.forEach(leader => {
    normalizedLeaders[leader.id] = leader;
  });
  return normalizedLeaders;
};

export const humanReadableDuration = (durationMinutes: number): string => {
  const hours = Math.floor(durationMinutes / 60);
  const hoursText = hours === 1 ? 'Hour' : 'Hours';
  const minutes = durationMinutes % 60;
  const minutesText = minutes === 1 ? 'Minute' : 'Minutes';
  let durationText = '';
  if (hours > 0) durationText += `${hours} ${hoursText}`;
  if (minutes > 0) durationText += ` ${minutes} ${minutesText}`;

  return durationText;
};

export const getSlotDuration = (slot: MeetingSlot | CombinedMeetingSlot): number => {
  const start = DateTime.fromISO(slot.start_date);
  const end = DateTime.fromISO(slot.end_date);
  const duration = end.diff(start, 'minutes').toObject();
  if (duration.minutes != null) {
    return duration.minutes;
  }
  return 0;
};

export const getEventDuration = (event: RBCEvent): number => {
  if (!event.start || !event.end) {
    return 0;
  }
  const dateDiff = event.end.getTime() - event.start.getTime();
  return dateDiff / (1000 * 60);
};

export const getBookingLinkUrl = (meeting: Meeting): string => {
  const bookingPath = meeting.is_poll ? PAGE_URL.GROUP_SCHEDULING_PARTICIPANT : PAGE_URL.BOOK_MEETING;
  let origin = window.location.origin;
  if (import.meta.env.STORYBOOK_ORIGIN) {
    origin = import.meta.env.STORYBOOK_ORIGIN;
  } else if (window.IS_MOBILE) {
    origin = "https://app.joincabinet.com";
  }
  
  return `${origin}${bookingPath}/${meeting.external_id}`;
};

const getInvalidParticipantVotesUpdateOrDelete = (
  meeting: Meeting, updateMeetingSlot: MeetingSlot,
  existingSlots: MeetingSlot[], removedParticipantsTemp: InvalidParticipantVotes
) => {
  const originalMeetingSlot = existingSlots.find(slot => slot.id === updateMeetingSlot.id);
  if (originalMeetingSlot) {
    Object.values(meeting.poll_selections || {}).filter(
      (poll_selection) => {
        const response_start = DateTime.fromISO(poll_selection.start_date);
        const response_end = DateTime.fromISO(poll_selection.end_date);
        const slot_start = DateTime.fromISO(updateMeetingSlot.start_date);
        const slot_end = DateTime.fromISO(updateMeetingSlot.end_date);
          
        const originalSlotStart = DateTime.fromISO(originalMeetingSlot.start_date);
        const originalSlotEnd = DateTime.fromISO(originalMeetingSlot.end_date);

        return !(
          originalSlotStart.toMillis() > response_start.toMillis() ||
          originalSlotEnd.toMillis() < response_end.toMillis()
        ) && (
          slot_start.toMillis() > response_start.toMillis() ||
          slot_end.toMillis() < response_end.toMillis()
        );
      }).forEach((poll_selection) => {
      if (poll_selection.participant && removedParticipantsTemp) {
        const participantId = poll_selection.participant;
        if (removedParticipantsTemp[participantId.toString()]) {
          if (
            removedParticipantsTemp[participantId.toString()].invalidVotes
          ) {
            removedParticipantsTemp[participantId.toString()].invalidVotes?.push(poll_selection);
          } else {
            removedParticipantsTemp[participantId.toString()].invalidVotes = [poll_selection];
          }
        } else {
          if (meeting?.participants?.[participantId]) {
            removedParticipantsTemp[participantId.toString()] = meeting?.participants[participantId];
            removedParticipantsTemp[participantId.toString()].invalidVotes = [poll_selection];
          }
        }
      }
    });
  }
  return removedParticipantsTemp;
};

const mergeInvalidParticipantVotes = (
  removedParticipantsTemp: InvalidParticipantVotes, invalidVotes: InvalidParticipantVotes
): InvalidParticipantVotes => {
  Object.entries(invalidVotes).forEach(([participantId, participant]) => {
    if (removedParticipantsTemp[participantId]) {
      const newInvalidVotes = [
        ...(participant.invalidVotes || []),
        ...(removedParticipantsTemp[participantId].invalidVotes || [])
      ];

      removedParticipantsTemp[participantId].invalidVotes = uniqBy(newInvalidVotes, "id");
    } else {
      removedParticipantsTemp[participantId] = participant;
    }
  });
  return removedParticipantsTemp;
};

const cleanParticipantsTemp = (
  invalidMeetingSlots: InvalidParticipantVotes, referenceSlots: MeetingSlot[]
): InvalidParticipantVotes => {
  Object.entries(invalidMeetingSlots).forEach(([partipantId, participant]) => {
    if (referenceSlots.length > 0) {
      const invalidVotes = (participant.invalidVotes || []).filter(vote => {
        const responseStartDate = DateTime.fromISO(vote.start_date);
        const responseEndDate = DateTime.fromISO(vote.end_date);
        return !referenceSlots.find((slot) => {
          const slotStateDate = DateTime.fromISO(slot.start_date);
          const slotEndDate = DateTime.fromISO(slot.end_date);
          return responseStartDate >= slotStateDate && responseEndDate <= slotEndDate;
        });
      });
      if (invalidVotes.length > 0) {
        invalidMeetingSlots[partipantId].invalidVotes = invalidVotes;
      } else {
        delete invalidMeetingSlots[partipantId];
      }
    }
  });
  return invalidMeetingSlots;  
};

export const getInvalidParticipantVotes = (
  meeting: Meeting,
  createdSlots: MeetingSlot[],
  deletedSlots: MeetingSlot[],
  updatedSlots: MeetingSlot[],
  existingSlots: MeetingSlot[]
): InvalidParticipantVotes => {

  let removedParticipantsTemp: InvalidParticipantVotes = {};
  deletedSlots.map(slot => ({...slot, end_date:slot.start_date})).forEach(slot => {
    const invalidVotes = getInvalidParticipantVotesUpdateOrDelete(
      meeting, slot, existingSlots, removedParticipantsTemp
    );
    removedParticipantsTemp = mergeInvalidParticipantVotes(removedParticipantsTemp, invalidVotes);
  });
  
  removedParticipantsTemp = cleanParticipantsTemp(removedParticipantsTemp, updatedSlots);
  updatedSlots.forEach(slot => {
    const invalidVotes = getInvalidParticipantVotesUpdateOrDelete(
      meeting, slot, existingSlots, removedParticipantsTemp
    );
    
    removedParticipantsTemp = mergeInvalidParticipantVotes(removedParticipantsTemp, invalidVotes);
  });
  return cleanParticipantsTemp(removedParticipantsTemp, createdSlots);
};

export const getNumResponses = (participants: { first_response_date: string | null }[]): number => {
  return participants.filter(p => p.first_response_date).length;
};

export const getQuestionsAndAnswers = (questions: {[id: number]: MeetingQuestion}, isParent?: boolean): string => {
  let questionsString = '';
  const newQuestions = Object.values(questions);
  if (newQuestions.length) {
    newQuestions.forEach((question, idx) => {
      if (!isParent) {
        const answerObj = Object.values(question.answers || {}).at(0);
        const answer = getAnswerString(answerObj, question, '- No Response -');
        questionsString += `Q: ${question.title} \n A: ${answer}`;
        if (idx + 1 < newQuestions.length) {
          questionsString += '\n\n';
        }
      } else {
        questionsString += `Q: ${question.title}`;
        if (question.options) {
          question.options.forEach(o => {
            questionsString += `\n • ${o.name}`;
          });
        }
        if (idx + 1 < newQuestions.length) {
          questionsString += '\n\n';
        }
      }
    });
  }
  return questionsString;
};

export const replaceTemplateVariables = (
  origText: string, attendeeName: string, questionsAnswers: {questionId: number, question: string, 
    answer: string, answerOptions?: number[]}[] 
  | []
) => {
  // Build template variables
  const templateVariables: {[key: string]: string} = {
    "{{name}}": attendeeName,
    "{{attendee}}": attendeeName,
  };

  questionsAnswers.forEach(questionAnswer => {
    templateVariables[`{{question_${questionAnswer.questionId}}}`] = questionAnswer.question;
    templateVariables[`{{answer_${questionAnswer.questionId}}}`] = questionAnswer.answer;
  });
  
  const allVarsRegex = /\{\{[A-Za-z0-9_-]*\}\}/g;
  const allVarsFound = origText.match(allVarsRegex);
  let newText = origText;
  if (allVarsFound) {
    allVarsFound.forEach(varFound => {
      newText = newText.replace(
        varFound, 
        (templateVariables[varFound] && templateVariables[varFound] !== '' ? templateVariables[varFound] : varFound
        ));
    });
  }

  return newText;
};

export interface TemplateVars {
  [varName: string]: {
    group: string;
    explanation: string;
    replaceText: string;
    disabled?: boolean;
  }
}

const MAX_TRUNC_QUESTION_LENGTH = 20;

export const getTemplateVars = (questions?: Meeting['questions']) => {
  const templateVars: TemplateVars = {
    "attendee": {
      group: "Attendee Details", explanation: "Name", replaceText: "Attendee"
    },
  };
  if (questions) {
    Object.keys(questions).map(Number).forEach((questionId, index) => {
      const question = questions[questionId];
      const varName = `answer_${questionId}`;
      const questionTrunc = question.title.substring(0, MAX_TRUNC_QUESTION_LENGTH) +
        (question.title.length > MAX_TRUNC_QUESTION_LENGTH ? "..." : "");
      const replaceText = `Q${index + 1}: ${questionTrunc}`;
      if (question.required) {
        templateVars[varName] = {
          group: "Question Answers", explanation: questionTrunc, replaceText
        };
      } else {
        templateVars[varName] = {
          group: "Question Answers", explanation: questionTrunc, replaceText: '', disabled: true
        };
      }
    });
  }
  return templateVars;
};

export const nonReadDisableText = (canEdit: boolean, readable:boolean, provider: number, free_busy: boolean) => {
  const calendarProviderName = PROVIDER_BY_ID[provider]?.label;
  if (!readable && free_busy) {
    return "You only have Free/Busy access to this calendar";
  } else if (!canEdit && readable) {
    return 'You do not have permission to add or remove events for this calendar.';
  } else if (!canEdit && !readable) {
    return "You may not have full access to this calendar. If you're missing details from events, "
      + ` contact the calendar owner on ${calendarProviderName}.`;
  } 
  return undefined;
};

export const timePickerOptions = ( minuteInterval: number ): {value: string, label: string}[] => {
  const times: {value: string, label: string}[] = [];
  let startTime = 0;
  const ap = [' am', ' pm'];

  for (let i = 0; startTime < 24 * 60; i++) {
    const hh = Math.floor(startTime / 60);
    const mm = (startTime % 60);
    const hhString = String(hh).toString();
    const mmString = String(mm).toString();
    times[i] = {
      value: (hhString.length === 1 ? "0" + hhString : hhString) + 
        ":" + (mmString.length === 1 ? "0" + mmString : mmString),
      label: `${hh === 0 || hh === 12 ? '12' 
        : (hh % 12).toString().slice(-2)}:${( '0' + mm).slice(-2)}${ap[hh < 12 ? 0 : 1]}`};
    startTime = startTime + minuteInterval;
  }
  return times;
};

export const  getStartEndFromRecurringTimes = (
  data: RecurringTimes, startRange: DateTime, endRange: DateTime, startDate: DateTime
) => {
  let start = startRange;
  let end = endRange;
  if (data.options.limit.selected === "num_days") {
    const numDaysEnd = startDate.plus({days: data.options.limit.num_days});
    start = start > startDate ? start : startDate;
    end = end < numDaysEnd ? end : numDaysEnd;
  } else if (data.options.limit.selected === "date_range") {
    const dateRangeStart = DateTime.fromISO(data.options.limit.date_range.start);
    const dateRangeEnd = DateTime.fromISO(data.options.limit.date_range.end);
    // Adding a day to account for createMeetingSlotsFromRecurringTimes subtracting one
    start = start > dateRangeStart ? start : dateRangeStart.plus({days: 1});
    // Adding a day, since the time gets set to 12 AM
    end = end < dateRangeEnd ? end : dateRangeEnd.plus({days: 1});
  }
  return {start, end};
};

interface SlotStartEnd {
  start: DateTime;
  end: DateTime;
}

export const createMeetingSlotsFromRecurringTimes = (
  data: RecurringTimes, startRange: DateTime, endRange: DateTime,
  startDate: DateTime, ignoreSlots: MeetingSlot[] = [], meetingId: number,
  minDuration = 0
): MeetingSlot[] => {
  const {start, end} = getStartEndFromRecurringTimes(
    data, startRange, endRange, startDate.set({hour: 0, minute:0, second:0, millisecond: 0})
  );
  const allDates: MeetingSlot[] = [];
  // HACKY: Need to start 1 day earlier to make sure we catch all slots on Sundays
  //        This is an issue because of RRule.js spitting out bad days (see HACKY below)
  const startRangeJSDate = start.minus({days: 1}).toJSDate();
  const endRangeJSDate = end.toJSDate();

  Object.values(data.days).filter(day => day.enabled).forEach((day) => {
    day.rules.forEach((rule) => {
      const time = DateTime.fromISO(rule.rrule.time).setZone("UTC");
      const dtstart = datetime(time.year, time.month, time.day, time.hour, time.minute, 0);
      const until = datetime(end.year, end.month, end.day, end.hour, end.minute, 0);

      const rrule = new RRule({
        freq: RRule.WEEKLY,
        byweekday: rule.rrule.byweekday,
        dtstart,
        until,
      });
      const startDates = rrule.between(startRangeJSDate, endRangeJSDate);
      
      startDates.forEach(date => {
        // HACKY: RRule.js seems to have odd behavior around timezones. The day will sometimes
        //       be incorrect even if the time is correct. This is hacky but fixes that. Unless it's fixed,
        //       or we roll our own logic, this fix is needed.
        // Luxon:    Monday = 1, Sunday = 7
        // RRule.js: Monday = 0, Sunday = 6
        const expectedWeekday = rule.rrule.byweekday + 1;
        const luxonDate = DateTime.fromJSDate(date);
        const currentWeekday = luxonDate.weekday;
        let dayOffset = 0;
        if ((currentWeekday === 7 && expectedWeekday === 1) || currentWeekday < expectedWeekday) {
          // 1 day behind, add 1 day
          dayOffset = 1;
        } else if ((currentWeekday === 1 && expectedWeekday === 7) || currentWeekday > expectedWeekday) {
          // 1 day ahead, subtract 1 day
          dayOffset = -1;
        }
        const dateAdjusted = luxonDate.plus({days: dayOffset});
        // End hacky fix.

        const recurringSlotStart = dateAdjusted;
        const recurringSlotEnd = recurringSlotStart.set({second: recurringSlotStart.second + rule.duration});

        // Need to order by descending so we can process multiple consecutive slot divisions properly
        const ignoreStartEndTimes = ignoreSlots.map(s => ({
          start: DateTime.fromISO(s.start_date),
          end: DateTime.fromISO(s.end_date)
        })).sort((s1, s2) => s2.start.valueOf() - s1.start.valueOf());

        const recurringSlot = {start: recurringSlotStart, end: recurringSlotEnd};
        const validSlots = checkRecurringSlotExclusions(ignoreStartEndTimes, [recurringSlot]);
        for (const validSlot of validSlots) {
          if (minDuration && minDuration > 0) {
            const durationMinutes = (validSlot.end.toSeconds() - validSlot.start.toSeconds()) / 60;
            if (durationMinutes < minDuration) {
              continue;
            }
          }
          allDates.push({
            id: -1,
            meeting: meetingId,
            start_date: validSlot.start.toISO() || "",
            end_date: validSlot.end.toISO() || "",
            is_exclude: false,
            isRecurring: true
          });
        }
      });
    });
  });

  return allDates.sort((a, b) => DateTime.fromISO(a.start_date).toMillis() - DateTime.fromISO(b.start_date).toMillis());
};

// Recursive function to remove exclusions from generated recurring slots
// This is a classic interview question
const checkRecurringSlotExclusions = (
  ignoreStartEndTimes: SlotStartEnd[], recurringSlots: SlotStartEnd[]
): SlotStartEnd[] => {
  let newRecurringSlots: SlotStartEnd[] = [];
  recurringSlots.forEach(recSlot => {
    const slotStart = recSlot.start;
    const slotEnd = recSlot.end;
    let noConflicts = true;
    for (let i = 0; i < ignoreStartEndTimes.length; i++) {
      const ignoreSlot = ignoreStartEndTimes[i];
      const ignoreSlotStart = ignoreSlot.start;
      const ignoreSlotEnd = ignoreSlot.end;
      // This is the most efficient way to handle the common case of no exclusions
      if (slotStart < ignoreSlotEnd && slotEnd > ignoreSlotStart) {
        noConflicts = false;
        let newSlots: SlotStartEnd[] = [];
        if (slotStart >= ignoreSlotStart && slotEnd <= ignoreSlotEnd) {
          // Condition: The entire slot is blocked by an exclusion
          // Solution: Skip it
        } else if (slotStart >= ignoreSlotStart && slotEnd > ignoreSlotEnd) {
          // Condition: The beginning of the slot is blocked by an exclusion
          // Solution: Start the slot at the end of the exclusion
          newSlots = checkRecurringSlotExclusions(
            ignoreStartEndTimes.slice(i), [{start: ignoreSlotEnd, end: slotEnd}]
          );
        } else if (slotStart < ignoreSlotStart && slotEnd <= ignoreSlotEnd) {
          // Condition: The end of the slot is blocked by an exclusion
          // Solution: End the slot at the start of the exclusion
          newSlots = checkRecurringSlotExclusions(
            ignoreStartEndTimes.slice(i), [{start: slotStart, end: ignoreSlotStart}]
          );
        } else {
          // Condition: The exclusion is inside of the slot (starts after, ends before)
          // Solution: Split the slot into 2 by removing the excluded range
          newSlots = checkRecurringSlotExclusions(
            ignoreStartEndTimes.slice(i), [
              {start: slotStart, end: ignoreSlotStart},
              {start: ignoreSlotEnd, end: slotEnd}
            ]
          );
        }
        newRecurringSlots = [...newRecurringSlots, ...newSlots];
        break;
      }
    }
    if (noConflicts) {
      newRecurringSlots.push(recSlot);
    }
  });
  return newRecurringSlots;
};


const getRecurringTimesTemplate = (): RecurringTimes => {
  return {
    days: {
      mon: {
        rules: [],
        enabled: false
      },
      tue: {
        rules: [],
        enabled: false
      },
      wed: {
        rules: [],
        enabled: false
      },
      thu: {
        rules: [],
        enabled: false
      },
      fri: {
        rules: [],
        enabled: false
      },
      sat: {
        rules: [],
        enabled: false
      },
      sun: {
        rules: [],
        enabled: false
      },
    },
    options: {
      limit: {
        selected: null,
        num_days: 30,
        date_range: {
          start: DateTime.now().toISO(),
          end: DateTime.now().toISO()
        }
      }
    }
  };
};

const getRecurringTimesFormDataTemplate = (): RecurringTimeFormData => {
  return {
    mon: {
      times: [],
      enabled: false
    },
    tue: {
      times: [],
      enabled: false
    },
    wed: {
      times: [],
      enabled: false
    },
    thu: {
      times: [],
      enabled: false
    },
    fri: {
      times: [],
      enabled: false
    },
    sat: {
      times: [],
      enabled: false
    },
    sun: {
      times: [],
      enabled: false
    },
  };
};

const ABBREVIATED_DAY_TO_DAY_INT = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

export const convertRecurringTimesFormDataToRecurringTimes = (
  data: RecurringTimeFormData, optionsData: RecurringTimesOptions
): RecurringTimes => {
  const recurringTimesData = getRecurringTimesTemplate();
  Object.entries(data).forEach(([key, value]) => {
    const typedKey = key as AbbreviatedDays;
    recurringTimesData.days[typedKey].enabled = value.enabled;
    const rules: RecurringTimes["days"][AbbreviatedDays]["rules"] = value.times.map((time) => {
      const startSplit = time.startTime.split(":");
      const endSplit = time.endTime.split(":");
      const startSeconds = Number(startSplit[0]) * 3600 + Number(startSplit[1]) * 60;
      const endSeconds = Number(endSplit[0]) * 3600 + Number(endSplit[1]) * 60;

      const duration = endSeconds - startSeconds;
      return {
        rrule: {
          "freq": "WEEKLY",
          "byweekday": ABBREVIATED_DAY_TO_DAY_INT[typedKey],
          "time": time.startTime
        },
        duration: duration
      };
    });
    recurringTimesData.days[typedKey].rules = rules;
    recurringTimesData.options = optionsData;
  });
  return recurringTimesData;
};

export const convertRecurringTimesToRecurringTimesFormData = (
  data: RecurringTimes
): RecurringTimeFormData => {
  const recurringTimesData = getRecurringTimesFormDataTemplate();
  Object.entries(data.days).forEach(([key, value]) => {
    const typedKey = key as AbbreviatedDays;
    recurringTimesData[typedKey].enabled = value.enabled;
    value.rules.forEach((rule, idx) => {
      const start = DateTime.fromISO(rule.rrule.time);
      const end = start.plus({seconds: rule.duration});
      recurringTimesData[typedKey].times[idx] = {
        startTime: start.toFormat("HH:mm"),
        endTime: end.toFormat("HH:mm")
      };
    });
  });
  return recurringTimesData;
};

export const convertMeetingSlotsToExcludedSingleDateFormData = (
  data: MeetingSlot[]
): ExcludedSingleDateFormData[] => {
  const excludedSingleDateFormData: ExcludedSingleDateFormData[] = [];
  data.forEach(slot => {
    const slotData: ExcludedSingleDateFormData = {
      slotId: slot.id,
      date: slot.start_date,
      startTime: DateTime.fromISO(slot.start_date).toFormat('HH:mm'),
      endTime: DateTime.fromISO(slot.end_date).toFormat('HH:mm')
    };
    excludedSingleDateFormData.push(slotData);
  });
  return excludedSingleDateFormData;
};

export const convertExcludedSingleDateFormDataToMeetingSlots = (
  data: ExcludedSingleDateFormData[], meetingId: number
): MeetingSlot[] => {
  const meetingSlots: MeetingSlot[] = [];
  data.forEach(slot => {
    const startHour = DateTime.fromISO(slot.startTime).hour;
    const startMin = DateTime.fromISO(slot.startTime).minute;
    const endHour = DateTime.fromISO(slot.endTime).hour;
    const endMin = DateTime.fromISO(slot.endTime).minute;
    const slotData: MeetingSlot = {
      id: slot.slotId,
      meeting: meetingId,
      start_date: DateTime.fromISO(slot.date).set({
        hour: startHour, minute: startMin, second: 0, millisecond: 0
      }).toISO() || "",
      end_date: DateTime.fromISO(slot.date).set({
        hour: endHour, minute: endMin, second: 0, millisecond: 0
      }).toISO() || "",
      is_exclude: true
    };
    meetingSlots.push(slotData);
  });
  return meetingSlots;
};


interface CombineSlotIntermediate {
  date: DateTime;
  anchor: "start" | "end";
  slot: MeetingSlot;
}

export const combineSlots = (slots: MeetingSlot[]): Array<CombinedMeetingSlot | MeetingSlot> => {
  const intermediates: Array<CombineSlotIntermediate> = [];
  const nonCombinedSlots: MeetingSlot[] = [];

  slots.forEach(slot => {
    // We only want to combine slots that are pending for another meeting and
    //    are not recurring or exclusions
    if (slot.editable || slot.isRecurring || slot.is_exclude) {
      nonCombinedSlots.push(slot);
    } else {
      intermediates.push({
        date: DateTime.fromISO(slot.start_date),
        anchor: "start",
        slot
      });

      intermediates.push({
        date: DateTime.fromISO(slot.end_date),
        anchor: "end",
        slot
      });
    }
  });
  
  if (intermediates.length === 0) {
    // If we have nothing left here, it means there was nothing to combine, so just
    //   return the original slots
    return slots;
  }

  // We need to order by date ascending first, and then by "end" anchors first.
  //   This is so that we see all dates in order and can push/pop from the stack, 
  //   and we don't see new slots starting before existing ones end if the start and end
  //   times are the same between the 2 slots.
  const ordered = intermediates.sort((i1, i2) => {
    const secDiff = i1.date.toSeconds() - i2.date.toSeconds();
    if (secDiff !== 0) {
      return secDiff;
    }
    if (i1.anchor === i2.anchor) {
      return 0;
    }
    if (i1.anchor === "end" && i2.anchor === "start") {
      return -1;
    }
    return 1;
  });


  const combinedSlots: Array<CombinedMeetingSlot | MeetingSlot> = [];
  // Use one slot as the template
  const firstSlot = ordered[0].slot;
  const currentSlot: CombinedMeetingSlot = {
    id: firstSlot.id,
    start_date: firstSlot.start_date,
    end_date: firstSlot.end_date,
    backgroundColor: firstSlot.backgroundColor,
    title: firstSlot.title,
    textColor: firstSlot.textColor,
    className: firstSlot.className,
    containedSlots: []
  };

  let lastDateChecked: DateTime = ordered[0].date;
  let nextSlotStart: string = ordered[0].date.toISO() || "";
  
  ordered.forEach(intermediate => {
    if (
      currentSlot.containedSlots && currentSlot.containedSlots.length > 0
      && intermediate.date > lastDateChecked
    ) {
      // If the date changed, add the set as a slot since it's about to change
      let newSlot: MeetingSlot | CombinedMeetingSlot = {...currentSlot};
      if (currentSlot.containedSlots.length === 1) {
        // If there is only one slot in this batch, show the actual slot instead
        //   of "1 Pending"
        newSlot = {...currentSlot.containedSlots[0]};
      } else {
        newSlot.title = `(${currentSlot.containedSlots.length || ""}) Pending Meetings`;
        newSlot.id = lastDateChecked.toSeconds();
      }
      newSlot.start_date = nextSlotStart;
      newSlot.end_date = intermediate.date.toISO() || "";
      combinedSlots.push(newSlot);
      nextSlotStart = intermediate.date.toISO() || "";
    }

    // If there are no slots in the stack, we want to keep incrementing the next start date
    if (!currentSlot.containedSlots || currentSlot.containedSlots.length === 0) {
      nextSlotStart = intermediate.date.toISO() || "";
    }

    if (intermediate.anchor === "start") {
      // If this is a new slot start, add the slot to the set
      currentSlot.containedSlots?.push(intermediate.slot);
    } else {
      // If this is the end of a slot, remove it from the set
      currentSlot.containedSlots = currentSlot.containedSlots?.filter(slot => (
        slot.id !== intermediate.slot.id
      ));
    }

    lastDateChecked = intermediate.date;
  });

  // Now we need to merge in the other non-combined slots with the combined ones
  const allSlots = combinedSlots.concat(nonCombinedSlots); 
  return allSlots;
};

export const updateCalendarsFromRemoteCalendars = (calendars: Calendar[], remoteCalendars: RemoteCalendar[]) => {
  const calendars_map: {[key: number]: Calendar} = {};
  calendars.forEach((cal) => {
    calendars_map[cal.id] = cal;
  });

  // need to make sure this accounts for the case where a Calendar does not exist but RemoteCalendar does
  remoteCalendars.forEach((remoteCal) => {
    const selectedCalendar = calendars_map[remoteCal.id] as Calendar | undefined;
    calendars_map[remoteCal.id] = {
      ...selectedCalendar,
      autoBackgroundColor: selectedCalendar?.autoBackgroundColor || '#C50F1F',
      autoForegroundColor: selectedCalendar?.autoForegroundColor || '#000000',
      overrideBackgroundColor: selectedCalendar?.overrideBackgroundColor || null,
      overrideForegroundColor: selectedCalendar?.overrideForegroundColor || null,
      leaders: selectedCalendar?.leaders || [],
      delegatorUser: selectedCalendar?.delegatorUser || null,
      freeBusy: remoteCal.freeBusy || false,
      ...remoteCal,
      backgroundColor: selectedCalendar?.overrideBackgroundColor ? 
        selectedCalendar.overrideBackgroundColor : remoteCal.backgroundColor,
      foregroundColor: selectedCalendar?.overrideForegroundColor ? 
        selectedCalendar.overrideForegroundColor : remoteCal.foregroundColor,
      isResource: remoteCal.isResource,
    };
  });
  return Array.from(Object.values(calendars_map));
};

export const calendarToParticipant = (
  meetingId: number,
  calendar: Calendar,
  assignedId: number
): PrivateNormalizedExternalParticipants[number] => {
  return {
    id: assignedId,
    email: calendar.owner_email ? 
      calendar.owner_email : (
        calendar.provider ===  PROVIDER["GOOGLE"].id ? calendar.calendar_id : calendar.owner_email ),
    name: calendar.additionalCalendarEmail?.name || calendar.summaryOverride || calendar.summary,
    required: true,
    should_invite: true,
    view_calendar: true,
    prevent_conflicts: true,
    is_fetchable: true,
    meeting: meetingId,
    email_hash: "",
    first_response_date: "",
    no_times_comment: null,
    calendar_access: calendar.calendar_access_id
  };
};

export const meetingLeaderInfoToMeetingLeader = (
  info: MeetingLeaderInfo, assignId: number, assignMeeting: number
):  MeetingLeader => ({
  required: info.required ?? true,
  should_invite: info.should_invite ?? true,
  view_calendar: info.view_calendar ?? true,
  prevent_conflicts: info.prevent_conflicts  ?? true,
  leader: info.id,
  id: info.meeting_leader_id > 0 ? info.meeting_leader_id : assignId,
  meeting: assignMeeting,
  first_name: info.first_name,
  last_name: info.last_name,
  color: info.color,
  pic_url: info.pic_url
});

export const getMeetingCalendarIds = (
  calendarMap: {[key: number]: Calendar}, mtg: Partial<Meeting> | null
): string[] => {
  return Object.values(mtg?.participants || {})
    .filter(partItr => (
      !!partItr.calendar_access && partItr.view_calendar && calendarMap[partItr.calendar_access || 0]?.calendar_id
    ))
    .map(partItr => calendarMap[partItr.calendar_access || 0].calendar_id);
};

export const getMeetingCalendarPk = (mtg: Partial<Meeting> | null): number[] => {
  return Object.values(
    mtg?.participants || {}
  ).filter(
    partItr => !!partItr.calendar_access && partItr.view_calendar
  ).map(partItr => partItr.id);
};

export const getAnswerString = (
  answer: MeetingQuestionAnswerSubmission | undefined, question: MeetingQuestion, defaultStr?: string) => {
  const defaultString = defaultStr ?? '';
  if (answer) {
    if (question.question_type !== QuestionType.TEXT) {
      if (answer?.options && answer.options.length > 0 && question?.options) {
        const answerArr: string[] = [];
        const questionOptions = [...question.options];
        answer.options.forEach(ao => {
          answerArr.push(questionOptions.filter(o => o.id === ao)[0]?.name);
        });
        return answerArr.join(', ');
      }
    } else if (answer?.text && answer.text !== '') {
      return answer.text;
    }
  }
  return defaultString;
};

export const getPrimaryCalendar = (leader: Leader, leadersCalendars: Calendar[]): Calendar | undefined => {
  if (leadersCalendars.length <= 1) return leadersCalendars[0];

  let remainingCalendars = leadersCalendars;

  // 1. Owner primary calendar matching leader's email
  const ownerPrimaryMatchingEmail = remainingCalendars.filter(
    cal => cal.is_owner_primary && leader.email === cal.owner_email
  );
  if (ownerPrimaryMatchingEmail.length === 1) return ownerPrimaryMatchingEmail[0];
  if (ownerPrimaryMatchingEmail.length > 1) remainingCalendars = ownerPrimaryMatchingEmail;

  // 2. Editable primary owner calendar  
  const editablePrimaryOwner = remainingCalendars.filter(
    cal => cal.is_owner_primary && cal.canEdit
  );
  if (editablePrimaryOwner.length === 1) return editablePrimaryOwner[0];
  if (editablePrimaryOwner.length > 1) remainingCalendars = editablePrimaryOwner;

  // 3. Any primary owner calendar
  const anyPrimaryOwner = remainingCalendars.filter(
    cal => cal.is_owner_primary
  );
  if (anyPrimaryOwner.length === 1) return anyPrimaryOwner[0];
  if (anyPrimaryOwner.length > 1) remainingCalendars = anyPrimaryOwner;

  // 4. Editable non-resource calendar
  const editableNonResource = remainingCalendars.filter(
    cal => cal.canEdit && !cal.isResource
  );
  if (editableNonResource.length === 1) return editableNonResource[0];
  if (editableNonResource.length > 1) remainingCalendars = editableNonResource;

  // 5. Any non-resource calendar
  const anyNonResource = remainingCalendars.filter(
    cal => !cal.isResource
  );
  if (anyNonResource.length === 1) return anyNonResource[0];
  if (anyNonResource.length > 1) remainingCalendars = anyNonResource;

  // 6. First remaining calendar
  return remainingCalendars[0];
};