import React, { ReactElement, useState, useRef, useCallback, useMemo, memo } from 'react';
import { 
  AttendeeResponseStatus, LeaderBorders, Meetings, MeetingSlot, RBCEvent, UICalendarEventConsolidated,
  EventAttendee, CombinedMeetingSlot, Calendar, Meeting, Leader
} from '../../../store';
import { allTimeZones, getEventDuration, getLocalTimeZoneName, getMeetingCalendarIds, getPickerDay, 
  getSlotDuration, getTemplateVars, getWeekRange, TimeZone } from '../../../utils/scheduleUtils';
import { Grid, styled,  Typography, Box, SxProps, ButtonBase } from '@mui/material';
import { CabDropdown, CabTimezoneInput, CabDatePicker, CabIcon, CabTooltip } from '@CabComponents';
import colors from '../../../colors';

import CustomWorkWeek from './CustomWorkWeek';
import { DateTime, Settings } from 'luxon';
import { 
  Calendar as RBCCalendar, luxonLocalizer, EventProps,
  View as RBCView, Views as RBCViews, SlotInfo, CalendarProps as RBCCalendarProps,
} from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'; // if using DnD
import { useElementBreakpoint, useMountEffect } from '../../../utils/hooks';
import { formatSlotTimeTiny } from '../../../utils/datesUtils';
import { ActionMenu } from '../../Meeting/ActionMenu';
import { CabButton } from '@CabComponents';
import { getTokenDisplayAsText } from '@CabComponents/CabTextTokenDisplay';
import EventDetailPopover from './EventDetailPopover';
import { range as lodashRange } from 'lodash-es';
import { 
  IoChevronForward, IoChevronBack, IoWarning, IoBanOutline, IoCloseCircle, IoRepeatOutline 
} from 'react-icons/io5';
import dayLayoutAlgorithm from './dayLayoutAlgorithm';

const DnDCalendar = withDragAndDrop<RBCEvent>(RBCCalendar as React.ComponentType<RBCCalendarProps<RBCEvent, object>>);

interface RBCEventChangeProps { 
  event: RBCEvent;
  start: Date | string;
  end: Date | string; 
  isAllDay?: boolean; 
}
interface CalendarProps { 
  onSlotSelected?: (slotInfo: SlotInfo) => void;
  onSlotEdited?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDateChange: (start: DateTime, end: DateTime) => void;
  events: UICalendarEventConsolidated[];
  slotsSelected: Array<CombinedMeetingSlot | MeetingSlot>;
  recurringSlots: MeetingSlot[];
  granularTimeSelection: boolean;
  useMultiTimezone?: boolean;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  calendarTimezoneSelected: TimeZone | undefined;
  onCalendarTimezoneSelected?: (timezone: TimeZone) => void;
  secondaryTimezonesSelected: (TimeZone | undefined)[];
  onSecondaryTimezoneSelected?: (idx: number, timezone: TimeZone) => void;
  onOpenMeeting?: (meetingId: number) => void;
  onOpenPollResults?: (meetingId: number) => void;
  onDeleteSlots?: (slots: MeetingSlot[]) => void;
  currentMeetingId?: number;
  currentMeetingDurationMinutes?: number;
  isPoll?: boolean;
  meetings: Meetings;
  onDuplicateMeeting?: (meetingId: number) => void;
  onDeleteMeeting?: (meetingId: number) => void;
  onShareMeeting?: (meetingId: number) => void;
  onCopyLink?: (meetingId: number) => void;
  meetingPreventDoubleBooking?: boolean;
  sideBySideMap: { resourceId: string; columnTitle: string }[];
  hideTodayButton?: boolean;
  hideViewPicker?: boolean;
  noCalendarBorder?: boolean;
  hideResourceHeader?: boolean;
  newMeeting: Partial<Meeting> | null;
  interactive?: boolean;
  showMultiLeaderColumns?: boolean;
  allowedDates?: DateTime[];
  calendarView: RBCView;
  onCalendarViewChange: (v: RBCView) => void;
  calendarMap: {[key: number]: Calendar};
  hideCalendarHeader?: boolean;
}

// This is all untyped in the library and we are not using it, so no sense over-typing it ourselves right now.
//    The goal of typing this is just to specifically indicate that we can (and should) access the 
//    children that are 2 levels deep, and where the actual dates for the time indicators are defined.
interface TimeGutterWrapperChildsChildProps {
  [key: string]: unknown;
  group: Date[];
}

interface TimeGutterWrapperChildProps {
  [key: string]: unknown;
  children: TimeGutterWrapperChildsChild[];
}

type TimeGutterWrapperChildsChild = ReactElement & { props: TimeGutterWrapperChildsChildProps };
type TimeGutterWrapperChild = ReactElement & { props: TimeGutterWrapperChildProps };
type TimeGutterWrapperProps = {
  // slotMetrics has some interesting data, but we aren't using it and it's not typed in RBC.
  slotMetrics: unknown;
  children: TimeGutterWrapperChild[];
};


const NoWhiteSpace = styled("div", {label: "NoWhiteSpace"})({
  whiteSpace: "nowrap",
  overflow: "hidden",
  height: "100%"
});

interface PrevNextArrowsProps { 
  direction: 'forward'|'back';
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
}

const PrevNextArrows = ({ direction, onClick, disabled, sx }: PrevNextArrowsProps) => (
  <CabIcon
    Icon={direction === 'forward' ? IoChevronForward : IoChevronBack}
    alt={direction}
    onClick={disabled ? undefined : onClick}
    sx={{
      width: '40px',
      minWidth: '40px',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '28px',
      color: disabled ? colors.black300 : colors.black600,
      ...sx
    }}
  />
);

const calendarStyle: RBCCalendarProps['style'] = {
  position: 'absolute',
  top: 0, bottom: 0,
  width: '100%',
  fontFamily: 'Inter',
  color: colors.black700,
};

const resourceIdAccessor = ((r: (CalendarProps['sideBySideMap'])[number]) => r.resourceId) as (r: object) => string;
const resourceTitleAccessor = ((r: (CalendarProps['sideBySideMap'])[number]) => r.columnTitle) as (r: object) => string;

const slotToRBC = (event: Omit<UICalendarEventConsolidated, "calendarId" | "iCalUId">): RBCEvent => {
  // NOTE: Temporarily doing a hacky 12-hour offset until we can get timezone corrections done properly.
  //       This is fine for now because it's impacting only the render of the event on the calendar and
  //       ensures all-day events will remain on the day they are meant to.
  const start = DateTime.fromISO(event.start);
  const end = DateTime.fromISO(event.end);
  const adjustedStart = event.allDay ? start.plus({hours: 12}) : start;
  const adjustedEnd = event.allDay ? end.plus({hours: 12, days: -1}) : end;

  return {
    id: event.id,
    title: event.title,
    location: event.location,
    start: adjustedStart.toJSDate(),
    end: adjustedEnd.toJSDate(),
    allDay: event.allDay,
    className: event.className,
    backgroundColor: event.backgroundColor,
    textColor: event.textColor,
    // borderColor: event.isBackground ? undefined : event.borderColor,
    borderColor: event.isBackground ? undefined : colors.white900,
    leaderBackgroundColors: event.isBackground ? [] : event.leaderBackgroundColors,
    leaderBorders: event.isBackground ? [] : event.leaderBorders,
    editable: event.editable || false,
    selfResponse: event.selfResponse ?? "accepted",
    attendees: event.attendees ?? [],
    leaderInfo: event.leaderInfo,
    calendarInfo: event.calendarInfo,
    busy: event.busy,
    resourceId: event.resourceId,
    bufferStartMinutes: event.bufferStartMinutes,
    bufferEndMinutes: event.bufferEndMinutes,
    recurringRootEventIdr: event.recurringRootEventIdr,
  };
};

const eventPropGetter = (event: RBCEvent) => ({
  className: event.className,
  style: {
    background: event.selfResponse === "declined" ? colors.white900 : event.backgroundColor,
    textDecoration: event.selfResponse === "declined" ? "line-through" : "none",
    borderColor: event.editable ? '#9296A4' : event.borderColor,
    color: event.textColor,
    minWidth: 35
  }
});

const onNavigate: RBCCalendarProps['onNavigate'] = () => {
  // Empty function because we are not using RBC's controls but we don't want an error thrown.
  return;
};

const views = {
  [RBCViews.WORK_WEEK]: CustomWorkWeek,
  [RBCViews.WEEK]: true,
  [RBCViews.DAY]: true
};

const tooltipAccessor = () => "";

const scrollToHour = 8;


const Scheduler = ({
  onSlotSelected, onSlotEdited, slotsSelected, useMultiTimezone, onDateChange, events, currentDateRangeInfo, 
  onOpenMeeting, onDeleteSlots, calendarTimezoneSelected, onCalendarTimezoneSelected,
  secondaryTimezonesSelected, onSecondaryTimezoneSelected, onShareMeeting, onCopyLink, currentMeetingId,
  currentMeetingDurationMinutes, isPoll, meetings, onDuplicateMeeting, onDeleteMeeting, recurringSlots,
  meetingPreventDoubleBooking, sideBySideMap, hideTodayButton, hideViewPicker, noCalendarBorder,
  hideResourceHeader, newMeeting, interactive = true, showMultiLeaderColumns, allowedDates, calendarView,
  calendarMap, onCalendarViewChange, onOpenPollResults, granularTimeSelection, hideCalendarHeader
} : CalendarProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rbcRef = useRef<RBCCalendar<RBCEvent>>(null);
  const scrollToTimeRef = useRef<Element>();

  const [highlightedSlotId, setHighlightedSlotId] = useState<number|null>(null);
  const [eventEl, setEventEl] = useState<Element | null>(null);

  const calendarStep = granularTimeSelection ? 5 : 15;
  // This somehow works perfectly
  const timeSlotGroupHeight = 81 - calendarStep;
  const timeslots = Math.floor(60 / calendarStep);


  const timezone = useMemo(() => (
    calendarTimezoneSelected?.name || ''
  ), [calendarTimezoneSelected?.name]);

  const timezoneShort = useMemo(() => (
    calendarTimezoneSelected?.abbreviation || ''
  ), [calendarTimezoneSelected?.abbreviation]);

  const secondaryTimezones = useMemo(() => (
    secondaryTimezonesSelected?.map(tz => tz?.name || '') || []
  // Need to do a deep compare which is not recognized by linter
  //    Every use of secondaryTimezones should be safe
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [JSON.stringify(secondaryTimezonesSelected)]);

  // const secondaryTimezonesShort = useMemo(() => (
  //   secondaryTimezonesSelected?.map(tz => tz?.abbreviation || '') || []
  // // Need to do a deep compare which is not recognized by linter
  // //    Every use of secondaryTimezonesShort should be safe
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // ), [JSON.stringify(secondaryTimezonesSelected)]);


  const numTimezones = useMemo(() => (
    !useMultiTimezone ? 1 : 1 + (secondaryTimezones?.length ?? 0)
  ), [secondaryTimezones?.length, useMultiTimezone]);

  const numDaysForView = useMemo(() => (
    calendarView === RBCViews.WORK_WEEK ? 5 : calendarView === RBCViews.WEEK ? 7 : 1
  ), [calendarView]);
  
  // Offset the breakpoints based on the number of timezones a user has added if more than 3
  const tzOffset = Math.max(0, useMultiTimezone ? secondaryTimezones.length - 2 : 0) * 55;
  const breakpoints = {
    xs: 620 + tzOffset,
    sm: 660 + tzOffset,
    lg: 661 + tzOffset
  };
  const calBreakpoint = useElementBreakpoint(containerRef, {
    [breakpoints.xs]: "xs",
    [breakpoints.sm]: "sm",
    [breakpoints.lg]: "lg"
  }, 'lg');
  const isXs = calBreakpoint === "xs" || calBreakpoint === "sm";
  const isSm = calBreakpoint === "sm";

  const { localizer, getNow, scrollToTime } = useMemo(() => {
    Settings.defaultZone = DateTime.local().setZone(timezone).zoneName || "";

    return {
      localizer: luxonLocalizer(DateTime),
      getNow: () => DateTime.local().toJSDate(),
      scrollToTime: DateTime.fromObject({ year: 2000, month: 1, day: 1, hour: scrollToHour, minute: 0 }).toJSDate(),
    };
  }, [timezone]);

  useMountEffect(() => {
    scrollToTimeRef.current?.scrollIntoView();
  });

  const onPickerDateChange = useCallback((newDateStr: string | null) => {
    if (newDateStr) {

      const newDate = DateTime.fromISO(newDateStr, {zone: timezone});

      if (calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK) {
        const {start, end } = getWeekRange(newDate);
        onDateChange(start, end);
      } else if (calendarView === RBCViews.DAY) {
        const startDay = newDate.startOf('day');
        const endDay = newDate.endOf('day');
        onDateChange(startDay, endDay);
      }

    }
  }, [calendarView, onDateChange, timezone]);

  // TODO: Evaluate the need for this. We should initialize date in redux or containers
  // This use effect is required to make sure currentDateRangeInfo gets set on initial render 
  //    since the RBC date change event will not trigger on mount
  // useMountEffect(() => {
  //   onPickerDateChange(currentDateRangeInfo?.start.toISOString() || DateTime.local().toISODate());
  // });

  const currentDate = currentDateRangeInfo
    ? DateTime.fromISO(currentDateRangeInfo.start.toISO() || '', {zone: timezone})
    : DateTime.now().setZone(timezone);
  const nextAllowedDate = allowedDates?.find(d => d.toMillis() > currentDate.toMillis());
  const prevAllowedDate = allowedDates && [...allowedDates].reverse()
    .find(d => d.toMillis() < currentDate.toMillis());

  const next = () => {
    const dateRangeType = calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'weeks' : 'days';
    let newDate = DateTime.fromISO(currentDateRangeInfo.start.toISO() || '', {zone: timezone})
      .plus({[dateRangeType]: 1});

    if (allowedDates) {
      const nextAvailableDate = allowedDates.find(d => d.toMillis() >= newDate.toMillis());
      if (nextAvailableDate) {
        newDate = nextAvailableDate;
      } else {
        return;
      }
    }

    onPickerDateChange(dateRangeType === 'weeks'
      ? newDate.plus({days: 1}).toISO()
      : newDate.toISO());
  };

  const prev = () => {
    const dateRangeType = calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'weeks' : 'days';
    let newDate = currentDateRangeInfo.start.minus({[dateRangeType]: 1}); 

    if (allowedDates) {
      const prevAvailableDate = [...allowedDates].reverse().find(d => d.toMillis() <= newDate.toMillis());
      if (prevAvailableDate) {
        newDate = prevAvailableDate;
      } else {
        return;
      }
    }

    onPickerDateChange(newDate.toISO());
  };

  const handleChangeView = useCallback((view: RBCView) => {
    if (view === RBCViews.DAY) {
      const startDate = DateTime.fromISO(getNow().toISOString()).startOf('day');
      const endDate = startDate.endOf('day');
      onDateChange(startDate, endDate);
    } else {
      if (currentDateRangeInfo) {
        const { start, end } = getWeekRange(currentDateRangeInfo.start);
        onDateChange(start, end);
      }
    }

    // onCalendarViewChange must be called AFTER the .changeView above or else the view will not change properly
    //  I expect this has to do with the calendar being re-rendered due to state change and interrupting the
    //  .changeView function
    onCalendarViewChange(view);
  }, [currentDateRangeInfo, getNow, onDateChange, onCalendarViewChange]);

  const handleRangeChange = useCallback((range: Date[] | {start: Date, end: Date}, view?: RBCView) => {
    if (!currentDateRangeInfo) {
      // make sure this is always a FULL week starting w/ Sunday
      let startDate = null;
      if (Array.isArray(range)) {
        if (range.length > 0) {
          startDate = range[0];
        }
      } else {
        startDate = range.start;
      }
      if (startDate) {
        let start = DateTime.fromJSDate(startDate);
        start = start.startOf('week', {useLocaleWeeks: true});
        const end = start.plus({ days: 6 });
        onDateChange(start, end);
      }
    }
  }, [currentDateRangeInfo, onDateChange]);

  const handleDeleteSlot = useCallback((slotId: number) => {
    if (!onDeleteSlots) return;

    const slot = slotsSelected.find(slotEvent => slotEvent.id === slotId);
    if (slot && "editable" in slot) {
      onDeleteSlots([slot]);
      setEventEl(null);
    }
  }, [slotsSelected, onDeleteSlots]);

  //filtering out excluded slots that are not in the current meeting
  const slotEvents: Omit<UICalendarEventConsolidated, 'calendarId' | 'iCalUId'>[] = useMemo(() => slotsSelected
    .filter(slot => (
      !("is_exclude" in slot) || !slot.is_exclude
      || (slot.is_exclude && (slot.meeting === currentMeetingId || slot.meeting < 0))
    ))
    .map(slot => {
      const slotMeeting = "meeting" in slot
        ? (slot.meeting < 0 ? newMeeting : meetings[slot.meeting])
        : null;
      const isExclude = Boolean("is_exclude" in slot && slot.is_exclude);
      const editable = Boolean("editable" in slot && slot.editable);
      const allDay = Boolean("allDay" in slot && slot.allDay);
      const selfResponse = "selfResponse" in slot ? slot.selfResponse : undefined;

      return {
        unique_id: slot.id.toString(),
        id: slot.id.toString(), 
        title: getTokenDisplayAsText(slot.title || '', getTemplateVars(slotMeeting?.questions)), 
        start: slot.start_date, 
        end: slot.end_date, 
        backgroundColor: isExclude ? colors.black200 : slot.backgroundColor,
        textColor: isExclude ? colors.navyDark : slot.textColor,
        borderColor: slot.borderColor || slot.backgroundColor,
        editable,
        className: slot.className,
        allDay,
        leaderBackgroundColors: [slot.backgroundColor || colors.black900],
        leaderBorders: [{color: slot.backgroundColor || colors.black900}],
        selfResponse: selfResponse,
        isBackground: isExclude,
        leaderInfo: slotMeeting?.leader_info,
        additionalCalendarIds: getMeetingCalendarIds(calendarMap, slotMeeting)
      };
    })
  , [currentMeetingId, meetings, newMeeting, slotsSelected, calendarMap]);

  const recurringSlotEvents: Omit<UICalendarEventConsolidated, 'calendarId'|'iCalUId'>[] = useMemo(() => recurringSlots
    .map(slot => {
      const slotMeeting = meetings[slot.meeting];
      return {
        unique_id: slot.id.toString(),
        id: `generated ${slot.id.toString()}`, 
        title: getTokenDisplayAsText(slot.title || '', getTemplateVars(slotMeeting?.questions)), 
        start: slot.start_date, 
        end: slot.end_date, 
        backgroundColor: slot.backgroundColor,
        textColor: colors.navyDark,
        borderColor: slot.backgroundColor,
        editable: slot.editable,
        className: slot.className,
        allDay: Boolean(slot.allDay),
        leaderBackgroundColors: [slot.backgroundColor || colors.black900],
        leaderBorders: [{color: slot.backgroundColor || colors.black900}],
        selfResponse: slot.selfResponse,
        isBackground: true,
        leaderInfo: slotMeeting?.leader_info,
        additionalCalendarIds: getMeetingCalendarIds(calendarMap, slotMeeting)
      };
    })
  , [meetings, recurringSlots, calendarMap]);

  const middleOfInterval = useMemo(() => {
    let date = currentDateRangeInfo 
      ? getPickerDay(currentDateRangeInfo.start.toISO() || '')
      : DateTime.local();

    let dayDiff = 0;
    if (calendarView === RBCViews.WORK_WEEK || calendarView === RBCViews.WEEK) {
      const { start } = getWeekRange(date);
      date = start;
      dayDiff = 2;
    }

    const midOfInterval = new Date(date.setZone(timezone).toJSDate());
    midOfInterval.setDate(midOfInterval.getDate() + dayDiff);

    return midOfInterval;
  }, [calendarView, currentDateRangeInfo, timezone]);

  const handleEventChange = useCallback((args: RBCEventChangeProps) => {
    if (!onSlotEdited) return;
    onSlotEdited(args.event.id, new Date(args.start), new Date(args.end), false); // isExcluded should be only false?
  }, [onSlotEdited]);

  const handleSelectEvent = useCallback((event: RBCEvent, e: React.SyntheticEvent<HTMLElement, Event>) => {
    const slot = slotsSelected.find(slotEvent => slotEvent.id === Number(event.id));
    if (slot !== undefined && "meeting" in slot && slot.meeting !== -1) {
      setHighlightedSlotId(Number(event.id));
      setEventEl(e.currentTarget);
    }
  }, [slotsSelected]);

  const handleDismissEventPopover = useCallback(() => {
    setEventEl(null);
    setHighlightedSlotId(null); 
  }, []);

  const handleEditMeeting = useCallback((slotId: number, meetingId?: number) => {
    if (!onOpenMeeting) return;
    const slot = slotsSelected.find(slotEvent => slotEvent.id === slotId);
    if (meetingId) {
      onOpenMeeting(meetingId);
    }
    if (slot && "meeting" in slot && slot.meeting) {
      onOpenMeeting(slot.meeting);
    }
  }, [onOpenMeeting, slotsSelected]);

  const handleSeeResults = useCallback((slotId: number, meetingId?: number) => {
    if (!onOpenPollResults) return;
    const slot = slotsSelected.find(slotEvent => slotEvent.id === slotId);
    if (meetingId) {
      onOpenPollResults(meetingId);
    }
    if (slot && "meeting" in slot && slot.meeting) {
      onOpenPollResults(slot.meeting);
    }
  }, [onOpenPollResults, slotsSelected]);

  const isCurrentMeeting = (slotId: number) => {
    const slot = slotsSelected.find(slotEvent => slotEvent.id === slotId);
    if (slot && "meeting" in slot && slot.meeting) {
      return currentMeetingId === slot.meeting;
    }
    return false;
  };

  const secondaryTzOffsets = useMemo(() => {
    if (!secondaryTimezones) {
      return [0];
    }
    const localDate = DateTime.local().setZone(timezone);

    // Timezones need to go from right to left so we reverse the order
    const offsetDiffs = secondaryTimezones.slice().reverse().map(tz => {
      const secondaryTzDate = DateTime.local().setZone(tz);
      const offsetDiff = secondaryTzDate.offset - localDate.offset;
      return offsetDiff;
    });
    return offsetDiffs;
  }, [timezone, secondaryTimezones]);

  const isHalfHourTzDiffs = useMemo(() => {
    return secondaryTzOffsets.map(o => o % 60 !== 0);
  }, [secondaryTzOffsets]);

  const baseTzColWidth = useMemo(() => {
    return isHalfHourTzDiffs.some(h => h === true) ? 60 : 45.5;
  }, [isHalfHourTzDiffs]);

  const secondaryTimezoneTimes = useMemo(() => {
    const times = secondaryTzOffsets.map(tzo => {
      // This starting time needs to be initialized with the primary timezone
      const startingTime = DateTime.now().setZone(timezone).set({ 
        hour: Math.floor(tzo / 60), 
        minute: tzo % 60,
        second: 0,
        millisecond: 0
      });

      const ts: Date[] = [];
      for (let i = 0; i < 24; i++) {
        const nextTime = startingTime.set({hour: startingTime.hour + i});
        ts.push(nextTime.toJSDate());
      }

      return ts;
    });
    return times;
  }, [timezone, secondaryTzOffsets]);

  const { calendarEvents, meetingSlotEvents, recurringMeetingSlotEvents } = useMemo(() => {
    let calEvents = events;
    let mtgSlotEvents = slotEvents;
    let recurringMtgSlotEvents = recurringSlotEvents;
    if (calendarView === RBCViews.DAY || showMultiLeaderColumns) {
      calEvents = events.flatMap(ev => [
        ...(ev.leaderInfo || []).map(li => ({ ...ev, resourceId: li.id.toString() })),
        ...(ev.calendarInfo || []).map(ci => ({ ...ev, resourceId: ci.owner_email || ci.summary })),
      ]);
      mtgSlotEvents = slotEvents.flatMap(ev => [
        ...(ev.leaderInfo || []).map(li => ({ ...ev, resourceId: li.id.toString() })),
        ...(ev.additionalCalendarIds || []).map(aci => ({ ...ev, resourceId: aci })),
      ]);
      recurringMtgSlotEvents = recurringSlotEvents.flatMap(ev => [
        // ev.leaderInfo?.length ? ev.leaderInfo?.map(li => ({ ...ev, resourceId: li.id.toString() }))
        //   : sideBySideMap.map(m => ({ ...ev, resourceId: m.resourceId }))
        ...(ev.leaderInfo || []).map(li => ({ ...ev, resourceId: li.id.toString() })),
        ...(ev.additionalCalendarIds || []).map(aci => ({ ...ev, resourceId: aci })),
      ]);
    }

    return {
      calendarEvents: calEvents,
      meetingSlotEvents: mtgSlotEvents,
      recurringMeetingSlotEvents: recurringMtgSlotEvents,
    };
  }, [events, slotEvents, recurringSlotEvents, calendarView, showMultiLeaderColumns]);

  const rbcForegroundEvents: RBCEvent[] = useMemo(() => (
    [...meetingSlotEvents.filter(e => !e.isBackground), ...calendarEvents]
      .map(event => slotToRBC(event))
  ), [calendarEvents, meetingSlotEvents]);

  const rbcBackgroundEvents: RBCEvent[] = useMemo(() => (
    [...meetingSlotEvents.filter(e => e.isBackground), ...recurringMeetingSlotEvents]
      .map(event => slotToRBC(event))
  ), [recurringMeetingSlotEvents, meetingSlotEvents]);

  const rbcComponents = useMemo(() => {
    const eventFunc = (args: EventProps<RBCEvent>) => {
      return (
        <EventComponent 
          args={args}
          currentMeetingDurationMinutes={currentMeetingDurationMinutes}
          currentMeetingId={currentMeetingId}
          handleDeleteSlot={handleDeleteSlot}
          handleOpenMeeting={onOpenMeeting}
          highlightedSlotId={highlightedSlotId}
          currentAllDayEventDates={[]}
          isPoll={isPoll || false}
          isRecurringEvent={!!args.event.recurringRootEventIdr}
          slotsSelected={[...slotsSelected, ...recurringSlots]}
          meetingPreventDoubleBooking={meetingPreventDoubleBooking}
          handleEditMeeting={handleEditMeeting}
          handleDeleteMeeting={onDeleteMeeting}
          handleDuplicateMeeting={onDuplicateMeeting}
          handleShareMeeting={onShareMeeting}
          calendarStep={calendarStep}
        />
      );
    };

    const timeGutterHeaderFunc = () => {
      const baseWidth = isHalfHourTzDiffs.some(h => h === true) ? 60 : 45.5;
      const totalWidth = numTimezones * baseWidth;
      // Accounts for 5px padding on left and right of gutter
      const colWidth = (totalWidth) / numTimezones;
      return <div style={{display: "flex", height: "100%"}}>
        {/* Timezones need to go from right to left so we reverse the order */}
        {useMultiTimezone && secondaryTimezones.slice().reverse().map((tz, i) => (
          <div key={i} style={{
            borderLeft: i > 0 ? "1px solid #dddddd" : "unset",
            width: colWidth, 
            textAlign: "center", 
            paddingTop: 4}}
          >
            {onSecondaryTimezoneSelected ? (
              <CabTimezoneInput
                key={i}
                size="small"
                label=""
                onChange={tzName => {
                  const newTz = allTimeZones.find(zone => zone.name === tzName);
                  if (newTz) {
                    onSecondaryTimezoneSelected(secondaryTimezones.length - i - 1, newTz);
                  }
                }}
                value={tz}
                shortFormat
                hideArrow
                sx={{
                  width: "100%",
                  textAlign: "center",
                  padding: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  },
                  "& .MuiInputBase-root": {
                    padding: '0 !important',
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "12px",
                    fontWeight: 600,
                    textDecoration: "underline",
                    color: colors.black700,
                  },
                }}
              />
            ) : tz}
          </div>
        ))}
        <div style={{
          borderLeft: useMultiTimezone ? "1px solid #dddddd" : "unset",
          width: colWidth, 
          textAlign: "center", 
          paddingTop: 4
        }}
        >
          {onCalendarTimezoneSelected ? (
            <CabTimezoneInput
              size="small"
              hideArrow
              label=""
              onChange={tzName => {
                const tz = allTimeZones.find(zone => zone.name === tzName);
                if (tz) {
                  onCalendarTimezoneSelected(tz);
                }
              }}
              value={timezone}
              shortFormat
              sx={{ 
                width: "100%",
                textAlign: "center",
                padding: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },
                "& .MuiInputBase-root": {
                  padding: '0 !important',
                },
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  fontWeight: 600,
                  textDecoration: "underline",
                  color: colors.black700,
                },
              }}
            />
          ) : timezoneShort}
        </div>
      </div>;
    };

    // RBC typing for `components` prop is wrong, so args needs to come in as `any` otherwise it won't work.
    //     We declare the type inside the function to have some semblance of type-safety.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timeGutterWrapperFunc = (args: any) => {
      if (!useMultiTimezone) {
        return args.children;
      }
      const wrapperArgs: TimeGutterWrapperProps = args;
      // Need to clone components/elements here because we are reusing most of them but tweaking one part of the
      //     inner-most children. cloneElement does a shallow copy of props, which is why we need to clone twice.
      const childrenCopy: TimeGutterWrapperChild[] = 
        React.Children.map(wrapperArgs.children, (c => React.cloneElement(c)));
      
      const innerChildrenCopy: TimeGutterWrapperChildsChild[] = 
        React.Children.map(childrenCopy[0].props.children, (c => React.cloneElement(c)));
      
      const allNewInnerChildren: TimeGutterWrapperChildsChild[][] = [];
      
      for (let j = 0; j < secondaryTimezoneTimes.length; j++) {
        const newInnerChildren: TimeGutterWrapperChildsChild[] = [];
        for (let i = 0; i < 24; i++) {
          const newInnerChild: TimeGutterWrapperChildsChild = {
            ...innerChildrenCopy[i],
            props: {
              ...innerChildrenCopy[i].props,
              // RBC just looks at the first time anyway, so there's no need to populate the group with all 4
              //    the way it is originally structured
              group: [
                secondaryTimezoneTimes[j][i]
              ]
            }
          };
          newInnerChildren.push(newInnerChild);
        }
        allNewInnerChildren.push(newInnerChildren);
      }

      const newChildren: TimeGutterWrapperProps['children'] = allNewInnerChildren.map(c => ({
        ...childrenCopy[0],
        props: {
          ...childrenCopy[0].props,
          children: c
        }
      }));

      return <div style={{display: "flex"}} className="time-gutter-column">
        {newChildren.map((c, i) => (
          <span key={i} style={{borderRight: "1px solid #dddddd"}}>
            {c}
          </span>
        ))}
        {wrapperArgs.children}
      </div>;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timeSlotWrapperFunc = (props: any /*{ children: ReactElement, value: Date }*/) => {
      const datetime = DateTime.fromJSDate(props.value).setZone(timezone);
      if (datetime.hour === scrollToHour && datetime.minute === 0 && !props.children.props.children) {
        const newElement = React.cloneElement(props.children, { ref: scrollToTimeRef });
        return newElement;
      }
      return props.children;
    };

    // `components` is set to a class variable in RBC ONLY on initial mount of the calendar
    //     in its constructor, so we need to forcibly overwrite it as there is no way to get it
    //     to change from setting the new function props. Any external/dynamic data that needs
    //     to be used in this function must be done so by this means, and will take effect at
    //     the next render.
    if (rbcRef.current) {
      (rbcRef.current as RBCCalendar<RBCEvent, object> & {
        components: { event: typeof eventFunc }
      })['components']['event'] = eventFunc;
      (rbcRef.current as RBCCalendar<RBCEvent, object> & {
        components: { timeGutterWrapper: typeof timeGutterWrapperFunc }
      })['components']['timeGutterWrapper'] = timeGutterWrapperFunc;
      (rbcRef.current as RBCCalendar<RBCEvent, object> & {
        components: { timeGutterHeader: typeof timeGutterHeaderFunc }
      })['components']['timeGutterHeader'] = timeGutterHeaderFunc;
    }

    // Technically we don't need to return these functions and set them to the RBC `components` prop
    //     but we are doing it to keep things consistent.
    return {
      event: eventFunc,
      timeGutterWrapper: timeGutterWrapperFunc,
      timeGutterHeader: timeGutterHeaderFunc,
      timeSlotWrapper: timeSlotWrapperFunc,
    };
    // This entire dependency array MUST be here so the component functions update with each one
  }, [currentMeetingDurationMinutes, currentMeetingId, handleDeleteSlot, onOpenMeeting, highlightedSlotId, isPoll,
    slotsSelected, recurringSlots, meetingPreventDoubleBooking, handleEditMeeting, onDeleteMeeting, onDuplicateMeeting,
    onShareMeeting, calendarStep, isHalfHourTzDiffs, numTimezones, useMultiTimezone, secondaryTimezones,
    onCalendarTimezoneSelected, timezone, timezoneShort, onSecondaryTimezoneSelected, secondaryTimezoneTimes]);

  const isPollSlot = React.useMemo(() => {
    const highlightedSlot =  slotsSelected.find(slot => slot.id === highlightedSlotId);
    if (highlightedSlot && "meeting" in highlightedSlot) {
      const meeting = meetings[highlightedSlot.meeting];
      return !!meeting?.is_poll;
    }
    return false;
  }, [highlightedSlotId, slotsSelected, meetings]);

  const selectedSlotInMeeting = React.useMemo(() => {
    const slot = slotsSelected.find(
      slotEvent => slotEvent.id === highlightedSlotId
    );
    return slot && "meeting" in slot && slot.meeting !== currentMeetingId;
  }, [currentMeetingId, highlightedSlotId, slotsSelected]);

  const resources = useMemo(() => (
    calendarView === RBCViews.DAY || showMultiLeaderColumns ? sideBySideMap : undefined
  ), [calendarView, sideBySideMap, showMultiLeaderColumns]);

  const formats: RBCCalendarProps['formats'] = useMemo(() => ({
    eventTimeRangeFormat: () => '',
    timeGutterFormat: (date) => {
      if (isHalfHourTzDiffs.some(h => h === true)) {
        return DateTime.fromJSDate(date).toFormat('h:mm a');
      } else {
        return DateTime.fromJSDate(date).toFormat('h a');
      }
    },
    dayFormat: (date) => {
      const dateTime = DateTime.fromJSDate(date); 
      return calendarView === RBCViews.DAY
        ? dateTime.toFormat('ccc, M/d/yyyy')
        : dateTime.toFormat('ccc d').toUpperCase();
    },
    selectRangeFormat: (range) => {
      return formatSlotTimeTiny(range.start, range.end, getLocalTimeZoneName());
    }
  }), [calendarView, isHalfHourTzDiffs]);

  const multiLeaderColumnViewSelector = `.rbc-time-view-resources .rbc-day-slot:nth-of-type(${numDaysForView}n + 1)`;

  const busyAllDayIndexes = useMemo(() => {
    const currentTime = DateTime.now().setZone(timezone);
    const currentDayStartMillis = currentTime.startOf('day').toMillis();
    const currentDayEndMillis = currentTime.endOf('day').toMillis();

    const busyIndexes = new Set<number>();
    calendarEvents.forEach(e => {
      if (e.allDay && e.busy) {
        // split all day events into separate days (since they could span multiple days)
        const allDayStart = DateTime.fromISO(e.start);
        const allDayEnd = DateTime.fromISO(e.end);
        const totalDays = allDayEnd.diff(allDayStart, 'days');
        lodashRange(0, totalDays.days).forEach(i => {
          const eventStart = allDayStart.plus({ days: i });
          const eventStartMillis = eventStart.toMillis();
          if (eventStartMillis >= currentDateRangeInfo.start.toMillis()
            && eventStartMillis <= currentDateRangeInfo.end.toMillis()
            && (eventStartMillis < currentDayStartMillis || eventStartMillis > currentDayEndMillis)
          ) {
            if (calendarView === RBCViews.WEEK) {
              const adjustedWeekday = eventStart.weekday === 7 ? 1 : eventStart.weekday + 1;
              busyIndexes.add(adjustedWeekday + 1);
            } else if (calendarView === RBCViews.WORK_WEEK) {
              if (eventStart.weekday < 6) {
                busyIndexes.add(eventStart.weekday + 1);
              }
            } else {
              lodashRange(2, sideBySideMap.length + 2).forEach(n => busyIndexes.add(n));
            }
          }
        });
      }
    });

    return busyIndexes;
  }, [calendarEvents, calendarView, timezone,
    currentDateRangeInfo.end, currentDateRangeInfo.start, sideBySideMap.length,
  ]);

  return (
    <Box ref={containerRef} display="flex" flexDirection="column" flex={1} sx={{ 
      transition: 'width 100ms',
      '.rbc-time-header-gutter': {
        width: `${baseTzColWidth * numTimezones}px !important`,
        maxWidth: `${baseTzColWidth * numTimezones}px !important`,
        minWidth: `${baseTzColWidth * numTimezones}px !important`,
      },
      '.rbc-timeslot-group': {
        minHeight: timeSlotGroupHeight
      },

      ...Array.from(busyAllDayIndexes).map(badi => ({
        [`.rbc-day-slot:nth-of-type(n+0):nth-of-type(${badi})`]: {
          background: colors.black100,
        },
        [`.rbc-day-bg:nth-of-type(n+0):nth-of-type(${badi - 1})`]: {
          background: colors.black100,
        },
      })).reduce((a, b) => ({ ...a, ...b }), {}),

      '.rbc-time-view-resources .rbc-time-header-content': {
        // minWidth: '5px',
      },
      '.rbc-button-link': {
        fontWeight: 600,
        fontSize: 12,
        fontFamily: 'Inter', // not sure why this is needed but it fixes the font
      },
      '.rbc-header.rbc-today .rbc-button-link': {
        color: colors.lavendar700,
      },
      '.rbc-header.rbc-today': {
        borderBottom: `2px solid ${colors.lavendar600}`,
      },
      ...(noCalendarBorder ? {
        '.rbc-time-view': {
          borderRadius: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 0,
        }
      } : {}),
      ...(hideResourceHeader ? {
        '.rbc-row-resource': { display: 'none' }
      } : {}),

      ...(showMultiLeaderColumns && sideBySideMap.length > 1 && calendarView !== RBCViews.DAY ? {
        '.rbc-time-view-resources .rbc-day-slot': {
          boxSizing: 'content-box',
        },
        [multiLeaderColumnViewSelector]: {
          borderRight: `8px solid ${colors.black200}`,
        },
        '.rbc-time-header-content:nth-of-type(n+2)': {
          borderRight: `8px solid ${colors.black200} !important`,
        },
        '.rbc-time-view .rbc-allday-cell': {
          boxSizing: 'border-box !important',
        },
        '.time-gutter-column': {
          position: 'sticky',
          left: 0,
          zIndex: 10,
          boxSizing: 'content-box',
        },
        ...(numTimezones === 1 ? { '.rbc-time-gutter': {
          boxSizing: 'content-box',
        } } : {}),
      } : {}),
    }}>

      {!hideCalendarHeader && (
        <Box>
          <Grid container display="flex" alignItems="center" justifyContent={'space-between'}
            margin="0px" paddingBottom={1.5}
          >
            <Grid item xs={isXs ? 12 : 'auto'}
              sx={{ 
                minWidth: isXs ? '100%' : '230px', 
                maxWidth: isXs ? '100%' : '290px',
                marginBottom: isXs ? 1 : 'inherit'
              }}
            >
              <Box display="flex" gap={1}>
                {onSecondaryTimezoneSelected && useMultiTimezone && secondaryTimezones
                && secondaryTimezones.slice().reverse().map((tz, idx) => (
                  <CabTimezoneInput
                    key={idx}
                    size="small"
                    label=""
                    onChange={tzName => {
                      const newTz = allTimeZones.find(zone => zone.name === tzName);
                      if (newTz) {
                        onSecondaryTimezoneSelected(secondaryTimezones.length - idx - 1, newTz);
                      }
                    }}
                    value={tz}
                    shortFormat={useMultiTimezone && (!isXs || numTimezones > 3)}
                    hideArrow
                    sx={isSm || isXs ? {flexGrow: 1} : {width: "60px"}}
                  />
                ))}
                {onCalendarTimezoneSelected && (
                  <CabTimezoneInput
                    size="small"
                    hideArrow={useMultiTimezone}
                    label=""
                    onChange={tzName => {
                      const tz = allTimeZones.find(zone => zone.name === tzName);
                      if (tz) {
                        onCalendarTimezoneSelected(tz);
                      }
                    }}
                    value={timezone}
                    shortFormat={useMultiTimezone && (!isXs || numTimezones > 3)}
                    sx={isSm || isXs || !useMultiTimezone
                      ? {flexGrow: 1} 
                      : {width: !useMultiTimezone ? "unset" : "60px"}
                    }
                  />
                )}
              </Box>
            </Grid>
            <Grid item
              xs={isXs && (!hideTodayButton || !hideViewPicker) ? 6
                : hideTodayButton && hideViewPicker ? 12 : 'auto'}
              sx={{paddingRight: isXs ? '4px' : 'inherit'}}
            >
              <Box display="flex" alignItems="center" justifyContent={"center"} width="100%">
                <PrevNextArrows direction="back" onClick={prev} disabled={allowedDates && !prevAllowedDate} />
                <CabDatePicker
                  size="small"
                  type={calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'week' : 'day'}
                  value={middleOfInterval.toISOString()}
                  onChange={onPickerDateChange}
                  timezone={calendarTimezoneSelected?.name}
                  sx={{
                    maxWidth: 100,
                    width: !isXs ? "100px" : '100%'
                  }}
                  allowedDates={allowedDates}
                />
                <PrevNextArrows direction="forward" onClick={next} disabled={allowedDates && !nextAllowedDate} />
              </Box>
            </Grid>
            {!hideTodayButton && !hideViewPicker && (
              <Grid item
                xs={isXs ? 6 : 'auto'}
                sx={{paddingLeft: isXs ? '4px' : 'inherit'}}
              >
                <Box display="flex">
                  {/* <CabDatePicker
                    type={calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'week' : 'day'}
                    value={middleOfInterval.toISOString()}
                    onChange={onPickerDateChange}
                    timezone={calendarTimezoneSelected?.name}
                    sx={{ maxWidth: { md: '170px' }, width: { xs: '100%' } }}
                  /> */}
                  {!hideTodayButton && (
                    <CabButton
                      sx={{ 
                        border: `1px solid ${colors.black200}`,
                        color: colors.black900,
                        marginRight: 1,
                      }} 
                      buttonType="text"
                      size="small"
                      onClick={e => onPickerDateChange(DateTime.now().toISODate())}
                    >
                      Today
                    </CabButton>
                  )}

                  {!hideViewPicker && (
                    <CabDropdown<RBCView>
                      size="small"
                      value={calendarView}
                      options={[
                        { value: RBCViews.WORK_WEEK, label: 'Work Week' },
                        { value: RBCViews.WEEK, label: 'Week' },
                        { value: RBCViews.DAY, label: 'Day' },
                      ]}
                      onChange={(e) => handleChangeView(e.target.value as RBCView)}
                      sx={{ 
                        minWidth: '124px', 
                        width: isXs ? '100%' : '124px',
                        fontFamily: 'Satoshi',
                      }}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <Box height="100%" sx={{ position: 'relative' }}>
        <DnDCalendar
          style={calendarStyle}
          ref={rbcRef}
          resizable
          selectable={interactive}
          showMultiDayTimes
          getNow={getNow}
          defaultDate={middleOfInterval}
          date={middleOfInterval}
          onNavigate={onNavigate}
          views={views}
          defaultView={RBCViews.WORK_WEEK}
          view={calendarView}
          resources={resources}
          resourceIdAccessor={resourceIdAccessor}
          resourceTitleAccessor={resourceTitleAccessor}
          toolbar={false}
          events={rbcForegroundEvents}
          backgroundEvents={rbcBackgroundEvents}
          formats={formats}
          components={rbcComponents}
          eventPropGetter={eventPropGetter}
          draggableAccessor="editable"
          startAccessor="start"
          endAccessor="end"
          tooltipAccessor={tooltipAccessor}
          localizer={localizer}
          scrollToTime={scrollToTime}
          step={calendarStep}
          timeslots={timeslots}
          onEventDrop={handleEventChange}
          onEventResize={handleEventChange}
          onSelectSlot={onSlotSelected}
          onRangeChange={handleRangeChange}
          onView={handleChangeView}
          onSelectEvent={handleSelectEvent}
          allDayMaxRows={3}
          popup
          dayLayoutAlgorithm={dayLayoutAlgorithm}
        />
      </Box>

      {interactive && (
        <ActionMenu 
          isPoll={isPollSlot}
          passedAnchorEl={eventEl}
          onClose={handleDismissEventPopover}
          passedAnchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          passedTransformOrigin={{
            vertical: selectedSlotInMeeting ? 80 : 40,
            horizontal: 'center',
          }}
          onEdit={highlightedSlotId && !isCurrentMeeting(highlightedSlotId) ? () => {
            handleEditMeeting(highlightedSlotId);
            handleDismissEventPopover();
          }
            : undefined
          }
          onSeeResults={highlightedSlotId && isPollSlot ? () => {
            handleSeeResults(highlightedSlotId);
            handleDismissEventPopover();
          }
            : undefined
          }
          onDelete={onDeleteMeeting && highlightedSlotId ? () => {
            const slot = slotsSelected.find(slotEvent => slotEvent.id === highlightedSlotId);
            onDeleteMeeting((slot && "meeting" in slot && slot.meeting) || -1);
            handleDismissEventPopover();
          } 
            : undefined
          }
          onDuplicate={onDuplicateMeeting && highlightedSlotId ? () => {
            const slot = slotsSelected.find(slotEvent => slotEvent.id === highlightedSlotId);
            onDuplicateMeeting((slot && "meeting" in slot && slot.meeting) || -1);
            handleDismissEventPopover();
          }
            : undefined
          }
          onShare={onShareMeeting && highlightedSlotId ? () => {
            const slot = slotsSelected.find(slotEvent => slotEvent.id === highlightedSlotId);
            onShareMeeting((slot && "meeting" in slot && slot.meeting) || -1);
            handleDismissEventPopover();
          } 
            : undefined
          }
          onCopyLink={onCopyLink && highlightedSlotId ? (() => {
            const slot = slotsSelected.find(slotEvent => slotEvent.id === highlightedSlotId);
            onCopyLink((slot && "meeting" in slot && slot.meeting) || -1);
            handleDismissEventPopover();
          }) : undefined}
        />
      )}
    </Box>
  );
};

interface EventContentWrapperProps {
  slot: MeetingSlot | CombinedMeetingSlot | undefined;
  slotDuration: number;
  slotText: string;
  timeText: string;
  locationText?: string;
  selectedDay: string;
  currentMeetingId: number | undefined;
  title: string;
  meetingDurationMinutes?: number;
  isPoll: boolean;
  isRecurringEvent: boolean;
  deleteSlot?: () => void;
  onEditMeeting?: () => void;
  isHighlighted: boolean;
  leaderBorders: LeaderBorders;
  leaderInfo?: Leader[];
  calendarInfo?: Calendar[];
  attendees?: EventAttendee[];
  busy?: boolean;
  allDayConflictDates: AllDayEvent[];
  bufferStartMinutes?: number;
  bufferEndMinutes?: number;
  meetingPreventDoubleBooking?: boolean;
  handleEditMeeting?: (slotId: number, meetingId?: number) => void;
  handleDeleteMeeting?: (meetingId: number) => void;
  handleDuplicateMeeting?: (meetingId: number) => void;
  handleShareMeeting?: (meetingId: number) => void;
  calendarStep: number;
}

const EventContentWrapper = ({
  slot, slotDuration, slotText, timeText, locationText, selectedDay, currentMeetingId, title, deleteSlot, isPoll, 
  meetingDurationMinutes, isHighlighted, leaderBorders, leaderInfo, calendarInfo, attendees, busy, allDayConflictDates,
  bufferStartMinutes, bufferEndMinutes, meetingPreventDoubleBooking, handleEditMeeting, handleDeleteMeeting, 
  handleDuplicateMeeting, handleShareMeeting, isRecurringEvent, calendarStep
}: EventContentWrapperProps) => {

  const [showEventDetails, setShowEventDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isRecurring = slot && "isRecurring" in slot && slot.isRecurring;
  const isExclude = slot && "is_exclude" in slot && slot.is_exclude;
  const isNewMeetingSlot = slot && "meeting" in slot && (slot?.meeting === currentMeetingId || slot?.id < 0);

  const handleEventClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (slot?.id === undefined || slot?.id > 0) {
      setShowEventDetails(true);
      setAnchorEl(e.currentTarget);
    }
  };

  let showDurationWarning = false;
  if (
    slot && "meeting" in slot && (slot?.meeting === currentMeetingId || slot?.id < 0)
    && meetingDurationMinutes != null
  ) {
    showDurationWarning = (!!currentMeetingId || isPoll || slot?.id < 0) 
    && slotDuration < meetingDurationMinutes;
  }

  let showConflictWarning = false;
  if (allDayConflictDates.length === 0) {
    showConflictWarning = false;
  } else if (isNewMeetingSlot && meetingPreventDoubleBooking != null) {
    if ((!!currentMeetingId || isPoll || slot?.id < 0) && meetingPreventDoubleBooking) {
      allDayConflictDates.forEach(event => {
        if ((event.start.getDate() <= DateTime.fromISO(slot.start_date).toJSDate().getDate()) && 
      (event.end.getDate() >= DateTime.fromISO(slot.end_date).toJSDate().getDate())) {
          showConflictWarning = true;
        }
      });
    }
  }
  const isSlotShort = slotDuration < calendarStep * 2;
  const maxTitleLines = Math.max(Math.floor(slotDuration / calendarStep) - 1, 1);
  // The 1px difference seems like it wouldn't do much, but it helps space the time out
  //    much better for 1-line events
  const maxTitlePx = maxTitleLines > 1 ? 4 + maxTitleLines * 12 : 15;

  let statusText = "";

  if (slot) {
    statusText = "Planning";
  } else if (leaderBorders.length === 1) {
    statusText = getStatusText(leaderBorders[0].status);
  } else {
    const statusCounts: {[key: string]: number} = {};
    leaderBorders.forEach(border => {
      const text = getStatusText(border.status);
      if (statusCounts[text]) {
        statusCounts[text]++;
      } else {
        statusCounts[text] = 1;
      }
    });
    // Ideally we would order specifically (e.g. "Accepted" first) but not doing that
    //    to keep this function lean
    statusText = Object.keys(statusCounts).map(t => `${t} (${statusCounts[t]})`).join(", ");
  }

  const handleDeleteSlot = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (deleteSlot) {
      e.stopPropagation();
      deleteSlot();
    }
  };
  
  return (
    <NoWhiteSpace>
      <Box 
        display="flex"
        flexDirection="row"
        onClick={handleEventClick}
        sx={{
          height: "100%", 
          backgroundColor: isRecurring ? 'rgb(203, 220, 239)' : undefined,
          // border: showConflictWarning ? '1px solid #eba083' : undefined
          // backgroundImage: slot?.isRecurring ? 
          //   `repeating-linear-gradient(45deg, white 0, white 10px, rgb(203, 220, 239) 0px, rgb(203, 220, 239) 16px)` 
          //   : undefined
        }}
      >
        {leaderBorders.map((border, idx) => <Box
          key={idx}
          sx={{
            backgroundColor: isExclude ? colors.black200 : border.color,
            width: 4,
            minWidth: 4,
            maxWidth: 4,
            height: "100%",
            ...((border.status === "tentative" || border.status === "needsAction") && {
              backgroundImage: 
                `repeating-linear-gradient(45deg, white 0, white 3px, ${border.color} 3px, ${border.color} 6px)`,
            }),            
            ...(idx === 0 ? {borderTopLeftRadius: 5, borderBottomLeftRadius: 5} : {})
          }} 
        />)}
        <CabTooltip 
          title={<>
            <Box>{title}</Box>
            <Box sx={{fontSize: 12}}>{timeText} {isRecurringEvent ? '(Recurring)' : ''}</Box>
            <Box sx={{fontSize: 12}}>Status: {statusText}</Box>
          </>} 
          placement={'top'}
          enterDelay={1000}
          enterNextDelay={100}
          arrow 
          open={(isHighlighted || !title) ? false : undefined}
          sx={{paddingLeft: '4px'}}
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '14px'
              }
            }
          }}
        >
          <EventTitleText variant="subtitle2" isSlotShort={isSlotShort} 
            sx={{maxHeight: `${maxTitlePx}px`}}
          >
            {slotText}
          </EventTitleText>
          <EventTimeText sx={{
            display: isSlotShort ? "inline" : "block",
            marginLeft: isSlotShort ? "4px" : "0",
            fontWeight: isRecurring || isExclude ? 500 : undefined
          }}>{timeText}</EventTimeText>
        </CabTooltip>
        {(isNewMeetingSlot && slot.editable && showConflictWarning) && (
          <CabTooltip
            title={<Box component="span" sx={{ fontSize: 14 }}>
              This slot conflicts with an all-day event and will not be offered to participants.
            </Box>}
            placement="top"
          >
            <Box component="span" sx={{
              position: 'absolute',
              bottom: slotDuration <= 15 ? -4 : 0,
              right: slotDuration <= 15 ? -4 : 0,
              zIndex: 10,
            }} >
              <CabIcon Icon={IoWarning} color="orange" alt='' sx={{
                position: 'relative',
                fontSize: 14,
                pointerEvents: 'none',
              }} />
            </Box>
          </CabTooltip>
        )}
        {(isNewMeetingSlot && slot.editable && showDurationWarning) && (
          <CabTooltip
            title={<Box component="span" sx={{ fontSize: 14 }}>
              Time slot is shorter than the meeting duration
            </Box>}
            placement="top"
          >
            <Box component="span" sx={{
              position: 'absolute',
              bottom: slotDuration <= 15 ? -4 : 0,
              right: slotDuration <= 15 ? -4 : 0,
              zIndex: 10,
            }} >
              <CabIcon Icon={IoWarning} color="orange" alt='' sx={{
                position: 'relative',
                fontSize: 14,
                pointerEvents: 'none',
              }} />
            </Box>
          </CabTooltip>
        )}
      </Box>
      {isNewMeetingSlot && slot.editable && !isExclude && (
        <ButtonBase
          title={'Remove slot'}
          sx={{
            position: 'absolute',
            left: -14,
            top: -12,
            color: colors.coralLight,
            padding: 0,
            backgroundColor: 'transparent',
            fontSize: 20,
            boxShadow: 'none',
            borderRadius: '50%',
            zIndex: 10
          }}
          onClick={(e) => handleDeleteSlot(e)}
        >
          <Box component="span" sx={{
            backgroundColor: colors.navyDark,
            position: 'absolute',
            top: 5,
            left: 5,
            width: 10,
            height: 10,
            borderRadius: '50%',
          }}></Box>
          <CabIcon
            Icon={IoCloseCircle}
            alt='Remove'
            sx={{ padding: '-4px', pointerEvents: 'none', zIndex: 1 }}
          />
        </ButtonBase>
      )}
      {isNewMeetingSlot && slot.editable && isExclude && !isRecurring && (
        <Box
          title={'Excluded slot'}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: colors.navyDark,
            padding: 0,
            backgroundColor: 'transparent',
            fontSize: 20,
            boxShadow: 'none',
            borderRadius: '50%',
          }}
        >
          <CabIcon
            Icon={IoBanOutline}
            alt='Exclude slot'
          />
        </Box>
      )}

      {isNewMeetingSlot && isRecurring && (
        <Box
          title={'Recurring slot'}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: colors.navyDark,
            padding: 0,
            backgroundColor: 'transparent',
            fontSize: 20,
            boxShadow: 'none',
            borderRadius: '50%',
          }}
        >
          <CabIcon
            Icon={IoRepeatOutline}
            alt='Recurring slot'
          />
        </Box>
      )}
      
      <EventDetailPopover
        isRecurringEvent={isRecurringEvent}
        open={showEventDetails}
        onClosePopover={() => setShowEventDetails(false)}
        popoverAnchorEl={anchorEl}
        title={title}
        locationText={locationText}
        timeText={timeText}
        selectedDate={selectedDay}
        leaderInfo={leaderInfo}
        calendarInfo={calendarInfo}
        attendees={attendees}
        busy={busy}
        bufferStartMinutes={bufferStartMinutes}
        bufferEndMinutes={bufferEndMinutes}
        containedSlots={slot?.containedSlots}
        onEditMeeting={handleEditMeeting}
        onDeleteMeeting={handleDeleteMeeting}
        onDuplicateMeeting={handleDuplicateMeeting}
        onShareMeeting={handleShareMeeting}
      />
      
    </NoWhiteSpace>
  );
};

interface EventComponentProps {
  args: React.PropsWithChildren<EventProps<RBCEvent>>;
  currentMeetingDurationMinutes?: number;
  currentMeetingId?: number;
  handleDeleteSlot?: (slotId: number) => void;
  handleOpenMeeting?: (meetindId: number) => void;
  highlightedSlotId: number | null;
  isPoll: boolean;
  isRecurringEvent: boolean;
  slotsSelected: Array<MeetingSlot | CombinedMeetingSlot>;
  currentAllDayEventDates: AllDayEvent[];
  meetingPreventDoubleBooking?: boolean;
  handleEditMeeting?: (slotId: number, meetingId?: number) => void;
  handleDeleteMeeting?: (meetingId: number) => void;
  handleDuplicateMeeting?: (meetingId: number) => void;
  handleShareMeeting?: (meetingId: number) => void;
  calendarStep: number;
}

interface AllDayEvent {
  start: Date;
  end: Date;
}

const statusMap: {[status in AttendeeResponseStatus]: string} = {
  accepted: "Accepted",
  declined: "Declined",
  needsAction: "No Response",
  tentative: "Tentative",
};

const getStatusText = (status?: AttendeeResponseStatus) => {
  return status ? statusMap[status] ?? "Accepted" : "Accepted";
};

const EventComponent = ({
  args, currentMeetingDurationMinutes, currentMeetingId, handleDeleteSlot, isRecurringEvent,
  handleOpenMeeting, highlightedSlotId, isPoll, slotsSelected, currentAllDayEventDates, meetingPreventDoubleBooking,
  handleEditMeeting, handleDeleteMeeting, handleDuplicateMeeting, handleShareMeeting, calendarStep
}: EventComponentProps) => {
  const allDay = args.isAllDay;
  const title = args.title;
  const locationText = args.event.location;
  const startDate = args.event.start;
  const endDate = args.event.end;
  const leaderBorders = args.event.leaderBorders;
  const leaderInfo = args.event.leaderInfo;
  const calendarInfo = args.event.calendarInfo;
  const attendees = args.event.attendees;
  const busy = args.event.busy;
  const allDayEvents = [...currentAllDayEventDates];
  const bufferStartMinutes = args.event.bufferStartMinutes;
  const bufferEndMinutes = args.event.bufferEndMinutes;

  let timeText = '';
  if (allDay && startDate && endDate && busy) {
    const newAllDayEvent: AllDayEvent = {
      start: startDate,
      end: endDate
    };
    allDayEvents.push(newAllDayEvent);
  }
  if (!allDay && startDate && endDate) {
    timeText = formatSlotTimeTiny(startDate, endDate, getLocalTimeZoneName());
  }
  const slotText = title;

  const slot = slotsSelected.find(slotEvent => slotEvent.id === Number(args.event.id) 
  || `generated ${slotEvent.id.toString()}` === args.event.id);
  const isHighlighted = !!(highlightedSlotId && highlightedSlotId === slot?.id);
  const slotDuration = slot ? getSlotDuration(slot) : getEventDuration(args.event);
  const selectedDay = startDate ? DateTime.fromJSDate(startDate).toFormat('cccc, MMMM d') : '';

  const deleteSlot = handleDeleteSlot && slot ? () => handleDeleteSlot(slot.id) : undefined;
  const editMeeting = handleOpenMeeting && slot && "meeting" in slot && slot?.meeting
    ? () => handleOpenMeeting(slot.meeting)
    : undefined;

  return <EventContentWrapper
    key={`${args.event.id}${currentMeetingId}`}
    title={title}
    slot={slot}
    slotDuration={slotDuration}
    allDayConflictDates={allDayEvents}
    slotText={slotText}
    timeText={timeText}
    locationText={locationText}
    selectedDay={selectedDay}
    isPoll={isPoll}
    isRecurringEvent={isRecurringEvent}
    currentMeetingId={currentMeetingId}
    meetingDurationMinutes={currentMeetingDurationMinutes}
    deleteSlot={deleteSlot}
    onEditMeeting={editMeeting} 
    leaderBorders={leaderBorders}
    isHighlighted={isHighlighted}
    leaderInfo={leaderInfo}
    calendarInfo={calendarInfo}
    attendees={attendees}
    busy={busy}
    meetingPreventDoubleBooking={meetingPreventDoubleBooking}
    bufferStartMinutes={bufferStartMinutes}
    bufferEndMinutes={bufferEndMinutes}
    handleEditMeeting={handleEditMeeting}
    handleDeleteMeeting={handleDeleteMeeting}
    handleDuplicateMeeting={handleDuplicateMeeting}
    handleShareMeeting={handleShareMeeting}
    calendarStep={calendarStep}
  />;
};

const EventTitleText = styled(
  Typography, { label: "EventTitleText", shouldForwardProp: (propName) => propName !== 'isSlotShort' },
)<{isSlotShort: boolean}>(({theme, isSlotShort}) => ({
  fontSize: 12,
  paddingTop: isSlotShort ? 0 : "3px",
  display: isSlotShort ? 'inline' : 'inherit',
  lineHeight: "12px",
  overflow: 'hidden',
  //maxHeight: 'calc(100% - 16px)',
  whiteSpace: isSlotShort ? 'inherit' : 'normal',
  color: 'unset',
  width: '100%'
}));

const EventTimeText = styled(
  Typography, {label: "EventTimeText"}
)(({theme}) => ({
  fontSize: 12,
  lineHeight: 1.3,
  overflow: 'hidden',
  fontWeight: 300,
  color: 'unset'
}));

export default memo(Scheduler, (prev, next) => {
  return Object.keys(prev).every((k) => prev[k as keyof CalendarProps] === next[k as keyof CalendarProps])
    && JSON.stringify(prev.slotsSelected) === JSON.stringify(next.slotsSelected);
}); 