import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PROVIDER_BY_NAME } from "../../../constants";
import { RootState, ThunkDispatchType, actions, Leader, Calendar } from "../../../store";
import { checkForGoogleGrant, checkForMicrosoftGrant } from "../../../utils/authUtils";
import AdditionalCalendars from "./AdditionalCalendarsModal";
import { additionalCalendarSelectionUpdated, selectSelectedAdditionalCalendars } from "../../../store/scheduleUI";


type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSelectLeaders: (leaders: number[]) => void;
  selectedLeaders: number[];
};

const AdditionalCalendarsContainer = ({
  isOpen, onCancel, onSelectLeaders, selectedLeaders
}: Props) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const selectedAdditionalCalendars = useSelector(selectSelectedAdditionalCalendars);

  const dispatch = useDispatch<ThunkDispatchType>();
  const [loading, setLoading] = useState(false);

  const handleUpdateAdditionalCalendars = useCallback((additionalCalendarAccessIds: number[]) => {
    dispatch(additionalCalendarSelectionUpdated(additionalCalendarAccessIds.map(cal => ({
      calendarAccessId: cal,
      selected: true
    }))));
    onCancel();
  }, [dispatch, onCancel]);

  const processedCalendars = useMemo(() => {
    return calendars
      .filter((cal): cal is Calendar & { calendar_access_id: number } => cal.calendar_access_id != null)
      .map(cal => {
        return {
          id: cal.id,
          calendar_access_id: cal.calendar_access_id,
          title: cal?.additionalCalendarEmail?.name || cal.summary || cal.owner_email || '',
          subtitle: cal.owner_email || '',
          editable: !!cal?.additionalCalendarEmail,
          leaders: cal?.leaders.map(lId => leaders.find(ldr => ldr.id === lId))
            .filter(ldr => ldr) as Leader[] | undefined,
        };
      });
  }, [calendars, leaders]);

  const handleFetchRemoteCalendars = useCallback(async () => {
    if (isOpen) {
      setLoading(true);
      await dispatch(actions.schedule.fetchRemoteCalendars());
      setLoading(false);
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    handleFetchRemoteCalendars();
  }, [handleFetchRemoteCalendars]);

  const handleAddCalendar = async (email: string, name: string) => {
    if (user?.oauth_grant_details) {
      const hasGoogle = checkForGoogleGrant(user?.oauth_grant_details);
      const hasMicrosoft = checkForMicrosoftGrant(user?.oauth_grant_details);
      const providerId = (hasGoogle && hasMicrosoft) ? undefined
        : hasGoogle ? PROVIDER_BY_NAME['google'].id
          : hasMicrosoft ? PROVIDER_BY_NAME['microsoft'].id
            : undefined;
      const res = await dispatch(actions.schedule.addAdditionalCalendar(email, name, providerId));
      return res?.calendar.id;
    }
  };

  const handleUpdateCalendar = async (id: number, name: string) => {
    await dispatch(actions.schedule.updateAdditionalCalendar(id, name));
  };

  const handleSelectLeaders = (newLeaders: number[]) => {
    onSelectLeaders([...new Set([...newLeaders, ...selectedLeaders])]);
  };

  return <AdditionalCalendars
    isOpen={isOpen}
    onDone={handleUpdateAdditionalCalendars}
    onCancel={onCancel}
    calendars={processedCalendars}
    defaultSelectedCalendars={selectedAdditionalCalendars.map(cal => cal.calendar_access_id)}
    onAddCalendar={handleAddCalendar}
    onUpdateCalendar={handleUpdateCalendar}
    onSelectLeaders={handleSelectLeaders}
    loading={loading}
  />;
};

export default AdditionalCalendarsContainer;
