import { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Meeting, RootState, ThunkDispatchType, Leader, GlobalModalComponentName } from "../../../store";
import { getLeadersForScheduling } from "../../../utils/leaderUtils";
import Header from "./Header";
import { TimeZone } from "../../../utils/scheduleUtils";
import { DateTime } from "luxon";
import {
  showAllDayEventsUpdated, showAllRecurringSlotsUpdated, showPendingSlotsUpdated, showCanceledDeclinedMeetingsUpdated,
  condensePendingSlotsUpdated, showMultiLeaderColumnsUpdated,
  calendarViewUpdated,
} from "../../../store/scheduleUI";
import { useHandler } from "../../../store/hooks";


export interface ScheduleHeaderProps {
  currentMeeting: Meeting|null;
  selectedLeaders: number[];
  creatingMeeting: boolean;
  onCancel: () => void;
  onLeaderSelect: (ids: number[]) => void;
  opencalendarsModal: () => void;
  onCreateMeeting: () => void;
  onCreateReusableMeeting: () => void;
  onCreatePoll: () => void;
  calendarTimezoneSelected: TimeZone | undefined;
  onDateChange: (start: DateTime, end: DateTime) => void;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  allowedDates?: DateTime[];
  onToggleSidePanel: () => void;
}


const ScheduleHeaderContainer = ({
  selectedLeaders, onLeaderSelect, opencalendarsModal, onCancel, creatingMeeting, currentMeeting,
  onCreateMeeting, onCreateReusableMeeting, onCreatePoll, calendarTimezoneSelected, onDateChange,
  currentDateRangeInfo, allowedDates, onToggleSidePanel
}: ScheduleHeaderProps): ReactElement | null => {
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const showAllRecurringSlots = useSelector((state: RootState) => state.scheduleUI.showAllRecurringSlots);
  const showCanceledDeclinedMeetings = useSelector((state: RootState) => state.scheduleUI.showCanceledDeclinedMeetings);
  const showPendingSlots = useSelector((state: RootState) => state.scheduleUI.showPendingSlots);
  const condensePendingSlots = useSelector((state: RootState) => state.scheduleUI.condensePendingSlots);
  const showAllDayEvents = useSelector((state: RootState) => state.scheduleUI.showAllDayEvents);
  const showMultiLeaderColumns = useSelector((state: RootState) => state.scheduleUI.showMultiLeaderColumns);
  const calendarView = useSelector((state: RootState) => state.scheduleUI.calendarView);

  const dispatch = useDispatch<ThunkDispatchType>();
  const handle = useHandler();

  const openUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const handleUpdateLeaderEmails = async (updateLeaders: Leader[]) => {
    const leaderUpdatePromises: Promise<Leader | undefined>[] = [];
    updateLeaders.forEach(l => {
      const leaderUpdate = {id: l.id, email: l.email};
      leaderUpdatePromises.push(dispatch(actions.leaders.updateLeader(leaderUpdate, null)));
    });
    await Promise.resolve(leaderUpdatePromises);
  };

  const leadersForScheduling = getLeadersForScheduling(leaders.leaders);

  const showSecondaryTimezone = user?.features.MULTI_TIMEZONE && schedulingPrefs.user_prefs?.multi_timezone;

  const handleToggleMultiTimezone = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        multi_timezone: !schedulingPrefs.user_prefs?.multi_timezone,
      }));
    }
  };

  const handleToggleGranularTimeSelection = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        granular_time_selection: !schedulingPrefs.user_prefs?.granular_time_selection,
      }));
    }
  };

  const handleToggleCanceledDeclinedMeetings = useCallback(() => {
    dispatch(showCanceledDeclinedMeetingsUpdated(!showCanceledDeclinedMeetings));
  }, [dispatch, showCanceledDeclinedMeetings]);

  const handleToggleShowAllRecurringSlots = useCallback(() => {
    dispatch(showAllRecurringSlotsUpdated(!showAllRecurringSlots));
  }, [dispatch, showAllRecurringSlots]);

  const handleToggleShowPendingSlots = useCallback(() => {
    dispatch(showPendingSlotsUpdated(!showPendingSlots));
  }, [dispatch, showPendingSlots]);

  const handleToggleCondensePendingSlots = useCallback(() => {
    dispatch(condensePendingSlotsUpdated(!condensePendingSlots));
  }, [dispatch, condensePendingSlots]);

  const handleToggleAllDayEvents = useCallback(() => {
    dispatch(showAllDayEventsUpdated(!showAllDayEvents));
  }, [dispatch, showAllDayEvents]);

  const handleToggleMultiLeaderColumns = useCallback(() => {
    dispatch(showMultiLeaderColumnsUpdated(!showMultiLeaderColumns));
  }, [dispatch, showMultiLeaderColumns]);

  if (!user) return null;

  return (
    <Header
      user={user}
      selectedLeaders={selectedLeaders}
      onUpdateLeaderEmails={handleUpdateLeaderEmails}
      leadersForScheduling={leadersForScheduling}
      calendars={calendars}
      creatingMeeting={creatingMeeting}
      currentMeetingId={currentMeeting?.id}
      onCancel={onCancel}
      onLeaderSelect={onLeaderSelect}
      execPickerDisabled={!!currentMeeting && currentMeeting.create_user?.id !== user.id}
      opencalendarsModal={opencalendarsModal}
      onCreateMeeting={onCreateMeeting}
      onCreateReusableMeeting={onCreateReusableMeeting}
      onCreatePoll={onCreatePoll}
      calendarTimezoneSelected={calendarTimezoneSelected}
      onDateChange={onDateChange}
      currentDateRangeInfo={currentDateRangeInfo}
      allowedDates={allowedDates}
      onToggleSidePanel={onToggleSidePanel}
      onToggleMultiTimezone={handleToggleMultiTimezone}
      onToggleGranularTimeSelection={handleToggleGranularTimeSelection}
      onToggleCanceledDeclinedMeetings={handleToggleCanceledDeclinedMeetings}
      hasMultiLeaderCalendarView={user?.features.MULTI_LEADER_CALENDAR_VIEW}
      hasMultiTimezone={user?.features.MULTI_TIMEZONE}
      granularTimeSelection={schedulingPrefs.user_prefs?.granular_time_selection || false}
      openUpgradeModal={openUpgradeModal}
      showCanceledDeclinedMeetings={showCanceledDeclinedMeetings}
      showSecondaryTimezone={showSecondaryTimezone}
      showAllRecurringSlots={showAllRecurringSlots}
      onToggleShowPendingSlots={handleToggleShowPendingSlots}
      onToggleCondensePendingSlots={handleToggleCondensePendingSlots}
      onToggleAllDayEvents={handleToggleAllDayEvents}
      onToggleMultiLeaderColumns={handleToggleMultiLeaderColumns}
      onToggleShowAllRecurringSlots={handleToggleShowAllRecurringSlots}
      calendarView={calendarView}
      onCalendarViewChange={handle(calendarViewUpdated)}
    />
  );
};

export default ScheduleHeaderContainer;