import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  additionalCalendarSelectionUpdated, calendarTimezoneUpdated, leaderSelectionUpdated, secondaryTimezonesUpdated,
  selectCalendarAccessToCalendarMap, selectCurrentOrNewMeeting
} from ".";
import { RootState, ThunkDispatchType } from "..";
import { difference, isEqual, uniqBy } from "lodash-es";
import { calendarToParticipant, getLocalTimeZone, getTimeZone, NY_TZ } from "../../utils/scheduleUtils";
import {
  createMeetingParticipant, deleteMeetingParticipant, deleteNewMeeting, updateMeeting, updateNewMeeting
} from "../schedule/actions";


// GUIDELINES:
// - Trigger effects in response to specific action(s) for simplicity and predictability. You may additionally
// check for a certain state condition if necessary.
// - If it simplifies overall logic, you may also trigger effects in response to state changes alone, but this should
// be less common.


export const scheduleListenerMiddleware = createListenerMiddleware();

export const startAppListening = scheduleListenerMiddleware.startListening.withTypes<
  RootState,
  ThunkDispatchType
>();

// run appropriate effects when current meeting is set or unset
startAppListening({
  // actionCreator: currentMeetingSet,
  predicate: (action, state, previousState) => {
    return previousState.scheduleUI.currentMeetingId !== state.scheduleUI.currentMeetingId;
  },
  effect: (action, { getState, dispatch }) => {
    const currentMeetingId = getState().scheduleUI.currentMeetingId;
    if (currentMeetingId != null) {
      const meeting = getState().schedule.meetings[currentMeetingId];
      if (meeting) {
        dispatch(deleteNewMeeting());

        const toDeselect = getState().scheduleUI.selectedLeaderIds.map(lId => ({ leaderId: lId, selected: false }));
        const toSelect = meeting.leaders.map(lId => ({ leaderId: lId, selected: true }));
        const allLeaders = uniqBy([...toSelect, ...toDeselect], ({ leaderId }) => leaderId);
        dispatch(leaderSelectionUpdated(allLeaders));

        const calsToDeselect = getState().scheduleUI.selectedAdditionalCalendarAccessIds.map(
          calendarAccessId => ({ calendarAccessId, selected: false })
        );
        const calsToSelect = Object.values(meeting.participants || {})
          .filter((participantItr): participantItr is typeof participantItr & { calendar_access: number } => 
            !!participantItr.calendar_access && participantItr.view_calendar
          )
          .map(participantItr => ({ calendarAccessId: participantItr.calendar_access, selected: true }));
        const allCalendars = uniqBy([...calsToSelect, ...calsToDeselect], ({ calendarAccessId }) => calendarAccessId);
        dispatch(additionalCalendarSelectionUpdated(allCalendars));

        const userPrefs = getState().schedule.schedulingPrefs.user_prefs;

        const fallbackTz = (userPrefs?.default_calendar_timezone && getTimeZone(userPrefs.default_calendar_timezone))
          || getTimeZone(getState().scheduleUI.timezoneName)
          || getLocalTimeZone()
          || NY_TZ;

        const currentMeetingTimezone = meeting.calendar_tz
          ? getTimeZone(meeting.calendar_tz) || fallbackTz
          : fallbackTz;

        if (currentMeetingTimezone && currentMeetingTimezone.name !== getState().scheduleUI.timezoneName) {
          dispatch(calendarTimezoneUpdated(currentMeetingTimezone.name));
        }

        // secondary timezones should be set to the union of secondary meeting and pref timezones
        const mtgSecondaryTzSet = new Set((meeting.secondary_tz || []).filter(tz => tz != null).map(tzName => tzName));
        const secondaryTzSet = new Set(getState().scheduleUI.secondaryTimezoneNames);
        if (!isEqual(mtgSecondaryTzSet, secondaryTzSet)) {
          const defaultSecondaryTzSet = new Set(userPrefs?.default_secondary_timezone?.filter(tz => tz != null) || []);
          const newSecondaryTzs = Array.from(new Set([...mtgSecondaryTzSet, ...defaultSecondaryTzSet]))
            .filter(tzName => !!getTimeZone(tzName));
          dispatch(secondaryTimezonesUpdated(newSecondaryTzs));
        }
      }
    } else {
      const userPrefs = getState().schedule.schedulingPrefs.user_prefs;

      dispatch(calendarTimezoneUpdated(userPrefs?.default_calendar_timezone || getLocalTimeZone()?.name || NY_TZ.name));
      dispatch(secondaryTimezonesUpdated((userPrefs?.default_secondary_timezone || [])
        .filter(tz => tz != null)
        .map(tz => (tz ? getTimeZone(tz ) || getLocalTimeZone() : getLocalTimeZone())?.name || NY_TZ.name)
      ));
    }
  },
});


// sync current meeting timezones to selected timezones
startAppListening({
  predicate: (action, state) => {
    if (action.type !== calendarTimezoneUpdated.type && action.type !== secondaryTimezonesUpdated.type) return false;

    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;

    return currentOrNewMeeting.calendar_tz !== state.scheduleUI.timezoneName
      || !isEqual(new Set(currentOrNewMeeting.secondary_tz), new Set(state.scheduleUI.secondaryTimezoneNames));
  },
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);

    if (currentOrNewMeeting) {
      listenerApi.dispatch(updateMeeting({
        id: currentOrNewMeeting.id,
        calendar_tz: state.scheduleUI.timezoneName,
      }, []));

      const secondaryTzSet = new Set(state.scheduleUI.secondaryTimezoneNames);
      const mtgSecondaryTzSet = new Set((currentOrNewMeeting.secondary_tz || []).filter(tz => tz != null));
      if (!isEqual(mtgSecondaryTzSet, secondaryTzSet)) {
        listenerApi.dispatch(updateMeeting({
          id: currentOrNewMeeting.id,
          secondary_tz: Array.from(secondaryTzSet),
        }, []));
      }
    }
  },
});


// sync current meeting leaders to selected leaders
startAppListening({
  predicate: (action, state) => {
    if (action.type !== leaderSelectionUpdated.type) return false;

    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;

    return !isEqual(new Set(currentOrNewMeeting.leaders), new Set(state.scheduleUI.selectedLeaderIds));
  },
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    const selectedLeaderIds = state.scheduleUI.selectedLeaderIds;

    if (currentOrNewMeeting?.id != null) {
      if (currentOrNewMeeting.id < 0) {
        // Update new meeting
        listenerApi.dispatch(updateNewMeeting({
          ...currentOrNewMeeting,
          leaders: selectedLeaderIds
        }));
      } else {
        // Update existing meeting
        listenerApi.dispatch(updateMeeting({
          id: currentOrNewMeeting.id,
          leaders: selectedLeaderIds
        }, []));
      }
    }
  },
});

// sync additional calendars to current meeting
startAppListening({
  predicate: (action, state) => {
    if (action.type === additionalCalendarSelectionUpdated.type) return false;

    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;

    const meetingAdditionalCalendarAccessIds = Object.values(currentOrNewMeeting.participants || {})
      .filter(part => !!part.calendar_access)
      .map(part => part.calendar_access);

    return !isEqual(
      new Set(state.scheduleUI.selectedAdditionalCalendarAccessIds),
      new Set(meetingAdditionalCalendarAccessIds)
    );
  },
  effect: async (action, { getState, dispatch }) => {
    const state = getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);

    dispatch(additionalCalendarSelectionUpdated(
      Object.values(currentOrNewMeeting?.participants || {}).filter(
        p => !!p.calendar_access && p.view_calendar
      ).map(p => ({ calendarAccessId: p.calendar_access as number, selected: true }))
    ));
  },
});

// sync current meeting to additional calendars
startAppListening({
  predicate: (action, state) => {
    if (action.type !== additionalCalendarSelectionUpdated.type) return false;

    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;

    const meetingAdditionalCalendarAccessIds = Object.values(currentOrNewMeeting.participants || {})
      .filter(part => !!part.calendar_access)
      .map(part => part.calendar_access);

    return !isEqual(
      new Set(state.scheduleUI.selectedAdditionalCalendarAccessIds),
      new Set(meetingAdditionalCalendarAccessIds)
    );
  },
  effect: async (action, { getState, dispatch }) => {
    const state = getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    const calendarMap = selectCalendarAccessToCalendarMap(state);

    if (currentOrNewMeeting?.id != null) {
      const participants = Object.fromEntries(
        state.scheduleUI.selectedAdditionalCalendarAccessIds
          .map((calendarAccessPk) => calendarMap[calendarAccessPk])
          .filter(cal => cal)
          .map((cal, idx) => {
            const assignedId = (idx + 1) * -1;
            return [assignedId, calendarToParticipant(currentOrNewMeeting.id || -1, cal, assignedId)];
          })
      );

      if (currentOrNewMeeting.id < 0) {
        const nonCalendarParticipants = Object.fromEntries(
          Object.values(currentOrNewMeeting.participants || {})
            .filter(partItr => partItr.calendar_access === null)
            .map(partItr => [partItr.id, partItr])
        );
        dispatch(updateNewMeeting({
          ...currentOrNewMeeting,
          participants: {...nonCalendarParticipants, ...participants}
        }));
      } else {
        let createPromises: Promise<void>[] = [];
        const attachedCalendars = Object.values(currentOrNewMeeting.participants || {})
          .filter(part => !!part.calendar_access)
          .map(participant => participant.calendar_access);
          
        const newCalendars = Object.values(participants)
          .filter(participant => !attachedCalendars.includes(participant.calendar_access));
          
        const removeCalendars = difference(attachedCalendars, state.scheduleUI.selectedAdditionalCalendarAccessIds);
        
        // Create new calendar participants
        for (const participant of newCalendars) {
          createPromises.push(dispatch(createMeetingParticipant(participant)));
          if (createPromises.length === 5) {
            await Promise.all(createPromises);
            createPromises = [];
          }
        }
        await Promise.all(createPromises);

        // Remove old calendar participants
        const removePromises: Promise<void>[] = [];
        const removeIds = Object.values(currentOrNewMeeting.participants || {})
          .filter((participant) => removeCalendars.includes(participant.calendar_access))
          .map(participant => participant.id);

        for (const removeId of removeIds) {
          removePromises.push(
            dispatch(deleteMeetingParticipant(
              removeId, 
              currentOrNewMeeting.id || -1,
              !!currentOrNewMeeting.id
            ))
          );
          if (removePromises.length === 5) {
            await Promise.all(removePromises);
          }
        }
        await Promise.all(removePromises);
      }
    }
  },
});
