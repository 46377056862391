import { memo, ReactElement, useState } from 'react'; 
import { Box } from '@mui/material';
import { CAB_PANEL_WIDTH } from '@CabComponents/CabPanel';
import { PAGE_URL } from '../../constants';
import {
  MeetingSlot, MeetingHoldEventErrorResolution, Meeting, Calendar, InvalidParticipantVotes, Leader,
  MeetingHoldEventError, NewMeeting,
} from '../../store';
import MeetingSettingsContainer from '../../components/Schedule/MeetingSettings';
import CabinetPage from '../../components/Common/CabinetPage';
import { CabinetModal } from '../../components/Common/CabinetModal';
import MeetingErrors from '../../components/Schedule/MeetingErrors';
import Header from './Header';
import CalendarSchedulerContainer from '../../components/Schedule/CalendarScheduler';
import ScheduleShareModalContainer from '../../components/Schedule/ScheduleShareModal';
import colors from '../../colors';
import DuplicateMeetingModal, {
  DuplicateMeetingSubmitProps
} from '../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import DeleteMeetingModal from '../../components/Schedule/DeleteMeetingModal';
import ReusableMeetingModal from '../../components/Schedule/ReusableMeetingModal';
import CabSpinner from '@CabComponents/CabSpinner';
import { ExcludedSlotInfo, TimeZone } from '../../utils/scheduleUtils';
import { SlotInfo } from 'react-big-calendar';
import SidePanel from './SidePanel';
import { DateTime } from 'luxon';


const calendarSchedulerSx = { padding: 0, paddingTop: 0 };


interface ScheduleProps {
  currentMeeting: Meeting | null;
  currentMeetingErrors: MeetingHoldEventError[];
  errorModalOpen: boolean;
  handleResolution: (resolution: MeetingHoldEventErrorResolution) => void;
  calendars: Calendar[];
  selectedLeaders: number[];
  handleLeaderSelect: (leaders: number[]) => void;
  handleCancel: () => void;
  handleCreateOneOffMeeting: () => void;
  handleCreatePoll: () => void;
  handleCreateReusableMeeting: () => void;
  handleOpenMeeting: (meetingId: number) => void;
  handleCalendarTimezoneSelected: (timezone: TimeZone) => void;
  handleSecondaryTimezoneSelected: (index: number, timezone: TimeZone) => void; // Added index parameter
  handleUpdateAdditionalCalendars: (calendars: NonNullable<Calendar['calendar_access_id']>[]) => void;
  handleOpenAdditionalCalendarsModal: () => void;
  eventsExists: boolean;
  loadingEvents: boolean;
  allLoaded: boolean;
  openMeetingSettings: boolean;
  hasGrant: boolean;
  currentMeetingId: number | undefined; // Changed from number to number | undefined
  loadingCalendars: boolean;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  setCurrentDateRangeInfo: (start: DateTime, end: DateTime) => void;
  calendarTimezoneSelected: TimeZone;
  secondaryTimezonesSelected: Array<TimeZone | undefined>;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleCreateSlot: (info: SlotInfo) => void;
  handleEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  handleDeleteSlots: (slots: MeetingSlot[]) => void;
  handleDuplicateMeeting: (meetingId: number) => void;
  handleDeleteMeeting: (meetingId: number) => void;
  handleShareMeeting: (meetingId: number) => void;
  handleCopyLink: (meetingId: number) => void;
  newMeeting: NewMeeting | null;
  navigate: (url: string) => void;
  handleConvertToOneOff: (meetingId: number) => void;
  leaderMap: Record<number, Leader>;
  currentMeetingSlots: MeetingSlot[];
  updateSlotNames: (name: string) => void;
  activeCalendars: number[];
  handleRemoveLeader: (mtgId: number, leaderId: number) => void;
  handleAddLeader: (meetingId: number, leader: number) => void;
  // setSelectedLeaders: (leaders: number[]) => void;
  setErrorModalOpen: (open: boolean) => void;
  handleShareCurrentMeeting: () => void;
  updatingSlots: boolean;
  pollUpdateOpen: boolean;
  handlePollUpdateAccept: () => void;
  handlePollUpdateReject: () => void;
  invalidatedParticipantVotes: InvalidParticipantVotes;
  handleCreateExcludeSlots: (slots: ExcludedSlotInfo[]) => void;
  leaderCalendarOptions: Calendar[];
  shareMeetingModalMeetingId: number;
  setShareMeetingModalMeetingId: (id: number) => void;
  duplicateMeetingModalMeetingId: number;
  setDuplicateMeetingModalMeetingId: (id: number) => void;
  handleSubmitDuplicateMeeting: (data: DuplicateMeetingSubmitProps) => Promise<Meeting | undefined>;
  deleteMeetingModalMeetingId: number;
  setDeleteMeetingModalMeetingId: (id: number) => void;
  handleSubmitDeleteMeeting: (meeting: Meeting) => void;
  openAdditionalCalendarsModal: boolean;
  setOpenAdditionalCalendarsModal: (open: boolean) => void;
  meetingToDuplicate?: Meeting;
  meetingToDelete?: Meeting;
}

const Schedule = ({
  currentMeeting,
  currentMeetingErrors,
  errorModalOpen,
  handleResolution,
  calendars,
  selectedLeaders,
  handleLeaderSelect,
  handleCancel,
  handleCreateOneOffMeeting,
  handleCreatePoll,
  handleCreateReusableMeeting,
  handleOpenMeeting,
  handleCalendarTimezoneSelected,
  handleSecondaryTimezoneSelected,
  handleUpdateAdditionalCalendars,
  handleOpenAdditionalCalendarsModal,
  eventsExists,
  loadingEvents,
  allLoaded,
  openMeetingSettings,
  hasGrant,
  currentMeetingId,
  loadingCalendars,
  currentDateRangeInfo,
  setCurrentDateRangeInfo,
  calendarTimezoneSelected,
  secondaryTimezonesSelected,
  selectedSlots,
  recurringSlots,
  handleCreateSlot,
  handleEditSlot,
  handleDeleteSlots,
  handleDuplicateMeeting,
  handleDeleteMeeting,
  handleShareMeeting,
  handleCopyLink,
  newMeeting,
  navigate,
  handleConvertToOneOff,
  leaderMap,
  currentMeetingSlots,
  updateSlotNames,
  activeCalendars,
  handleRemoveLeader,
  handleAddLeader,
  // setSelectedLeaders,
  setErrorModalOpen,
  handleShareCurrentMeeting,
  updatingSlots,
  pollUpdateOpen,
  handlePollUpdateAccept,
  handlePollUpdateReject,
  invalidatedParticipantVotes,
  handleCreateExcludeSlots,
  leaderCalendarOptions,
  shareMeetingModalMeetingId,
  setShareMeetingModalMeetingId,
  duplicateMeetingModalMeetingId,
  setDuplicateMeetingModalMeetingId,
  handleSubmitDuplicateMeeting,
  deleteMeetingModalMeetingId,
  setDeleteMeetingModalMeetingId,
  handleSubmitDeleteMeeting,
  openAdditionalCalendarsModal,
  setOpenAdditionalCalendarsModal,
  meetingToDuplicate,
  meetingToDelete,
}: ScheduleProps): ReactElement => {
  const [openSidePanel, setOpenSidePanel] = useState(true);

  const handleToggleSidePanel = () => {
    setOpenSidePanel(!openSidePanel);
  };

  return (
    <CabinetPage
      pageName={'Schedule'}
      headerBackgroundColor={colors.white900}
      headerContent={<>
        <CabinetModal
          open={currentMeetingErrors.length > 0 ? errorModalOpen : false}
          component={
            <MeetingErrors
              meeting={currentMeeting}
              meetingErrors={currentMeetingErrors}
              handleResolution={handleResolution}
              calendars={calendars}
            />
          }
          onClose={() => setErrorModalOpen(false)}         
        />
        <Box
          width="100%"
          // for open side panel offset
          marginRight={openMeetingSettings ? `${CAB_PANEL_WIDTH}px` : 0 }
        >
          <Header
            selectedLeaders={selectedLeaders}
            onLeaderSelect={handleLeaderSelect}
            creatingMeeting={!!newMeeting}
            onCancel={handleCancel}
            currentMeeting={currentMeeting}
            opencalendarsModal={() => navigate(PAGE_URL.MANAGE_CALENDARS)}
            calendarTimezoneSelected={calendarTimezoneSelected}
            onCreateMeeting={handleCreateOneOffMeeting}
            onCreatePoll={handleCreatePoll}
            onCreateReusableMeeting={handleCreateReusableMeeting}
            onDateChange={setCurrentDateRangeInfo}
            currentDateRangeInfo={currentDateRangeInfo}
            onToggleSidePanel={handleToggleSidePanel}
          />
        </Box>
      </>}
    >
      {((!eventsExists && loadingEvents) || !allLoaded) && (
        <Box sx={{
          position: 'absolute',
          zIndex: 200,
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CabSpinner scale={4} color='inherit'/>
        </Box>
      ) }

      <Box display="flex" flex={1} height="100%">
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          gap={1}
          // for open side panel offset
          marginRight={openMeetingSettings ? 0 : `-${CAB_PANEL_WIDTH}px`}
        >
          <SidePanel
            open={openSidePanel}
            onCancel={() => setOpenSidePanel(false)}
            selectedLeaders={selectedLeaders}
            onLeaderSelect={handleLeaderSelect}
            currentMeetingId={currentMeetingId}
            creatingMeeting={!!newMeeting}
          />

          <CalendarSchedulerContainer
            selectedLeaders={selectedLeaders}
            userHasGrant={hasGrant}
            currentMeetingId={currentMeetingId}
            // This should be refactored at some point to use calendar accesses
            onUpdateAdditionalCalendar={handleUpdateAdditionalCalendars}
            loadingCalendars={loadingCalendars}
            openAdditionalCalendarsModal={handleOpenAdditionalCalendarsModal}
            handleCalendarTimezoneSelected={handleCalendarTimezoneSelected}
            handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
            currentDateRangeInfo={currentDateRangeInfo}
            onDateChange={setCurrentDateRangeInfo}
            //Scheduler
            calendarTimezoneSelected={calendarTimezoneSelected}
            secondaryTimezonesSelected={secondaryTimezonesSelected}
            handleOpenMeeting={handleOpenMeeting}
            selectedSlots={selectedSlots}
            recurringSlots={recurringSlots}
            handleSlotsCreated={handleCreateSlot}
            handleEditSlot={handleEditSlot}
            handleDeleteSlots={handleDeleteSlots}
            handleDuplicateMeeting={handleDuplicateMeeting}
            handleDeleteMeeting={handleDeleteMeeting}
            handleShareMeeting={handleShareMeeting}
            handleCopyLink={handleCopyLink}
            hideCalendarHeader={true}
            sx={calendarSchedulerSx}
          />
        </Box>
        {currentMeeting?.use_template_parent && currentMeeting.template_parent ? (
          <ReusableMeetingModal
            open={!!currentMeeting}
            meeting={currentMeeting}
            onClose={handleCancel}
            onConvertToOneOff={() => handleConvertToOneOff(currentMeeting.id)}
            onEditReusable={() => currentMeeting.template_parent && handleOpenMeeting(currentMeeting.template_parent)}
          />
        ) : (
          <MeetingSettingsContainer
            leaderMap={leaderMap}
            currentMeetingId={currentMeetingId}
            meetingSlots={currentMeetingSlots}
            onUpdateMeetingName={updateSlotNames}
            selectedLeaders={selectedLeaders}
            activeCalendars={activeCalendars}
            calendarTimezone={calendarTimezoneSelected}
            secondaryTimezones={secondaryTimezonesSelected}
            onDeleteSlots={handleDeleteSlots}
            onRemoveLeader={handleRemoveLeader}
            onAddLeader={handleAddLeader}
            // onSetSelectedLeaders={setSelectedLeaders}
            onShowErrors={setErrorModalOpen}
            meetingErrors={currentMeetingErrors}
            onShare={handleShareCurrentMeeting}
            onCancel={handleCancel}
            openMeetingSettings={openMeetingSettings}
            slotsAreRecalculating={updatingSlots}
            pollUpdateOpen={pollUpdateOpen}
            onPollUpdateAccept={handlePollUpdateAccept}
            onPollUpdateReject={handlePollUpdateReject}
            invalidMeetingSlots={invalidatedParticipantVotes}
            onExcludedSlotsCreated={handleCreateExcludeSlots}
            onEditSlot={handleEditSlot}
            leaderCalendarOptions={leaderCalendarOptions}
            onCalendarTimezoneSelected={handleCalendarTimezoneSelected}
          />
        )}
        <ScheduleShareModalContainer
          key={shareMeetingModalMeetingId}
          meetingId={shareMeetingModalMeetingId}
          onModalClose={() => setShareMeetingModalMeetingId(-1)}
          modalOpen={shareMeetingModalMeetingId > 0}
          showEdit
        />
        <DuplicateMeetingModal
          open={!!meetingToDuplicate}
          onClose={() => setDuplicateMeetingModalMeetingId(-1)}
          meeting={meetingToDuplicate}
          submitDuplicate={handleSubmitDuplicateMeeting}
        />
        <DeleteMeetingModal
          open={!!meetingToDelete}
          onClose={() => setDeleteMeetingModalMeetingId(-1)}
          meeting={meetingToDelete}
          submitDelete={handleSubmitDeleteMeeting}
        />
      </Box>
    </CabinetPage>
  );
};

export default memo(Schedule);
