import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  RootState, ThunkDispatchType, actions,
  GlobalModalComponentName, Leader, LeaderCalendarUpdate
} from "../../../store";
import { EVENT_TYPE, NEW_TIER_IDS, PAGE_URL } from "../../../constants";
import { CAB_UI_EVENT_NAME } from "../../../uiEvents";
import { NAVY_LOGO_SRC } from "../../../resourceUrls";
import { CabNavBar, SectionItem, Section } from "./CabNavBar";
import { usePrevious } from "../../../utils/hooks";
import { CabAvatar, CabAvatarProps } from "../CabAvatar";
import { PassedComponent } from "../../../utils/types";
import { SxProps, useMediaQuery, useTheme } from "@mui/material";
import AddLeader from "./AddLeader";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import { isMobile } from "../../../utils/screenSizeUtils";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { 
  IoAddCircleOutline, IoBarChartOutline, IoCheckboxOutline, IoFolderOutline, IoHomeOutline, 
  IoPeopleOutline, IoPersonCircle, IoRepeatOutline, IoTodayOutline 
} from "react-icons/io5";
import { isEqual } from "lodash-es";

const pathMatch = (comparisonPath: string, path: string) => {
  const pathArray = path.split("/");
  return isEqual(comparisonPath.split("/").slice(0, pathArray.length), pathArray);
};

const INSIGHTS_GROUP_ID = 'ANALYTICS_GROUP';
const CONNECTIONS_GROUP_ID = 'CRM_GROUP';
const TEAM_GROUP_ID = 'TEAM_GROUP';

const versionNumber = import.meta.env.VITE_APP_FRONTEND_VERSION || '0.0.0';

export const CabNavBarContainer = ({ sx }: { sx?: SxProps }): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatchType>();

  const fetchLeaders = useCallback(() => dispatch(actions.leaders.fetchLeaders()), [dispatch]);
  const fetchSharedLeaderGrants = useCallback(() => 
    dispatch(actions.leaders.fetchSharedLeaderGrants()), [dispatch]);
  const fetchRemoteCalendars = useCallback(() => dispatch(actions.schedule.fetchRemoteCalendars()), [dispatch]);
  const logout = useCallback((global?: boolean) => dispatch(actions.auth.logout(global)), [dispatch]);
  const openGlobalModal = useCallback((componentName: GlobalModalComponentName) => 
    dispatch(actions.globalModal.openModal(componentName)), [dispatch]);
  const createLeader = useCallback(
    (leader: Leader, leaderPic: File | null, idx?: number | undefined) =>
      dispatch(actions.leaders.createLeader(leader, leaderPic, idx)), [dispatch]);
  const updateLeaderCalendars = useCallback((associations: LeaderCalendarUpdate[]) => 
    dispatch(actions.schedule.updateLeaderCalendars(associations)), [dispatch]);
  const reorderLeaders = useCallback((leaderIds: number[]) => 
    dispatch(actions.leaders.reorderLeaders(leaderIds)), [dispatch]);
  const setNavbarExpanded = useCallback((expanded: boolean) =>
    dispatch(actions.cabUI.setCabNavbarExpanded(expanded)), [dispatch]);
  
  const auth = useSelector((root: RootState) => root.auth);
  const leaders = useSelector((root: RootState) => root.leaders);
  const navbarExpanded = useSelector((root: RootState) => root.cabUI.navbarExpanded);
  const calendars = useSelector((root: RootState) => root.schedule.calendars);

  // NOTE: This meant to get us around unit testing
  const pathname = location.pathname;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [showAddLeader, setShowAddLeader] = useState(false);
  const [scrollable, setScrollable] = useState(window.innerHeight < 620);

  useEffect(() => {
    if (isLgUp) {
      setNavbarExpanded(true);
    }
  }, [isLgUp, setNavbarExpanded]);

  useEffect(() => {
    if (isMdDown) {
      setNavbarExpanded(false);
    }
  }, [isMdDown, setNavbarExpanded]);

  window.onresize = () => {
    setScrollable(window.innerHeight < 620);
  };

  // Make this a separate function so the event listener can add/remove it properly
  const handleOpenNav = useCallback(() => {
    setNavbarExpanded(true);
  }, [setNavbarExpanded]);

  useEffect(() => {
    window.addEventListener(CAB_UI_EVENT_NAME.NAV_OPEN, handleOpenNav);

    return () => {
      window.removeEventListener(CAB_UI_EVENT_NAME.NAV_OPEN, handleOpenNav);
    };
  }, [handleOpenNav]);

  useEffect((): void => {
    if (auth.isAuthenticated) {
      fetchLeaders();
      fetchSharedLeaderGrants();
      fetchRemoteCalendars();
    }
  }, [auth.isAuthenticated, fetchRemoteCalendars, fetchLeaders, fetchSharedLeaderGrants]);

  const selectLeader = useCallback((leaderId: number) => {
    navigate(`${PAGE_URL.EXECUTIVE}/${leaderId}`);
  }, [navigate]);

  const prevLeaders = usePrevious(leaders.leaders);

  useEffect((): void => {
    if (!auth.showOnboarding && prevLeaders && prevLeaders.length > 0 && location.pathname !== PAGE_URL.SUPPORT_MAP) {
      if ( leaders.leaders.length > 0 && leaders.leaders.length < prevLeaders.length) {
        // If a leader has beed deleted, switch to the first in the list.
        selectLeader(leaders.leaders[0].id);
      }
    }
  }, [leaders.leaders.length, navigate, prevLeaders, selectLeader, auth.showOnboarding, leaders.leaders, 
    location.pathname]);

  const handleChangePage = useCallback((
    pageUrl: string, external?: boolean, state?: NavigateOptions["state"]
  ) => {
    if (external) {
      window.open(pageUrl, '_blank');
    } else {
      navigate(pageUrl, {state});
    }
  }, [navigate]);

  const handleOpenAddLeader = () => {
    setShowAddLeader(true);
    trackEvent(EVENT_TYPE.START_EXEC_CREATION);
  };

  const handleTemporaryDrawerClick = useCallback((value: unknown) => {
    if (isMdDown) {
      setNavbarExpanded(false);
    }
  }, [isMdDown, setNavbarExpanded]);

  const handleReorderLeaders = async (leaderIds: number[]) => {
    await reorderLeaders(leaderIds);
  };

  const hideCalendarAnalytics = !!auth.user?.features.BLOCK_CALENDAR_ANALYTICS;

  const leaderSectionItems = useMemo(() => {
    const items: SectionItem[] = leaders.leaders.map(leader => {
      const leaderName = `${leader.first_name} ${leader.last_name}`;
      const sharedBy = leader.shared_by ? `${leader.shared_by.first_name} ${leader.shared_by.last_name}` : undefined;
      const iconOverride: PassedComponent<CabAvatarProps> = {
        component: CabAvatar,
        props: {
          name: leaderName,
          src: leader.pic_url,
          color: leader.color,
          size: 'medium',
          isShared: leader.is_shared,
          sharedBy: sharedBy
        }
      };
      return {
        title: leaderName,
        id: leader.id,
        Icon: IoPersonCircle,
        iconOverride,
        path: `${PAGE_URL.EXECUTIVE}/${leader.id}`,
        onClick: () => handleTemporaryDrawerClick(selectLeader(leader.id)),
        locked: false
      };
    });

    if (!auth.user?.features.DISABLE_USER_LEADER_CONTROL) {
      items.push({
        title: "Add New",
        Icon: IoAddCircleOutline,
        onClick: () => handleTemporaryDrawerClick(handleOpenAddLeader()),
        locked: false,
        id: -1
      });
    }

    return items;
  }, [leaders.leaders, auth.user?.features.DISABLE_USER_LEADER_CONTROL, handleTemporaryDrawerClick, selectLeader]);

  const mainSection: Section = useMemo(() => {
    const sectionList = {
      items: [
        {
          title: "Dashboard",
          Icon: IoHomeOutline,
          path: PAGE_URL.DASHBOARD,
          onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.DASHBOARD)),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: false,
          elementId: 'CabNavBar-dashboard'
        },
        {
          title: "Scheduling",
          Icon: IoTodayOutline,
          path: PAGE_URL.SCHEDULE,
          onClick: () => handleTemporaryDrawerClick(
            handleChangePage(PAGE_URL.SCHEDULE, false, { initSchedule: true })
          ),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: false,
          hidden: isMobile(),
          elementId: 'CabNavBar-scheduling'
        },
        {
          title: "Meeting Log",
          Icon: IoFolderOutline,
          path: PAGE_URL.MEETINGS,
          onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.MEETINGS)),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: false,
          hidden: false,
          elementId: 'CabNavBar-meeting-log'
        },
        {
          title: "Reusables",
          Icon: IoRepeatOutline,
          path: PAGE_URL.REUSABLE_MEETINGS,
          onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.REUSABLE_MEETINGS)),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: false,
          hidden: false,
          elementId: 'CabNavBar-reusable-log'
        },
        {
          title: "Poll Results",
          Icon: IoCheckboxOutline,
          path: PAGE_URL.POLL_RESULTS,
          onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.POLL_RESULTS)),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: !auth.user?.features.MEETING_POLLS,
          hidden: false,
          elementId: 'CabNavBar-polls-log'
        },
        {
          title: "Connections",
          Icon: IoPeopleOutline,
          groupId: CONNECTIONS_GROUP_ID,
          onClick: () => handleTemporaryDrawerClick(handleChangePage("")),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: !auth.user?.features.CRM,
          hidden: !auth.user?.features.CRM,
          elementId: 'CabNavBar-crm-connections',
          childItems: [
            {
              title: "People",
              path: PAGE_URL.CRM_PEOPLE,
              onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.CRM_PEOPLE)),
              onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
              locked: !auth.user?.features.CRM,
              hidden: !auth.user?.features.CRM,
              elementId: 'CabNavBar-crm-contacts',
            },
            {
              title: "Companies",
              path: PAGE_URL.CRM_COMPANIES,
              onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.CRM_COMPANIES)),
              onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
              locked: !auth.user?.features.CRM,
              hidden: !auth.user?.features.CRM,
              elementId: 'CabNavBar-crm-companies',
            },
            {
              title: "Relationships",
              path: PAGE_URL.CRM_RELATIONSHIPS,
              onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.CRM_RELATIONSHIPS)),
              onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
              locked: !auth.user?.features.CRM,
              hidden: !auth.user?.features.CRM,
              elementId: 'CabNavBar-crm-relationships',
            },
          ]
        },
        {
          title: "Reports",
          groupId: INSIGHTS_GROUP_ID,
          Icon: IoBarChartOutline,
          onClick: () => handleTemporaryDrawerClick(handleChangePage("")),
          onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
          locked: false,
          hidden: hideCalendarAnalytics,
          elementId: 'CabNavBar-reports',
          childItems: [
            {
              title: "Reconciliation",
              path: PAGE_URL.RECONCILIATION,
              onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.RECONCILIATION)),
              onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
              locked: !auth.user?.features.CALENDAR_ANALYTICS,
              hidden: hideCalendarAnalytics,
              elementId: 'CabNavBar-reconciliation-report',
            },
            {
              title: "Calendar Insights",
              path: PAGE_URL.INSIGHTS,
              onClick: () => handleTemporaryDrawerClick(handleChangePage(PAGE_URL.INSIGHTS)),
              onClickLocked: () => openGlobalModal(GlobalModalComponentName.CABINET_PROMO),
              locked: !auth.user?.features.CALENDAR_ANALYTICS,
              hidden: hideCalendarAnalytics,
              elementId: 'CabNavBar-insights-report',
            },
          ]
        },
        {
          title: "Teammates",
          groupId: TEAM_GROUP_ID,
          Icon: IoPeopleOutline,
          onClick: () => handleTemporaryDrawerClick(handleChangePage("")),
          locked: false,
          elementId: 'CabNavBar-teammates',
          childItems: leaderSectionItems,
        },
      ]
    };
    return sectionList;
  }, [auth.user?.features.MEETING_POLLS, auth.user?.features.CALENDAR_ANALYTICS, auth.user?.features.CRM, 
    hideCalendarAnalytics, handleTemporaryDrawerClick, handleChangePage, openGlobalModal, leaderSectionItems]);

  const handleSectionItemActive = (sectionItem: SectionItem) => {
    if (sectionItem.path === PAGE_URL.DASHBOARD) {
      return !!sectionItem.path && pathname === sectionItem.path;
    }
    if (sectionItem?.groupId === INSIGHTS_GROUP_ID) {
      return pathname === PAGE_URL.RECONCILIATION || 
      pathname === PAGE_URL.INSIGHTS;
    }
    if (sectionItem?.groupId === CONNECTIONS_GROUP_ID) {
      return pathname === PAGE_URL.CRM_PEOPLE || 
      pathname === PAGE_URL.CRM_COMPANIES ||
      pathname === PAGE_URL.CRM_RELATIONSHIPS;
    }
    return !!sectionItem.path && pathMatch(pathname, sectionItem.path);
  };

  const handleCreateLeader = async (
    leader: Leader, leaderPic: File | null, idx?: number, cals?: number[]
  ) => {
    if (cals?.length) {
      const providerLookup = calendars
        .map(c => ({ [c.id]: c.provider }))
        .reduce((a, b) => ({ ...a, ...b }), {});
      const newLeader = await createLeader(leader, leaderPic);
      if (!newLeader) return;

      const leaderToCalendars = cals.map(cal => {
        return {
          leader_id: newLeader.id,
          provider: providerLookup[cal],
          calId: cal,
          associate: true,
          prevent_conflict: true,
          allow_calendar_analytics: true,
          allow_crm: true
        };
      });
      await updateLeaderCalendars(leaderToCalendars);
    } else {
      await createLeader(leader, leaderPic);
      dispatch(actions.leaders.fetchOrganizationLeader());
    }
  };

  const noLicense = !auth.user?.active_license.is_active
    || auth.user.active_license.license_tier === NEW_TIER_IDS['BASIC'];

  if (!auth.isAuthenticated
    || pathname === PAGE_URL.UPDATE 
    || pathname === PAGE_URL.MAINTENANCE
    || pathname === PAGE_URL.SSO_SEARCH
    || pathname.startsWith(PAGE_URL.SIGNUP)
    || pathname.startsWith(PAGE_URL.LOGIN)
    || pathname.startsWith(`${PAGE_URL.BOOK_MEETING}/`)
    || pathname.startsWith(`${PAGE_URL.TERMS_OF_SERVICE}/`)
    || pathname.startsWith(PAGE_URL.CHANGE_EMAIL) 
    || pathname.startsWith(`${PAGE_URL.ONBOARDING}/`)
    || pathname.startsWith(`${PAGE_URL.GROUP_SCHEDULING_PARTICIPANT}/`)
  ) {
    return <></>;
  } else {
    return <>
      <CabNavBar 
        parentOpen={navbarExpanded}
        variant={isMdDown ? "temporary" : "permanent"}
        onOpen={setNavbarExpanded}
        logo={NAVY_LOGO_SRC}
        mainSection={noLicense ? { items: [] } : mainSection}
        leadersLoaded={leaders.loaded}
        sectionItemActive={handleSectionItemActive}
        user={auth.user} 
        onChangePage={handleChangePage} 
        onLogout={logout}
        versionNumber={versionNumber}
        onTemporaryDrawerClick={handleTemporaryDrawerClick}
        onOpenAddLeader={handleOpenAddLeader}
        onReorderLeaders={handleReorderLeaders}
        scrollable={scrollable}
        sx={sx}
      />
      <AddLeader 
        open={showAddLeader} 
        onClose={() => setShowAddLeader(false)}
        onCreateLeader={handleCreateLeader}
        calendars={calendars}
      />
    </>;
  }
};

export default CabNavBarContainer;
