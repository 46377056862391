import { useDispatch, useSelector } from "react-redux";
import SidePanel from "./SidePanel";
import { actions, Calendar, Leader, RootState, ThunkDispatchType } from "../../../store";
import { useMemo } from "react";
import {
  hiddenCalendarIdsUpdated,
  leaderSelectionUpdated, selectMiddleOfInterval, selectSelectedAdditionalCalendars, updateDateRange
} from "../../../store/scheduleUI";
import { useHandler } from "../../../store/hooks";
import { selectOrderedSchedulingLeaders } from "../../../store/leaders/selectors";
import { selectLeaderIdCalendarMap, selectLeaderIdPrimaryCalendarMap } from "../../../store/schedule/selectors";


const SidePanelContainer = ({
  open, onCancel, selectedLeaders, onLeaderSelect, currentMeetingId, creatingMeeting,
}: {
  open: boolean;
  onCancel: () => void;
  selectedLeaders: number[];
  onLeaderSelect: (ids: number[]) => void;
  currentMeetingId?: number;
  creatingMeeting: boolean;
}) => {
  const allLeaders = useSelector((state: RootState) =>  state.leaders.leaders);
  const schedulingLeaders = useSelector(selectOrderedSchedulingLeaders);
  const calendars = useSelector((state: RootState) =>  state.schedule.calendars);
  const selectedAdditionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const middleOfInterval = useSelector(selectMiddleOfInterval);
  const hiddenCalendarIds = useSelector((state: RootState) => state.scheduleUI.hiddenCalendarIds);
  const leaderIdCalendarMap = useSelector(selectLeaderIdCalendarMap);
  const leaderIdPrimaryCalendarMap = useSelector(selectLeaderIdPrimaryCalendarMap);

  const handle = useHandler();

  const additionalCalendars = useMemo(() => {
    return selectedAdditionalCalendars
      .filter((cal): cal is Calendar & { calendar_access_id: number } => cal.calendar_access_id != null)
      .map(cal => {
        return {
          id: cal.id,
          calendar_access_id: cal.calendar_access_id,
          title: cal?.additionalCalendarEmail?.name || cal.summary || cal.owner_email || '',
          subtitle: cal.owner_email || '',
          editable: !!cal?.additionalCalendarEmail,
          leaders: cal?.leaders.map(lId => allLeaders.find(ldr => ldr.id === lId))
            .filter(ldr => ldr) as Leader[] | undefined,
          backgroundColor: cal.backgroundColor,
        };
      });
  }, [selectedAdditionalCalendars, allLeaders]);

  const dispatch = useDispatch<ThunkDispatchType>();

  const handleUpdateLeaderEmails = async (updateLeaders: Leader[]) => {
    const leaderUpdatePromises: Promise<Leader | undefined>[] = [];
    updateLeaders.forEach(l => {
      const leaderUpdate = {id: l.id, email: l.email};
      leaderUpdatePromises.push(dispatch(actions.leaders.updateLeader(leaderUpdate, null)));
    });
    await Promise.resolve(leaderUpdatePromises);
  };

  return (
    <SidePanel
      open={open}
      onCancel={onCancel}
      leaders={schedulingLeaders}
      calendars={calendars}
      additionalCalendars={additionalCalendars}
      selectedLeaders={selectedLeaders}
      onLeaderSelect={handle(leaderSelectionUpdated)}
      currentMeetingId={currentMeetingId}
      creatingMeeting={creatingMeeting}
      onUpdateLeaderEmails={handleUpdateLeaderEmails}
      middleOfInterval={middleOfInterval}
      onUpdateDateRange={handle(updateDateRange)}
      hiddenCalendarIds={hiddenCalendarIds}
      onToggleCalendarVisibility={handle(hiddenCalendarIdsUpdated)}
      leaderIdCalendarMap={leaderIdCalendarMap}
      leaderIdPrimaryCalendarMap={leaderIdPrimaryCalendarMap}
    />
  );
};

export default SidePanelContainer;
