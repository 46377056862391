import { CabAvatar, CabButton, CabExecPicker, CabIcon, CabPanel, CabTimezoneInput } from "@CabComponents";
import { Box, Chip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { PollTable, PollTableLegend } from "../../../components/Meeting/GroupScheduling";
import CalendarScheduler from "../../../components/Schedule/CalendarScheduler/CalendarScheduler";
import {
  Calendar, Leader, MeetingPollPriority, Meetings, MeetingSlot, ParticipantsWithSelections, 
  SchedulingPreferences, SelectedSlots, UICalendarEventConsolidated
} from "../../../store";
import { allTimeZones, colorDisplayMeetingSlots, TimeZone } from "../../../utils/scheduleUtils";
import { Views as RBCViews } from 'react-big-calendar';
import classes from '../../Schedule/Schedule.module.css';
import chroma from "chroma-js";
import { uniqBy } from "lodash-es";
import { getLeaderIconSrc } from "../../../utils/leaderUtils";
import { IoEyeOffOutline, IoPeopleOutline, IoTimeOutline } from "react-icons/io5";

const calendarPanelWidth = 425;

export type PollSelectionTableProps = {
  attendeesDisplayName: string;
  handleCalendarTimezoneSelected: (timezone: TimeZone) => void;
  // returningUserEmailHashes: {[hash: string]: string};
  activeParticipants: { email: string; emailHash: string; name: string }[];
  participants: ParticipantsWithSelections;
  convertedSlots: ConvertedSlot[];
  handleCheckSlot: (email: string, value: number, priority: MeetingPollPriority | null) => void;
  selectedSlots: { [email: string]: Map<number, SelectedSlots[number] | null> };
  calendarTimezoneSelected?: TimeZone;
  isMobile?: boolean;
  name: string;
  description: string;
  durationText: string;
  participantLength: number;
  date?: { start: DateTime, end: DateTime };
  onDateChange?: (start: DateTime, end: DateTime) => void;
  userData?: {
    leaders: Leader[];
    meetingSlots: MeetingSlot[];
    recurringSlots: MeetingSlot[];
    calendarEvents: UICalendarEventConsolidated[];
    meetings: Meetings;
    calendars: Calendar[];
    schedulingPrefs?: SchedulingPreferences;
    selectedLeader: Leader | null,
    onSelectedLeaderChange: (l: Leader | null) => void,
  };
  openCalendar: boolean;
  onCloseCalendar: () => void;
  enhancePollTableCursors: boolean
  onEndReached: (value: boolean) => void
};

type ConvertedSlot = {
  converted: {
    start: DateTime;
    end: DateTime;
  };
  index: number;
  start: DateTime;
  end: DateTime;
};

const PollSelectionTable = ({
  attendeesDisplayName, handleCalendarTimezoneSelected,
  /*returningUserEmailHashes*/ activeParticipants, participants, convertedSlots, isMobile,
  handleCheckSlot, selectedSlots, calendarTimezoneSelected,
  name, durationText, participantLength, description, date, onDateChange, userData, openCalendar, onCloseCalendar,
  enhancePollTableCursors, onEndReached
}: PollSelectionTableProps) => {
  const [showDescription, setShowDescription] = useState(false);

  const cabExecOptions = useMemo(() => (userData?.leaders || []).map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [userData?.leaders]);

  const selectedLeaders = userData?.selectedLeader ? [userData.selectedLeader] : [];

  useEffect(() => {
    if (selectedLeaders.length === 0 && userData) {
      const participantEmails = activeParticipants.map(ap => ap.email);
      const matchingLeaders = userData.leaders.filter(l => participantEmails.includes(l.email)) || [];
      if (matchingLeaders.length > 0) {
        userData.onSelectedLeaderChange(matchingLeaders[0]);
      }
    }
  }, [activeParticipants, selectedLeaders.length, userData]);

  const newMeeting = {
    id: -1,
    title: 'Proposed Slot',
    leader_info: selectedLeaders.map(l => ({ ...l, meeting_leader_id: -1 })),
  };

  const meetingSlots = userData ? colorDisplayMeetingSlots(
    userData.meetingSlots,
    selectedLeaders.map(l => l.id),
    userData?.meetings,
    undefined,
    classes.pattern,
    userData.schedulingPrefs,
    false,
    true,
  ) : [];

  let availableSlots: MeetingSlot[] = Object.values(selectedSlots).flatMap(p => [...p.values()])
    .filter((s) => !!s && s.priority === MeetingPollPriority.AVAILABLE)
    // NOTE: Get's us around an inconveince of a nullable s which we check for in the filter
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((s: any) => ({
      id: s.id,
      start_date: s.start.toISO(),
      end_date: s.end.toISO(),
      meeting: -1,
      is_exclude: false,
      editable: false,
    }));

  availableSlots = userData ? colorDisplayMeetingSlots(
    availableSlots,
    selectedLeaders.map(l => l.id),
    { [-1]: newMeeting } as unknown as Meetings,
    -1,
    classes.pattern,
    userData.schedulingPrefs,
  ) : [];

  let potentialSlots: MeetingSlot[] = Object.values(selectedSlots).flatMap(p => [...p.values()])
    .filter((s) => !!s && s.priority === MeetingPollPriority.POTENTIAL)
    // NOTE: Get's us around an inconveince of a nullable s which we check for in the filer
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((s: any) => ({
      id: s.id,
      start_date: s.start.toISO(),
      end_date: s.end.toISO(),
      meeting: -1,
      is_exclude: false,
      editable: false,
    }));

  potentialSlots = userData ? colorDisplayMeetingSlots(
    potentialSlots,
    selectedLeaders.map(l => l.id),
    { [-1]: newMeeting } as unknown as Meetings,
    -1,
    classes.pattern,
    userData.schedulingPrefs,
    true,
  ) : [];

  const allMeetingSlots = [...meetingSlots, ...availableSlots, ...potentialSlots].map(s => ({ ...s, editable: false }));

  const handleLeaderChange = (leaderId: number) => {
    if (userData) {
      const leader = userData.leaders.find(l => l.id === leaderId);
      userData.onSelectedLeaderChange(leader || null);
    }
  };

  const handleDateChange = (curDate: DateTime) => {
    if (!onDateChange) return;

    const newDate = calendarTimezoneSelected ? curDate.setZone(calendarTimezoneSelected.name) : curDate;
    const newDateStart = newDate.startOf('day');
    const newDateEnd = newDate.endOf('day');
    onDateChange(newDateStart, newDateEnd);
  };

  const coloredEvents = userData?.calendarEvents.map(e => ({
    ...e,
    backgroundColor: e.backgroundColor ? chroma.hex(e.backgroundColor).desaturate(0.3).hex() : undefined,
    borderColor: e.borderColor ? chroma.hex(e.borderColor).desaturate(0.3).hex() : undefined,
    textColor: e.textColor ? chroma.hex(e.textColor).alpha(0.7).hex() : undefined,
  })) || [];

  const allowedDates = uniqBy(convertedSlots.map(s => s.converted.start.startOf('day')), s => s.toMillis());

  return (
    <Box display="flex">
      <Box
        flexGrow={1}
        marginRight={{
          lg: openCalendar ? 0 : `-${calendarPanelWidth}px`,
        }}
        position="relative"
      >
        <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
          <Box display='flex' flexGrow={1} flexDirection='column' gap={isMobile ? 2 : 4}
          >
            <Box display='flex' flexDirection='column' gap={isMobile ? 1 : 2}>
              {!isMobile ? (
                <>
                  <Typography variant="h1" fontSize={38} lineHeight={'41px'}>
                    Choose preferred time slots
                  </Typography>
                  <Typography variant="h5" maxWidth={'480px'}>
                    Click once to select preferred times, or double click if the time isn't ideal but could still work.
                  </Typography>
                </>
              ) : (
                <>
                  <Box display='flex' gap={2} marginTop={1}>
                    <Box display='flex' flexDirection='column' gap={1}>
                      <Typography variant="h1">{name}</Typography>
                      <Box gap={2} display="flex">
                        <Chip
                          sx={{ borderRadius: '6px', fontWeight: 500 }}
                          icon={<CabIcon Icon={IoTimeOutline} alt='respondents' sx={{fontSize: '16px'}}/>}
                          label={durationText}
                        />
                        <Chip
                          sx={{ borderRadius: '6px', fontWeight: 500 }}
                          icon={<CabIcon Icon={IoPeopleOutline} alt='respondents' sx={{fontSize: '16px'}}/>}
                          label={`${participantLength} responded`}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {description && (
                      <>
                        <CabButton
                          buttonType='tertiary' color='accent'
                          onClick={() => setShowDescription(!showDescription)}
                          icon={<CabIcon Icon={showDescription ? IoEyeOffOutline : IoEyeOffOutline}/>} 
                          sx={{width: '178px'}}
                        >
                          {showDescription ? 'Hide Description' : 'See Description'}
                        </CabButton>
                        {showDescription && (
                          <Typography whiteSpace='pre-wrap' variant="body1">{description}</Typography>
                        )}
                      </>
                    )}
                    <Typography marginTop={2} variant="body1" fontWeight={500}>
                      Click once to select preferred times, or double click if the time isn't ideal but could
                      still work.
                    </Typography>
                  </Box>
                </>
              )}
              <Box display="flex" justifyContent="space-between" gap={1} marginRight={1}>
                <CabTimezoneInput
                  onChange={tzName => {
                    const tz = allTimeZones.find(zone => zone.name === tzName);
                    if (tz) {
                      handleCalendarTimezoneSelected(tz);
                    }
                  }}
                  value={calendarTimezoneSelected?.name || ''}
                  sx={{ minWidth: { xs: '100%', sm: '315px', md: '350px' } }}
                />
                <PollTableLegend hidePopular />
              </Box>
            </Box>
            <Box>
              <PollTable
                // returningUserEmailHashes={returningUserEmailHashes}
                activeParticipants={activeParticipants}
                votable={true}
                name={attendeesDisplayName}
                participants={participants}
                timezoneConvertedSlots={convertedSlots}
                onCheckSlot={handleCheckSlot}
                selectedSlots={selectedSlots}
                calendarOpen={openCalendar}
                calendarDate={date?.start}
                onEndReached={onEndReached}
                enhancePollTableCursors={enhancePollTableCursors}
                onDateChange={handleDateChange}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {userData && date && onDateChange && (
        <CabPanel
          open={openCalendar}
          onClose={onCloseCalendar}
          closeIcon
          anchor="right"
          sx={{ flexShrink: 0, display: { xs: 'none', lg: 'block' } }}
          width={calendarPanelWidth}
          title={(
            <CabExecPicker
              options={cabExecOptions}
              placeholder="Select a teammember"
              value={userData?.selectedLeader?.id || null}
              sx={{ fontSize: '16px', fontWeight: 700, height: '26px', marginBottom: 1, marginTop: 1,
                '& .MuiInputBase-input': {
                  lineHeight: '20px',
                  width: calendarPanelWidth - 90,
                }
              }}
              onChange={handleLeaderChange}
            />
          )}
        >
          <Box display="flex" flex={1} height="100%">
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <CalendarScheduler
                defaultView={RBCViews.DAY}
                currentMeetingId={-1}
                secondaryTimezonesSelected={[]}
                leaderHasAssociations
                userHasGrant
                currentDateRangeInfo={date}
                calendarTimezoneSelected={calendarTimezoneSelected}
                onDateChange={onDateChange}
                coloredEvents={coloredEvents}
                selectedSlots={allMeetingSlots}
                selectedLeaders={selectedLeaders}
                loadingCalendars={false}
                calendars={[]}
                calendarMap={{}}
                meetings={userData.meetings}
                recurringSlots={userData.recurringSlots}
                additionalCalendars={[]}
                hideHeader
                hideViewOptionsPicker
                hideTodayButton
                hideViewPicker
                noCalendarBorder
                hideResourceHeader
                newMeeting={newMeeting}
                interactive={false}
                allowedDates={allowedDates}
                granularTimeSelection={userData.schedulingPrefs?.granular_time_selection || false}
                calendarView={RBCViews.DAY}
                onCalendarViewChange={() => {}}
              />
            </Box>
          </Box>
        </CabPanel>
      )}
    </Box>
  );
};

export default PollSelectionTable;
