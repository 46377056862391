import { Fragment, useCallback, useEffect, useMemo } from "react";
import {
  Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import { DateTime } from "luxon";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import CabDatePicker from "@CabComponents/CabDatePicker";
import { Leader, Calendar } from "../../../store";
import chroma from "chroma-js";
import colors from "../../../colors";
import LeaderEmailsModal from "../../../components/Schedule/LeaderEmailsModal";
import { CabModal } from "@CabComponents/CabModal";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import CabAvatar from "@CabComponents/CabAvatar";
import {
  IoAddOutline, IoChevronDownOutline, IoChevronUpOutline, IoEyeOffOutline, IoEyeOutline, IoHelpCircleOutline
} from "react-icons/io5";
import { useState } from "react";
import AdditionalCalendarsModal from "../../../components/Schedule/AdditionalCalendarsModal";


const sidePanelWidth = '275px';

interface SidePanelProps {
  open: boolean;
  onCancel: () => void;
  leaders: Leader[];
  calendars: Calendar[];
  selectedLeaders: number[];
  currentMeetingId?: number;
  creatingMeeting: boolean;
  onLeaderSelect: (selection: { leaderId: number, selected: boolean }[]) => void;
  onUpdateLeaderEmails: (leaders: Leader[]) => Promise<void>;
  middleOfInterval: DateTime;
  onUpdateDateRange: (start: DateTime, end: DateTime) => void;
  hiddenCalendarIds: number[];
  onToggleCalendarVisibility: (payload: { calendarId: number, hidden: boolean }[]) => void;
  leaderIdCalendarMap: Record<number, Calendar[]>;
  leaderIdPrimaryCalendarMap: Record<number, Calendar | undefined>;
  additionalCalendars: {
    id: number;
    title: string;
    subtitle: string;
    editable: boolean;
    leaders?: Leader[];
    calendar_access_id: number;
    backgroundColor?: string;
  }[];
}

const SidePanel = ({
  open, onCancel, leaders, calendars, selectedLeaders, currentMeetingId, creatingMeeting, onLeaderSelect,
  onUpdateLeaderEmails, additionalCalendars, middleOfInterval, onUpdateDateRange, hiddenCalendarIds,
  onToggleCalendarVisibility, leaderIdCalendarMap, leaderIdPrimaryCalendarMap,
}: SidePanelProps) => {
  const [execsToUpdate, setExecsToUpdate] = useState<Leader[]>([]);
  const [execsToBeUpdatedOnMeeting, setExecsToBeUpdatedOnMeeting] = useState<Leader[]>([]);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [openAdditionalCalendarsModal, setOpenAdditionalCalendarsModal] = useState(false);
  const [expandedLeaderIds, setExpandedLeaderIds] = useState<Set<number>>(new Set());

  const hiddenCalendarSet = useMemo(() => new Set(hiddenCalendarIds), [hiddenCalendarIds]);

  // additionally sort leaders first if they have a calendar
  const sortedLeaders = useMemo(() => {
    return leaders.toSorted((a, b) => {
      const aHasCalendar = leaderIdCalendarMap[a.id].length > 0;
      const bHasCalendar = leaderIdCalendarMap[b.id].length > 0;
      if (aHasCalendar && !bHasCalendar) {
        return -1;
      } else if (!aHasCalendar && bHasCalendar) {
        return 1;
      }
      return 0;
    });
  }, [leaders, leaderIdCalendarMap]);

  const currLeaders = useMemo(() => (
    sortedLeaders.filter(l => selectedLeaders.includes(l.id))
  ), [sortedLeaders, selectedLeaders]);

  const addedLeaders = useMemo(() => (
    execsToBeUpdatedOnMeeting.filter(l => !currLeaders.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const removedLeaders = useMemo(() => (
    currLeaders.filter(l => !execsToBeUpdatedOnMeeting.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const confirmMeetingLeaderChange = () => {
    handleLeaderSelectForOpenMeeting(execsToBeUpdatedOnMeeting.map(l => l.id));
    setExecsToBeUpdatedOnMeeting([]);
  };

  const cancelMeetingLeaderChange = () => {
    setExecsToBeUpdatedOnMeeting([]);
  };

  const handleLeaderSelect = (selection: { leaderId: number, selected: boolean }[]) => {
    const fullLeaderSelection = sortedLeaders.filter(l => 
      selection.some(s => s.leaderId === l.id && s.selected) || selectedLeaders.includes(l.id)
    );
    if (currentMeetingId) {
      setExecsToBeUpdatedOnMeeting(fullLeaderSelection);
    } else if (creatingMeeting) {
      handleLeaderSelectForOpenMeeting(fullLeaderSelection.map(l => l.id));
    } else {
      onLeaderSelect(selection);
    }
  };

  const handleUpdateLeaderSelection = useCallback((ids: number[]): void => {
    onLeaderSelect(ids.map(id => ({ leaderId: id, selected: true })));
  }, [onLeaderSelect]);

  const handleLeaderSelectForOpenMeeting = (leaderIds: number[]) => {
    const leadersWithoutEmail = sortedLeaders.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
      .filter(l => !l.email);
    const leadersWithEmail = sortedLeaders.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
      .filter(l => l.email);

    if (leadersWithEmail.length > 0) {
      onLeaderSelect(leadersWithEmail.map(l => ({ leaderId: l.id, selected: true })));
    }

    if (leadersWithoutEmail.length > 0) {
      setExecsToUpdate(leadersWithoutEmail);
    }
  };

  const handleCheckLeader = (leaderId: number, checked: boolean) => {
    handleLeaderSelect([{ leaderId, selected: checked }]);
  };

  const handleToggleCalendarVisibility = (calendarId: number) => {
    onToggleCalendarVisibility([{ calendarId, hidden: !hiddenCalendarSet.has(calendarId) }]);
  };

  const handleToggleLeaderExpansion = (leaderId: number) => {
    setExpandedLeaderIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(leaderId)) newSet.delete(leaderId);
      else newSet.add(leaderId);
      return newSet;
    });
  };

  useEffect(() => {
    if (creatingMeeting) {
      const leadersWithoutEmail = sortedLeaders.filter(l => selectedLeaders.includes(l.id))
        .filter(l => !l.email);

      if (leadersWithoutEmail.length > 0) {
        setExecsToUpdate(leadersWithoutEmail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingMeeting]);

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      variant="persistent"
      anchor="left"
      sx={{
        width: open ? sidePanelWidth : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidePanelWidth,
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ padding: 1.5 }}>
        <CabDatePicker
          static
          noActionBar
          condensed
          size="small"
          type="week"
          // type={calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'week' : 'day'}
          value={middleOfInterval.toISO()}
          onChange={d => d && onUpdateDateRange(
            DateTime.fromISO(d).startOf('week'), DateTime.fromISO(d).endOf('week')
          )}
          // timezone={calendarTimezoneSelected?.name}
          // sx={{
          //   maxWidth: 100,
          //   width: '100px',
          // }}
          // allowedDates={allowedDates}
        />
      </Box>

      <Divider />

      <Box sx={{ padding: 2.5, overflow: 'auto' }}>
        <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 600, color: colors.black700 }}>
          Selected Teammates
        </Typography>
        {sortedLeaders.map(leader => (
          <Fragment key={leader.id}>
            <List dense disablePadding>
              <ListItem
                disablePadding
                secondaryAction={leaderIdCalendarMap[leader.id].length > 0 && (
                  <Box className="collapsible-icon">
                    <CabIcon
                      Icon={expandedLeaderIds.has(leader.id) ? IoChevronUpOutline : IoChevronDownOutline}
                      onClick={() => handleToggleLeaderExpansion(leader.id)} />
                  </Box>
                )}
                sx={{
                  marginTop: leaderIdCalendarMap[leader.id].length === 0 ? 0.75 : 1.5,
                  '& .collapsible-icon': { opacity: expandedLeaderIds.has(leader.id) ? 1 : 0 },
                  '&:hover .collapsible-icon': { opacity: 1 },
                  '& .MuiListItemSecondaryAction-root': { right: 0 },
                  paddingLeft: 0.25,
                }}

              >
                <ListItemButton role={undefined} dense disableGutters
                  onClick={(e) => {
                    // Don't fire if click originated from checkbox
                    if (!(e.target as HTMLElement).closest('.MuiCheckbox-root')) {
                      handleToggleLeaderExpansion(leader.id);
                    }
                  }}
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                    <CabCheckbox
                      checked={selectedLeaders.includes(leader.id)}
                      onChange={(e, checked) => handleCheckLeader(leader.id, checked)}
                      overrides={{ disableRipple: true }}
                      sx={
                        leaderIdPrimaryCalendarMap[leader.id] && {
                          '& .MuiSvgIcon-root': {
                            color: leaderIdPrimaryCalendarMap[leader.id]?.backgroundColor
                          },
                          '&.Mui-checked': {
                            '& .MuiSvgIcon-root': {
                              color: leaderIdPrimaryCalendarMap[leader.id]?.backgroundColor
                            }
                          },
                        }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${leader.first_name} ${leader.last_name}`} 
                    primaryTypographyProps={{
                      fontWeight: 600,
                    }}
                    sx={{ margin: 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>

            <Collapse in={expandedLeaderIds.has(leader.id)}>
              <List dense disablePadding>
                {leaderIdCalendarMap[leader.id].map(calendar => (
                  <ListItem
                    disablePadding
                    secondaryAction={(
                      <Box id="calendar-eye-icon">
                        <CabIcon
                          Icon={hiddenCalendarSet.has(calendar.id) ? IoEyeOffOutline : IoEyeOutline}
                          onClick={() => handleToggleCalendarVisibility(calendar.id)} />
                      </Box>
                    )}
                    sx={{
                      '& #calendar-eye-icon': { opacity: hiddenCalendarSet.has(calendar.id) ? 1 : 0 },
                      '&:hover #calendar-eye-icon': { opacity: 1 },
                      opacity: hiddenCalendarSet.has(calendar.id) ? 0.5 : 1,
                      paddingLeft: 0.25,
                      '& .MuiListItemSecondaryAction-root': { right: 0 },
                    }}
                    key={calendar.id}
                  >
                    <ListItemButton dense disableGutters sx={{ paddingTop: 0.25, paddingBottom: 0.25 }}>
                      <ListItemIcon sx={{ minWidth: 22, marginRight: 0.5, marginLeft: '6px' }}>
                        <Box sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: calendar.backgroundColor
                            && chroma(calendar.backgroundColor).alpha(0.5).hex(),
                          border: `1px solid ${calendar.backgroundColor}`,
                        }} />
                      </ListItemIcon>
                      <ListItemText primary={calendar.summary} sx={{ margin: 0 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>

          </Fragment>
        ))}

        <Typography
          variant="h6"
          sx={{ marginBottom: 0.5, marginTop: 2, fontSize: 14, fontWeight: 600, color: colors.black700 }}
        >
          Other Calendars
        </Typography>
        <List dense>
          {additionalCalendars.map(calendar => (
            <ListItem
              key={calendar.id}
              disablePadding
              // onClick={() => handleCheckLeader(leader.id, !selectedLeaders.includes(leader.id))}
              secondaryAction={(null)}
            >
              <ListItemButton role={undefined} dense disableGutters>
                <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                  <CabCheckbox
                    checked={!hiddenCalendarSet.has(calendar.id)}
                    onChange={(e, checked) => handleToggleCalendarVisibility(calendar.id)}
                    overrides={{ disableRipple: true }}
                    sx={calendar.backgroundColor ? {
                      '& .MuiSvgIcon-root': {
                        color: calendar.backgroundColor
                      },
                      '&.Mui-checked': {
                        '& .MuiSvgIcon-root': {
                          color: calendar.backgroundColor
                        }
                      },
                    } : {}}
                  />
                </ListItemIcon>
                <ListItemText primary={calendar.title} sx={{ margin: 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton role={undefined} dense disableGutters onClick={() => setOpenAdditionalCalendarsModal(true)}>
              <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                <CabIcon Icon={IoAddOutline} />
              </ListItemIcon>
              <ListItemText primary="Add Calendar" primaryTypographyProps={{ fontWeight: 600 }} sx={{ margin: 0 }} />
            </ListItemButton>
          </ListItem>
        </List>

      </Box>

      {execsToUpdate.length > 0 && (
        <>
          <LeaderEmailsModal
            isOpen={execsToUpdate.length > 0}
            leaders={execsToUpdate}
            onEmailsSubmitted={async (leadersToUpdate) => {
              await onUpdateLeaderEmails(leadersToUpdate);
              onLeaderSelect(Array.from(new Set([...selectedLeaders, ...leadersToUpdate.map(l => l.id)]))
                .map(lId => ({ leaderId: lId, selected: true })));
              setExecsToUpdate([]);
            }}
            onCancel={() => {
              setShowConfirmCancelDialog(true);
            }}
          />
          <CabModal
            open={showConfirmCancelDialog}
            onClose={() => setShowConfirmCancelDialog(false)}
            isAlert
            title="Are you sure?"
            text="Any executives without an email will be removed from this meeting."
            actionButtons={<>
              <CabButton buttonType='tertiary' color='primary' onClick={() => setShowConfirmCancelDialog(false)}>
                Add Emails
              </CabButton>
              <CabButton onClick={() => {
                onLeaderSelect(execsToUpdate.map(l => ({ leaderId: l.id, selected: false })));
                setExecsToUpdate([]);
                setShowConfirmCancelDialog(false);
              }}>
                Remove Executives
              </CabButton>
            </>}
          />
        </>
      )}

      {execsToBeUpdatedOnMeeting.length > 0 && (addedLeaders.length > 0 || removedLeaders.length > 0) && (
        <ConfirmMeetingLeaderChangeModal
          open={execsToBeUpdatedOnMeeting.length > 0}
          onCancel={cancelMeetingLeaderChange}
          onConfirm={confirmMeetingLeaderChange}
          addedLeaders={addedLeaders}
          removedLeaders={removedLeaders}
        />
      )}

      {openAdditionalCalendarsModal && (
        <AdditionalCalendarsModal
          isOpen={openAdditionalCalendarsModal} 
          onCancel={() => setOpenAdditionalCalendarsModal(false)}
          selectedLeaders={selectedLeaders}
          onSelectLeaders={handleUpdateLeaderSelection}
        />
      )}

    </Drawer>
  );
};

export default SidePanel;

const ConfirmMeetingLeaderChangeModal = ({ open, onCancel, onConfirm, addedLeaders, removedLeaders }: {
  open: boolean; onCancel: () => void; onConfirm: () => void; addedLeaders: Leader[]; removedLeaders: Leader[]
}) => {

  return (
    <CabModal
      open={open}
      onClose={onCancel}
      isAlert
      title="Change Meeting Teammates"
      text="The following changes will be made to your meeting:"
      actionButtons={<Box width="100%" display="flex" justifyContent="space-between" padding={1}>
        <CabTooltip
          title="Don't want to update this meeting? Close the meeting panel on the right before changing teammates"
          wrapWithSpan
          placement="bottom-start"
        >
          <CabIcon Icon={IoHelpCircleOutline} size="large" />
        </CabTooltip>
        <Box display="flex" gap={1}>
          <CabButton buttonType='tertiary' color='primary' onClick={onCancel}>
            No
          </CabButton>
          <CabButton onClick={onConfirm}>
            Yes
          </CabButton>
        </Box>
      </Box>}
    >
      {removedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Removed</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {removedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {addedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Added</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {addedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </CabModal>
  );
};
